import { sorareBaseball, sorareBasketball } from "./sports";

const clickIds = {
  normal: "XkzxFx08axyLWzVwUx0Mo3EAUkE05zVZhSq91o0&irgwc",
  cardRecommendation: "XkzxFx08axyLWzVwUx0Mo3EAUkE05zVZhSq91o0&irgwc",
};

export const getLinkToSorareCard = (sport, cardSlug, clickId) => {
  const c = clickIds[clickId] || clickIds.normal;
  return sport === sorareBasketball
    ? `https://sorare.com/nba/cards/${cardSlug}`
    : sport === sorareBaseball
    ? `https://sorare.com/mlb/cards/${cardSlug}`
    : `https://sorare.com/football/cards/${cardSlug}?irclickid=${c}&irgwc=1`;
};
