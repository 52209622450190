import React, { useState } from "react";
import PopHoverWrapper from "../util/popHoverWrapper";
import { ReactComponent as WarningIcon } from "../../img/icons-warning-alternative-no-color.svg";
import { Button, RocketButton } from "../util/button";
import { ReactComponent as KeyBoardDoubleArrowUp } from "@material-design-icons/svg/round/keyboard_double_arrow_up.svg";
import { ReactComponent as CloseIcon } from "@material-design-icons/svg/round/close.svg";
import { ReactTooltip } from "../util/tooltip";
import { isMobile } from "react-device-detect";
import { withUser } from "../../userContext";
import { t1OrBelow } from "../util/handleSubscriptionTier";
import { ReactComponent as LockIcon } from "../../img/lock-icon.svg";
import { getThemeColor, useTheme } from "../../themeContext";
import { CardRecommendation } from "./cardRecommendation";
import SlidingPanel from "../util/slidingPanel/slidingPanel";
import clsx from "clsx";
import { sdDark } from "../../themeColors";
import { Tag } from "../util/tag";

// Helper function to clamp values
function clamp(value, min, max) {
  return Math.min(Math.max(value, min), max);
}

export const LineupStrength = withUser((props) => {
  const {
    lineupScore,
    lineupScoreNormalized,
    divisionMaxScore,
    isTopCorner,
    clickable,
    size,
    withLock,
    withCap240Indicator,
    cap240Indicators,
    shouldCardRecommendation,
    divisionId,
    lineupCards,
    captainPosition,
    title,
    darkMode,
    divLogo,
    gwNumber,
    notRelevant,
  } = props;
  const [openPanel, setOpenPanel] = useState(false);
  const strength =
    lineupScoreNormalized !== undefined
      ? lineupScoreNormalized
      : Math.round(clamp(divisionMaxScore > 0 ? (lineupScore / divisionMaxScore) * 100 : 0, 0, 100));

  const containerStyle = {
    top: isTopCorner ? "0.8rem" : "",
    right: isTopCorner ? (withCap240Indicator ? "1.8rem" : "0.8rem") : withCap240Indicator ? "2rem" : "1rem",
  };
  const containerClass = clsx("flex items-center", clickable ? "cursor-pointer" : "");
  let offset = { x: 30, y: 30 };
  if (isMobile) {
    offset = { x: -280, y: 40 };
  }

  if (t1OrBelow(props.user.tier)) {
    if (!withLock) return null;
    return !isNaN(strength) && divisionMaxScore ? (
      <LineupStrengthLocked size={size} containerStyle={containerStyle} containerClass={containerClass} offset={offset} />
    ) : null;
  }

  const empty = isNaN(strength) || strength === 0 || (lineupScoreNormalized === undefined && !divisionMaxScore);
  return (
    <div className={containerClass} style={containerStyle}>
      <StrengthScore
        empty={empty}
        strength={strength}
        size={size}
        shouldRecoCard={!empty && shouldCardRecommendation?.shouldRecommend.value}
        panelIsOpen={openPanel}
        onOpenRecoCard={() => setOpenPanel(true)}
        darkMode={darkMode}
        notRelevant={notRelevant}
      />
      {clickable && (
        <SlidingPanel
          type={isMobile ? "bottom" : "right"}
          backdropClicked={() => setOpenPanel(false)}
          isOpen={openPanel}
          size={isMobile ? "90vh" : "25em"}
        >
          <div className={"bg-surface-container h-full overflow-y-auto cursor-default"}>
            <div className={"flex flex-row justify-between items-center border-b border-outline-variant px-5 pt-6 pb-5"}>
              <div className={"flex flex-col gap-1"}>
                <p className={"font-headers text-on-surface font-semibold text-lg sm:text-xl flex flex-row gap-2 items-center"}>
                  Lineup strength upgrade
                  <Tag context="tertiary" size="small" label={"Beta"} />
                </p>
                <p className={"text-on-surface-variant font-medium text-md"}>{title}</p>
              </div>
              <Button
                onClick={() => setOpenPanel(false)}
                context="dim"
                icon={<CloseIcon className={"w-6 h-6 fill-on-surface-variant"} />}
              />
            </div>
            <div className={"h-full p-3"}>
              <div className="flex flex-col gap-2 font-semibold text-center">
                <CardRecommendation
                  shouldRecoCard={shouldCardRecommendation}
                  divisionId={divisionId}
                  lineupCards={lineupCards}
                  captainPosition={captainPosition}
                  cap240Indicators={withCap240Indicator && !empty ? cap240Indicators : []}
                  darkMode={darkMode}
                  divLogo={divLogo}
                  gwNumber={gwNumber}
                />
                <ReactTooltip />
              </div>
            </div>
          </div>
        </SlidingPanel>
      )}
    </div>
  );
});

const getStrengthColorClass = (strength, notRelevant) => {
  if (strength === 0 || notRelevant) {
    return "";
  } else if (strength < 25) {
    return "terrible";
  } else if (strength < 45) {
    return "bad";
  } else if (strength < 60) {
    return "average-good";
  } else if (strength < 75) {
    return "good";
  } else if (strength < 90) {
    return "very-good";
  } else if (strength <= 100) {
    return "excellent";
  }
  return "";
};

const sizeStyles = {
  xs: {
    containerClass: "w-3 h-3 p-3",
    borderWidth: "3px",
    fontSize: "12px",
  },
  sm: {
    containerClass: "w-7 h-7 p-4",
    borderWidth: "5px",
    fontSize: "16px",
  },
  md: {
    containerClass: "w-12 h-12 p-5",
    borderWidth: "7px",
    fontSize: "20px",
  },
};

const StrengthScore = ({ empty, strength, size, isLocked, shouldRecoCard, onOpenRecoCard, panelIsOpen, darkMode, notRelevant }) => {
  const { theme } = useTheme();
  const strengthColorClass = getStrengthColorClass(strength, notRelevant);
  const [hover, setHover] = useState(false);
  const defaultSizeStyle = sizeStyles.md;

  const selectedSizeStyle = sizeStyles[size] || defaultSizeStyle;

  const containerClass = [
    "bg-surface-container rounded-full flex items-center justify-center border-surface-dim relative",
    shouldRecoCard ? "cursor-pointer" : "",
    selectedSizeStyle.containerClass,
  ].join(" ");

  const containerStyle = {
    borderWidth: selectedSizeStyle.borderWidth,
    fontSize: selectedSizeStyle.fontSize,
    boxShadow: "0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30)",
    borderColor: strengthColorClass
      ? getThemeColor(darkMode ? sdDark : theme, `quality-scale-${strengthColorClass}-quality-container-bright-fixed`)
      : undefined,
  };

  const textStyle = {
    color: strengthColorClass
      ? getThemeColor(darkMode ? sdDark : theme, `quality-scale-${strengthColorClass}-on-surface-container`)
      : getThemeColor(darkMode ? sdDark : theme, "on-surface-variant"),
    fontFamily: "Poppins",
    fontWeight: 600,
    paddingTop: "0.02rem",
  };

  return (
    <div
      className={containerClass}
      style={containerStyle}
      onClick={onOpenRecoCard}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {shouldRecoCard && (
        <div className={"absolute right-0 top-0 -mr-[1.12rem] -mt-[1.12rem]"}>
          <RecommendationIndicator hovered={panelIsOpen || hover} />
        </div>
      )}
      {isLocked ? (
        <span>
          <LockIcon className="w-7 h-7 fill-on-surface-variant" />
        </span>
      ) : (
        <span className="font-semibold" style={textStyle}>
          {empty ? "-" : Math.round(strength)}
        </span>
      )}
      {shouldRecoCard && (
        <div className={"absolute -bottom-14"}>
          <p className={"text-white font-semibold text-xs sm:text-sm shadow-sm"}>Upgrade available</p>
        </div>
      )}
    </div>
  );
};

function LineupStrengthLocked(props) {
  const { containerClass, containerStyle, size, offset } = props;

  const strengthLocked = <StrengthScore strength={0} size={size} isLocked={true} />;
  const strength92 = <StrengthScore strength={92} size={size} />;
  const strength78 = <StrengthScore strength={78} size={size} />;
  const strength64 = <StrengthScore strength={64} size={size} />;
  const strength51 = <StrengthScore strength={51} size={size} />;
  const strength36 = <StrengthScore strength={36} size={size} />;
  const strengthDemo = (
    <div className="flex items-center justify-between space-b">
      {strength92} {strength78} {strength64} {strength51} {strength36}
    </div>
  );

  const hoverContent = (
    <div className="flex items-center justify-center h-45 w-80">
      <div className="flex flex-col gap-2 font-semibold text-center">
        <div>{strengthDemo}</div>
        <div className="opacity-50 bg-surface-container-high rounded-xl p-3 font-semibold text-xs">
          <div className="flex flex-row gap-2 items-center">
            <WarningIcon className="w-10 h-10 fill-on-surface-variant" />
            <span className="text-left text-on-surface-variant">
              The best possible theoretical lineup has a score of 100. Each player contributes to the lineup score, using its pick score and
              power
            </span>
          </div>
        </div>
        <a href="/product">
          <RocketButton label="Upgrade" />
        </a>
      </div>
    </div>
  );

  return (
    <div className={containerClass} style={containerStyle}>
      <PopHoverWrapper hoverTitle="Lineup strength" hover={hoverContent} fixed={true} offset={offset}>
        {strengthLocked}
      </PopHoverWrapper>
    </div>
  );
}

const RecommendationIndicator = (props) => {
  const hovered = props.hovered || false;
  return (
    <button
      className="relative bg-transparent border-0 border-radius-0 cursor-pointer line-height-1 p-1 appearance-none display-inline-block position-relative w-10 h-10 z-10"
      type="button"
    >
      {!hovered && (
        <div
          className={
            "absolute left-0 top-0 flex flex-row justify-center items-center w-full h-full animate-card-recommendation-outer fill-transparent-surface-container-low"
          }
        >
          <svg className={"m-auto"} width="40" height="40" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
            <circle cx="20" cy="20" r="19" fillOpacity={0.15} />
          </svg>
        </div>
      )}
      {!hovered && (
        <div
          className={
            "absolute left-0 top-0 flex flex-row justify-center items-center w-full h-full animate-card-recommendation-outer fill-surface-bright"
          }
        >
          <svg className={"m-auto"} width="40" height="40" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
            <circle cx="20" cy="20" r="14" />
          </svg>
        </div>
      )}
      <div className={"absolute left-0 top-0 flex flex-row justify-center items-center w-full h-full"}>
        <UpgradeAvailable hovered={hovered} />
      </div>
    </button>
  );
};

const UpgradeAvailable = (props) => {
  const { hovered } = props;
  return (
    <div className={clsx("rounded-full bg-primary flex items-center justify-center ", hovered ? "w-6 h-6" : "w-5 h-5")}>
      <KeyBoardDoubleArrowUp className={clsx("fill-on-primary", hovered ? "w-5 h-5" : "w-4 h-4")} />
    </div>
  );
};
