import React, { useCallback, useEffect, useState } from "react";
import clsx from "clsx";
import { withUser } from "../../userContext";
import { scarcities_objects_not_all_shortname } from "../util/scarcities";
import { DropdownDS } from "../util/dropdownDS";
import { ReactComponent as Info } from "../../img/icon-info-no-color.svg";
import { capitalize } from "../util/capitalize";
import { ReactComponent as CheckBox } from "../../img/check-box-empty-no-color.svg";
import { ReactComponent as CheckBoxBrand } from "../../img/check-box-checked-no-color.svg";

import {
  accepted_currencies,
  durations_before_end_options,
  editions,
  listing_types,
  price_type_custom,
  price_type_floor,
  price_type_last_avg,
  seasons,
} from "./enum";
import Slider from "rc-slider";
import { errorCatcher } from "../util/errors";
import { Button } from "../util/button";
import { isFree } from "../util/handleSubscriptionTier";
import BoxWithToggleDS from "../util/boxWithToggleDS";
import { SegmentedButton } from "../util/segmentedButton";

export const PriceAlertForm = withUser((props) => {
  const { user, priceAlert, playerId, floorPrice, lastAvg, onSubmitCallback, onClose } = props;
  const defaultPriceAlert = {
    alertConfigId: "",
    durationsBeforeEnd: [],
    listingType: "offer",
    scarcity: "",
    acceptedCurrency: "all",
  };

  // form inputs
  const [priceAlertForm, setPriceAlertForm] = useState(priceAlert || defaultPriceAlert);
  const [priceFilterFloor, setPriceFilterFloor] = useState(priceAlert?.priceFilters?.floor);
  const [priceFilterLastAvg, setPriceFilterLastAvg] = useState(priceAlert?.priceFilters?.last_avg);
  const [priceFilterCustom, setPriceFilterCustom] = useState(priceAlert?.priceFilters?.custom);
  const [priceFilterCustomCurrency, setPriceFilterCustomCurrency] = useState(priceAlert?.priceFilters?.custom?.currency);
  const [formSeasons, setFormSeasons] = useState(priceAlert?.seasons ?? []);
  const [formEditions, setFormEditions] = useState(priceAlert?.editions ?? []);

  // form validation
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    const form = buildForm(priceAlertForm);
    if (isOfferMode()) {
      if (Object.values(form.priceFilters).length === 0) {
        setIsFormValid(false);
        return;
      }
    }
    if (form.priceFilters.custom) {
      if (!form.priceFilters.custom.price || form.priceFilters.custom.price <= 0) {
        setIsFormValid(false);
        return;
      }
    }
    if (isAuctionMode()) {
      if (priceAlertForm.durationsBeforeEnd.length === 0) {
        setIsFormValid(false);
        return;
      }
    }
    setIsFormValid(true);
  }, [priceAlertForm, priceFilterFloor, priceFilterLastAvg, priceFilterCustom, formSeasons, formEditions]);

  const isOfferMode = () => priceAlertForm.listingType === "offer";
  const isAuctionMode = () => priceAlertForm.listingType === "auction";
  const isEditMode = () => priceAlert?.alertConfigId && priceAlert?.alertConfigId !== "";

  const buildForm = (priceAlert) => {
    const form = {
      alertConfigId: priceAlert?.alertConfigId,
      durationsBeforeEnd: priceAlert?.durationsBeforeEnd,
      acceptedCurrency: priceAlert?.acceptedCurrency,
      playerIds: priceAlert?.playerIds ?? [playerId],
      scarcity: priceAlert?.scarcity,
      listingType: priceAlert?.listingType,
      sendWeb: true,
      sendMobile: true,
      priceFilters: {},
      seasons: formSeasons,
      editions: formEditions,
    };
    if (priceFilterFloor) {
      form.priceFilters.floor = priceFilterFloor;
    }
    if (priceFilterLastAvg) {
      form.priceFilters.last_avg = priceFilterLastAvg;
    }
    if (priceFilterCustom) {
      form.priceFilters.custom = priceFilterCustom && {
        ...priceFilterCustom,
        currency: priceFilterCustomCurrency,
        price: priceFilterCustom?.price && parseFloat(priceFilterCustom?.price),
      };
    }
    return form;
  };

  const upsertAlert = () => {
    const httpMethod = isEditMode() ? "PUT" : "POST";
    let url = "/apiv2/user/priceAlerts";
    if (isEditMode()) {
      url = `${url}/${priceAlert.alertConfigId}`;
    }
    props
      .fetch(url, {
        method: httpMethod,
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(buildForm(priceAlertForm)),
      })
      .then((response) => {
        if (response.ok) {
          onSubmitCallback();
        }
      })
      .catch(errorCatcher());
  };

  const onFormChange = useCallback(
    (key, value) =>
      setPriceAlertForm((prev) => ({
        ...prev,
        [key]: value,
      })),
    [],
  );

  useEffect(() => {
    if (user?.preferredScarcity && !priceAlert?.scarcity) {
      onFormChange("scarcity", user?.preferredScarcity);
    }
    if (!priceAlert?.priceFilters?.custom?.currency) {
      setPriceFilterCustomCurrency(user?.preferredUnit);
    }
  }, [user?.preferredScarcity, user?.preferredUnit]);

  let alertTypeDescription = "";
  switch (priceAlertForm.listingType) {
    case "offer":
      alertTypeDescription = "Get notified for every new live offer matching your criteria.";
      break;
    case "auction":
      alertTypeDescription = "Get notified for every auction matching your criteria.";
      break;
  }

  const formTitleClassName = "text-base text-on-surface-variant font-semibold";
  const sliderStyle = {
    track: [{ backgroundColor: "#f45206", border: "" }],
    handle: [
      {
        backgroundColor: "#f45206",
        border: "solid 1px #f45206",
      },
      { border: "solid 1px #f45206" },
    ],
  };
  const initialForm = JSON.stringify({
    ...buildForm(priceAlert),
    priceFilters: priceAlert?.priceFilters,
    editions: priceAlert?.editions ?? [], // Keeping default as an empty array ensures accurate form comparison
    seasons: priceAlert?.seasons ?? [], // Keeping default as an empty array ensures accurate form comparison
  });
  const isFormDifferent = () => initialForm !== JSON.stringify(buildForm(priceAlertForm));

  return (
    <div className="max-w-96 space-y-3">
      <div className="space-y-3 px-4">
        {!isEditMode() && (
          <div className="space-y-2">
            <span className={formTitleClassName}>Alert Type</span>
            <SegmentedButton
              className="mb-2"
              options={listing_types}
              selected={priceAlertForm.listingType}
              onFormChange={(value) => onFormChange("listingType", value)}
            />
            <p className="text-xs text-on-surface-variant">{alertTypeDescription}</p>
          </div>
        )}
        <div className="space-y-2">
          <span className={formTitleClassName}>Card Properties</span>
          <div className="space-y-2">
            <SegmentedButton
              className="mb-2"
              options={scarcities_objects_not_all_shortname}
              selected={priceAlertForm.scarcity}
              onFormChange={(value) => onFormChange("scarcity", value)}
            />
            <div>
              <DropdownDS
                width="w-full"
                options={seasons}
                paywalled={isFree(user.tier)}
                selected={formSeasons}
                onChange={(v) => {
                  if (!formSeasons?.includes(v)) {
                    setFormSeasons([...formSeasons, v]);
                  } else {
                    setFormSeasons(formSeasons.filter((season) => season !== v));
                  }
                }}
                placeholder={formSeasons.length === seasons.length ? "All seasons" : "Select seasons"}
                multiple
              />
            </div>
            <div>
              <DropdownDS
                width="w-full"
                options={editions}
                paywalled={isFree(user.tier)}
                selected={formEditions}
                onChange={(v) => {
                  if (!formEditions?.includes(v)) {
                    setFormEditions([...formEditions, v]);
                  } else {
                    setFormEditions(formEditions.filter((edition) => edition !== v));
                  }
                }}
                placeholder={formEditions.length === editions.length ? "All editions" : "Select editions"}
                multiple
              />
            </div>
          </div>
        </div>
        <div className="space-y-2">
          <span className={formTitleClassName}>{capitalize(priceAlertForm.listingType)} Price</span>
          <div className="w-full flex flex-col lg:flex-row space-y-2 lg:space-y-0 items-center justify-center space-x-2 bg-transparent-inverse-surface-low bg-opacity-10 px-2 py-1.5 rounded-xl">
            <Info className="fill-on-surface-variant w-4 h-4" />
            <p className="text-xs text-on-surface-variant">Dynamic prices will adjust automatically based on latest offers.</p>
          </div>
          <div>
            <BoxWithToggleDS
              color="text-on-surface"
              label={isOfferMode() ? "Is the new floor" : "Below floor"}
              paywalled={isFree(user.tier)}
              isActive={priceFilterFloor}
              setActive={() => {
                !priceFilterFloor ? setPriceFilterFloor({ priceType: price_type_floor }) : setPriceFilterFloor(undefined);
              }}
            >
              {isAuctionMode() && (
                <>
                  <p className="text-sm text-on-surface font-medium">Minimum % below</p>
                  <div className="flex space-x-3">
                    <span className="text-xs font-semibold">{priceFilterFloor?.pricePct ?? 0}%</span>
                    <Slider
                      trackStyle={sliderStyle.track}
                      handleStyle={sliderStyle.handle}
                      step={5}
                      min={0}
                      max={100}
                      value={priceFilterFloor?.pricePct}
                      onChange={(value) => setPriceFilterFloor({ ...priceFilterFloor, pricePct: value })}
                    />
                  </div>
                </>
              )}
            </BoxWithToggleDS>
          </div>
        </div>
        <BoxWithToggleDS
          color="text-on-surface"
          label="Below latest valuation price"
          isActive={priceFilterLastAvg}
          paywalled={isFree(user.tier)}
          setActive={() => {
            !priceFilterLastAvg ? setPriceFilterLastAvg({ priceType: price_type_last_avg }) : setPriceFilterLastAvg(undefined);
          }}
        >
          <>
            <p className="text-sm text-on-surface font-medium">Minimum % below</p>
            <div className="flex space-x-3">
              <span className="text-xs font-semibold">{priceFilterLastAvg?.pricePct ?? 0}%</span>
              <Slider
                trackStyle={sliderStyle.track}
                handleStyle={sliderStyle.handle}
                step={5}
                min={0}
                max={100}
                value={priceFilterLastAvg?.pricePct}
                onChange={(value) => setPriceFilterLastAvg({ ...priceFilterLastAvg, pricePct: value })}
              />
            </div>
          </>
        </BoxWithToggleDS>
        <BoxWithToggleDS
          color="text-on-surface"
          label={"Below a fixed price"}
          isActive={priceFilterCustom}
          setActive={() => {
            !priceFilterCustom ? setPriceFilterCustom({ priceType: price_type_custom }) : setPriceFilterCustom(undefined);
          }}
        >
          <>
            <span className="text-on-surface-variant text-sm text-opacity-50">Price ({priceFilterCustomCurrency})</span>
            <input
              type="number"
              value={priceFilterCustom?.price}
              onChange={(e) => {
                setPriceFilterCustom({ ...priceFilterCustom, price: e.target.value });
              }}
              className={clsx(
                "focus:ring-0 focus:outline-none font-medium block w-full py-2 pl-4 rounded-full text-sm bg-transparent-inverse-surface-low bg-opacity-10 text-on-surface-variant border-0 placeholder:text-on-surface-variant placeholder:text-opacity-30 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none",
              )}
              placeholder={"Enter custom amount"}
            />
            {!priceFilterCustom?.price && <p className="mt-2 text-error text-xs font-semibold">*Required</p>}
          </>
        </BoxWithToggleDS>
        {isOfferMode() && (
          <div className="space-y-2">
            <span className={formTitleClassName}>Purchase Option</span>
            <SegmentedButton
              options={accepted_currencies}
              selected={priceAlertForm.acceptedCurrency}
              onFormChange={(value) => onFormChange("acceptedCurrency", value)}
            />
          </div>
        )}
        {isAuctionMode() && (
          <div className="space-y-2">
            <div className="flex items-center space-x-1">
              <span className={formTitleClassName}>Alert Timing</span>
              {priceAlertForm.durationsBeforeEnd.length === 0 && <Info className="fill-error w-4 h-4" />}
            </div>
            <div className="bg-white rounded-md">
              {durations_before_end_options.map(({ value, name }, index) => {
                return (
                  <div
                    key={value}
                    className={clsx(
                      "cursor-pointer select-none flex justify-between items-center px-4 py-3 text-sm font-medium bg-white rounded-md",
                      index < durations_before_end_options.length - 1 && "border-b",
                    )}
                    onClick={() => {
                      if (!priceAlertForm.durationsBeforeEnd?.includes(value)) {
                        onFormChange("durationsBeforeEnd", [...priceAlertForm.durationsBeforeEnd, value]);
                      } else {
                        onFormChange(
                          "durationsBeforeEnd",
                          priceAlertForm.durationsBeforeEnd.filter((duration) => duration !== value),
                        );
                      }
                    }}
                  >
                    {name}
                    {priceAlertForm.durationsBeforeEnd?.includes(value) ? (
                      <CheckBoxBrand className="w-4 h-4 fill-primary" />
                    ) : (
                      <CheckBox className="w-4 h-4" />
                    )}
                  </div>
                );
              })}
            </div>
            {priceAlertForm.durationsBeforeEnd.length === 0 && (
              <p className="p-3 pt-0 mt-2 text-error text-xs font-semibold">*At least one timing option is required.</p>
            )}
          </div>
        )}
      </div>
      <div className={"flex flex-row border-t border-outline-variant justify-end space-x-3 py-2 px-4"}>
        <Button context="secondary" label={"Cancel"} onClick={() => onClose()} />
        <Button label={isEditMode() ? "Edit" : "Create"} onClick={() => upsertAlert()} disabled={!isFormDifferent() || !isFormValid} />
      </div>
    </div>
  );
});
