import { Redirect, Route } from "react-router-dom";
import React from "react";
import { withUser } from "../../userContext";
import ls from "local-storage";

function isAuthenticated() {
  return ls.get("username") !== undefined && ls.get("username") !== null && ls.get("username") !== "";
}

function isRegistrationValid() {
  const email = ls.get("email");
  const termsAccepted = ls.get("termsAccepted");
  return email !== undefined && email !== null && email !== "" && termsAccepted !== undefined && termsAccepted !== null && termsAccepted;
}

function isAccountRelatedToSorare() {
  return ls.get("linkedAccount") !== undefined && ls.get("linkedAccount") !== null && ls.get("linkedAccount") !== "";
}

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      const redirectionQuery = "?to=" + props.location.pathname.slice(1, props.location.pathname.length).replaceAll("/", "%2F");
      if (!isAuthenticated()) {
        return (
          <Redirect
            to={{
              pathname: "/login",
              search: redirectionQuery,
            }}
          />
        );
      }
      if (!isAccountRelatedToSorare()) {
        return (
          <Redirect
            to={{
              pathname: "/deprecatedAccount",
              search: redirectionQuery,
            }}
          />
        );
      }
      if (!isRegistrationValid()) {
        return (
          <Redirect
            to={{
              pathname: "/validateRegistration",
              search: redirectionQuery,
            }}
          />
        );
      }

      return <Component {...props} />;
    }}
  />
);

export default withUser(PrivateRoute);
