import React, { Fragment, useEffect, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/solid";

export default function CustomSelect(props) {
  const options = props.options;

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  useEffect(() => {
    setSelected(options[0]);
  }, []);

  const changeSelected = (o) => {
    console.log("allo", o);
    setSelected(o);
    props.onChange(o);
  };

  const [selected, setSelected] = useState(options[0]);

  const bg = props.bg || "bg-white";
  const font = props.font || "";
  const chevron = props.chevron || "text-white";

  return (
    <Listbox value={selected} onChange={(o) => changeSelected(o)}>
      {({ open }) => (
        <>
          <div className="relative">
            <Listbox.Button
              className={
                props.className +
                font +
                " " +
                bg +
                " relative flex flex-row space-x-2 w-full rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-pointer focus:outline-none sm:text-sm"
              }
            >
              {props.icon && <div>{props.icon}</div>}
              <span className="block truncate">{selected.name}</span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <ChevronDownIcon className={"h-5 w-5 cursor-pointer " + chevron} aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition show={open} as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
              <Listbox.Options
                className={
                  bg +
                  " " +
                  font +
                  " absolute z-10 mt-1 w-full shadow-lg max-h-60 rounded-md py-1 overflow-auto focus:outline-none sm:text-sm"
                }
              >
                {options.map((option) => (
                  <Listbox.Option
                    key={option.id}
                    className={({ active }) =>
                      classNames(active ? "bg-brand-100" : font, "cursor-pointer select-none relative py-2 pl-3 pr-9")
                    }
                    value={option}
                  >
                    {({ selected, active }) => (
                      <>
                        <span className={classNames(selected ? "font-semibold" : "font-medium", "block truncate")}>{option.name}</span>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? "text-white font-semibold" : "text-indigo-600",
                              "absolute inset-y-0 right-0 flex items-center pr-4",
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
