import { withUser } from "../../userContext";
import { getItemFromCompAndOptionalTeam, LineupBuilderDivisionOrLineupPicker } from "./lineupBuilderDivisionOrLineupPicker";
import { so5_positions } from "../util/positions";
import { LineupBuilderLineup } from "./lineupBuilderLineup";
import { t1OrAbove } from "../util/handleSubscriptionTier";
import { LineupBuilderList } from "./lineupBuilderTable";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { sorareFootball } from "../util/sports";
import { ReactTooltip } from "../util/tooltip.js";
import { errorCatcher } from "../util/errors";
import { capitalize } from "../util/capitalize";
import { defaultFootballLUBFilters } from "./footballFilters";
import { defaultFootballLUBPreferences, getPreferencesFromLocalStorage, setPreferenceToLocalStorage } from "./footballPreferences";
import ViewLUBTableLegend from "./footballLineupTableLegend";
import AbortController from "abort-controller";
import { useQuery } from "../util/useQuery";
import { useHistory, useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { getPaginatedJSONResponse } from "../util/pagination";
import { useElementSize } from "usehooks-ts";
import { LearnMoreAboutLUBTiers } from "./learnMore";
import { Manager, Reference } from "react-popper";
import { DiscardedPlayersModale, DiscardedPlayersModaleMobile } from "./footballLineupBuilderDiscardedPlayers";
import { useTailwindMediaQueries } from "../util/mediaQueries";
import { Button } from "../util/button";
import { LUBFilterAndPreferencesToggle } from "./footballLineupBuilderFilterAndPreferences";
import { isEqual } from "lodash-es";
import { allStarDefaultCompetition } from "../competitions/eligibilityCompetitionsPicker";

const initialSuggestionResults = {
  combinations: [],
  has_next_combination: false,
  has_more_players_combinations: {},
  cards_by_position: {},
  type: "",
  cards: {},
  pick_score_map: {},
};

const initialSuggestionBrowseInfo = {
  indexes: [0, 0, 0, 0, 0],
  hasPrev: [false, false, false, false, false],
  hasNext: [false, false, false, false, false],
  cardsByPosition: {},
};

const initialSelectLineupOrDivision = {
  id: "",
  divisionId: "",
  name: "",
  rarity: "",
  logo: "",
  team: undefined,
};

const initialCompetitions = {
  loading: false,
  enteredCompetitions: undefined,
  availableCompetitions: undefined,
  disabledCompetitions: undefined,
  unavailableCompetitions: undefined,
  competitionsByDivId: undefined,
  teamsByIds: undefined,
  suggestionLimits: undefined,
  maxScores: undefined,
  maxScoresNoCaptain: undefined,
  prizePools: undefined,
  savedLineupsById: undefined,
  abortSignal: new AbortController(),
};

const initialOdds = {
  loading: false,
  simple: undefined,
  full: undefined,
};

const initialBreakdown = {
  loading: false,
  players: undefined,
  standings: undefined,
  pickScoreRevealRights: undefined,
  so5ScoresDetailed: undefined,
  gwStatus: undefined,
  abortSignal: new AbortController(),
};

const initialGw = {
  gwNumber: undefined,
  status: undefined,
};

const initialLineupPlayers = [null, null, null, null, null];

const initialLineup = {
  lineupId: "",
  saving: false,
  loading: false,
  position: "",
  current: initialLineupPlayers,
  saved: null,
  hasChangesToSave: false,
  previous: initialLineupPlayers,
  captain: null,
  abortSignal: new AbortController(),
};

const initialHistoricalPerformance = {
  loading: false,
  data: undefined,
  range: {
    min: 0,
    max: 0,
  },
  abortSignal: new AbortController(),
};

const initialSuggestion = {
  mode: false,
  suggesting: [false, false, false, false, false],
  results: initialSuggestionResults,
  browseInfo: initialSuggestionBrowseInfo,
  lockedCards: {},
  discardedCards: {},
  discardPanelOpen: false,
};

export const FootballLineupBuilder = withUser((props) => {
  const { gw, targetUserId, draftId, onSaveLineup, onThemeChange, onLineupChange, interceptAction, onPreferencesChange } = props;
  const sport = sorareFootball;

  const query = useQuery();
  const history = useHistory();
  const location = useLocation();

  const [selectedGw, setSelectedGw] = useState({ ...initialGw, gwNumber: gw });
  const [selectedLineupOrDivision, setSelectedLineupOrDivision] = useState({
    ...initialSelectLineupOrDivision,
    id: query.get("divisionId") || "",
    divisionId: query.get("divisionId") || "",
    team: {
      lineup: query.get("lineupId")
        ? {
            competitionId: query.get("divisionId") || "",
            id: query.get("lineupId") || "",
          }
        : undefined,
      managerTeam: query.get("managerTeamId")
        ? {
            id: query.get("managerTeamId") || "",
          }
        : undefined,
      lineupTeamNumber: query.get("lineupTeamNumber") || undefined,
    },
  });
  const [breakdown, setBreakdown] = useState(initialBreakdown);
  const [competitions, setCompetitions] = useState(initialCompetitions);
  const [odds, setOdds] = useState(initialOdds);
  const [lineup, setLineup] = useState(initialLineup);
  const [historicalPerformance, setHistoricalPerformance] = useState(initialHistoricalPerformance);
  const [suggestion, setSuggestion] = useState(initialSuggestion);
  const [filters, setFilters] = useState(defaultFootballLUBFilters);
  const [preferences, setPreferences] = useState(getPreferencesFromLocalStorage());
  const [refresh, setRefresh] = useState(0);
  const [projections, setProjections] = useState({});
  const [pickScoreReveal, setPickScoreReveal] = useState({
    ...initialBreakdown.pickScoreRevealRights,
    players: {},
  });
  const [revealing, setRevealing] = useState({});

  // Display behavioral states
  const [validationMessages, setValidationMessages] = useState([]);
  const validationMessagesRef = useRef(validationMessages); // ref because of the async nature of the validation (there are timeouts)
  validationMessagesRef.current = validationMessages;
  const [legendTab, setLegendTab] = useState("");
  const [isOnHistoryTab, setIsOnHistoryTab] = useState(false);
  const mdQueries = useTailwindMediaQueries();

  const [ref, { height }] = useElementSize();
  const discardPanelRef = useRef(null);
  const lineupRef = useRef(null);

  const selectedCompetitionInfo = getCompetitionInfo(selectedLineupOrDivision, competitions);
  const lineupPoints = lineup.current?.reduce((acc, p) => acc + (p?.cost !== undefined ? p.cost : 0), 0) || 0;

  useEffect(() => {
    setSelectedGw({ ...selectedGw, gwNumber: gw });
  }, [gw]);

  useEffect(() => {
    setPreferenceToLocalStorage(preferences);
    onThemeChange && onThemeChange(preferences.darkMode);
    onPreferencesChange && onPreferencesChange(preferences);
  }, [preferences]);

  useEffect(() => {
    loadSavedLineupsAndCompetitions();
  }, [gw, draftId, refresh]);

  useEffect(() => {
    if (selectedLineupOrDivision.divisionId) {
      onSelectDivOrLineup(selectedLineupOrDivision, competitions.savedLineupsById);
    }
  }, [competitions.savedLineupsById, selectedLineupOrDivision]);

  useEffect(() => {
    fetchPlayers({ division: selectedLineupOrDivision.divisionId, gw: gw });
  }, [draftId, lineup.position]);

  useEffect(() => {
    const newParams = new URLSearchParams([...Array.from(query.entries())]); // copy existing params
    newParams.set("divisionId", selectedLineupOrDivision.divisionId);
    if (selectedLineupOrDivision.team?.lineup?.id) {
      newParams.set("lineupId", selectedLineupOrDivision.team?.lineup?.id);
    } else {
      newParams.delete("lineupId");
    }
    if (selectedLineupOrDivision.team?.managerTeam?.id) {
      newParams.set("managerTeamId", selectedLineupOrDivision.team?.managerTeam?.id);
    } else {
      newParams.delete("managerTeamId");
    }
    if (selectedLineupOrDivision.team?.lineupTeamNumber) {
      newParams.set("lineupTeamNumber", selectedLineupOrDivision.team?.lineupTeamNumber);
    } else {
      newParams.delete("lineupTeamNumber");
    }

    history.replace({
      pathname: location.pathname,
      search: newParams.toString(),
    });
    resetSuggestion(true);
    if (selectedLineupOrDivision.divisionId) {
      fetchPlayers({
        division: selectedLineupOrDivision.divisionId,
        lineupId: selectedLineupOrDivision.team?.lineup?.id || "",
        gw: selectedGw.gwNumber,
        ...defaultFootballLUBFilters,
      });
    }
  }, [selectedLineupOrDivision]);

  useEffect(() => {
    setPickScoreReveal({
      ...breakdown.pickScoreRevealRights,
      players: { ...pickScoreReveal.players },
    });
    setRevealing({});
  }, [breakdown]);

  useEffect(() => {
    if (isOnHistoryTab) {
      getGraphStats(lineup.current, undefined, undefined, lineup.captain, undefined);
    }
  }, [lineup, isOnHistoryTab]);

  useEffect(() => {
    onLineupChange && onLineupChange(lineup);
  }, [lineup, onLineupChange]);

  useEffect(() => {
    const handleClickOutsideOfDiscardAndLineup = (event) => {
      if (
        suggestion.discardPanelOpen &&
        lineupRef.current &&
        discardPanelRef.current &&
        !lineupRef.current.contains(event.target) &&
        !discardPanelRef.current.contains(event.target)
      ) {
        event.stopImmediatePropagation();
        setSuggestion({ ...suggestion, discardPanelOpen: false });
      }
    };
    document.addEventListener("click", handleClickOutsideOfDiscardAndLineup, true);
    return () => {
      document.removeEventListener("click", handleClickOutsideOfDiscardAndLineup, true);
    };
  }, [suggestion, lineupRef, discardPanelRef]);

  // end useEffect
  const reloadPreferencesFromLocalStorage = useCallback(() => {
    setPreferences(getPreferencesFromLocalStorage());
  }, []);
  const onPreferenceChange = useCallback(
    (key, value) =>
      setPreferences((prev) => ({
        ...prev,
        [key]: value,
      })),
    [],
  );
  const onResetPreferences = useCallback(() => setPreferences(defaultFootballLUBPreferences), []);
  const onCloseValidationMessage = useCallback(
    (id) => {
      setValidationMessages(validationMessagesRef.current.filter((m) => m.id !== id));
    },
    [validationMessagesRef],
  );
  const addValidationMessage = useCallback(
    ({ msg, warning, timeout }) => {
      const tim = timeout || 4000;
      const id = uuidv4();
      setValidationMessages([...validationMessagesRef.current, { id, msg, warning, timeout: tim }]);
      setTimeout(() => {
        setValidationMessages(validationMessagesRef.current.filter((m) => m.id !== id));
      }, tim);
    },
    [validationMessagesRef],
  );

  const resetSuggestion = useCallback(
    (all) => {
      setSuggestion({
        ...initialSuggestion,
        mode: false,
        discardPanelOpen: false,
        results: all ? initialSuggestionResults : suggestion.results,
      });
    },
    [suggestion],
  );

  const checkPlayer = useCallback((playerId) => isPlayedAbsentFromLineup(playerId, lineup.current), [lineup]);
  const isCardInCurrentLineup = useCallback((tokenId) => isCardInLineup(tokenId, lineup.current, lineup.position), [lineup]);
  const pickCard = useCallback(
    (card, cardPosition) => {
      let players = [...lineup.current];
      if (!isCardInCurrentLineup(card.card.PlayerId).value) {
        const { index, nextPosition } = getNextLineupPositionToSelect(lineup.position, cardPosition);
        let newCaptain = lineup.captain;
        if (lineup.captain !== null && card.TokenId === lineup.captain.card.TokenId) newCaptain = null;
        players[index] = card;
        const hasLineupChanges = hasLineupChangesToSave(newCaptain, players, lineup.saved);
        setLineup({
          ...lineup,
          current: players,
          captain: newCaptain,
          position: nextPosition !== "" ? nextPosition : lineup.position,
          hasChangesToSave: hasLineupChanges,
        });
        setProjections({
          ...projections,
          [card.card.PlayerId]: card.projected_lineup,
        });
        resetSuggestion(true);
      }
    },
    [lineup, isCardInCurrentLineup],
  );

  const removeCard = useCallback(
    (position, card) => {
      let index;
      let players = [...lineup.current];
      players.map((player, i) => {
        if (player !== null) {
          if (player.card.TokenId === card.card.TokenId) {
            index = i;
          }
        }
      });
      players[index] = null;
      const newCaptain = lineup.captain !== null && card.card.TokenId === lineup.captain.card.TokenId ? null : lineup.captain;
      const hasLineupChanges = hasLineupChangesToSave(newCaptain, players, lineup.saved);
      const newLineup = {
        ...lineup,
        current: players,
        captain: newCaptain,
        position: position,
        hasChangesToSave: hasLineupChanges,
      };
      setLineup(newLineup);
      fetchPlayers({});
      resetSuggestion(true);
    },
    [lineup],
  );

  const resetHistoricalPerformance = useCallback(() => {
    setHistoricalPerformance(initialHistoricalPerformance);
  }, []);

  const resetLineup = useCallback(() => {
    const hasLineupChanges = hasLineupChangesToSave(null, initialLineupPlayers, lineup.saved);
    setLineup({ ...lineup, current: initialLineupPlayers, captain: null, hasChangesToSave: hasLineupChanges });
    resetSuggestion(true);
    resetHistoricalPerformance();
  }, [lineup, resetSuggestion, resetHistoricalPerformance]);

  const revertLineup = useCallback(() => {
    const hasLineupChanges = hasLineupChangesToSave(null, initialLineupPlayers, lineup.previous);
    setLineup({
      ...lineup,
      current: lineup.previous,
      previous: initialLineupPlayers,
      captain: null,
      hasChangesToSave: hasLineupChanges,
    });
    resetHistoricalPerformance();
  }, [lineup, resetHistoricalPerformance]);

  const revertSuggestion = useCallback(
    (all) => {
      revertLineup();
      resetSuggestion(all);
    },
    [revertLineup, resetSuggestion],
  );

  const onSelectDivOrLineup = useCallback(
    (selectedDivOrLineup, savedLineupsById) => {
      setFilters(defaultFootballLUBFilters);
      const lineupId = selectedDivOrLineup.team?.lineup?.id;
      if (lineupId) {
        // Click on a lineup that has been saved
        const players = savedLineupsById && savedLineupsById[lineupId] ? savedLineupsById[lineupId].cards : initialLineupPlayers;
        const captain = players.find((c) => c?.captain);
        const newCaptain = captain !== undefined ? captain : null;
        setLineup({
          ...lineup,
          lineupId: lineupId,
          current: players,
          saved: players,
          captain: newCaptain,
          hasChangesToSave: false,
        });
      } else {
        // Click on a remaining division
        setLineup({ ...initialLineup, position: lineup.position });
      }
      resetSuggestion(true);
    },
    [lineup],
  );

  const onChangeDivisionOrLineup = useCallback((v) => {
    setSelectedLineupOrDivision(v);
  }, []);

  const onChangePosition = useCallback((v) => setLineup({ ...lineup, position: v }), [lineup]);

  const getGraphStats = useCallback(
    (pid, s, f, c, gwRange) => {
      const divType = selectedCompetitionInfo?.divisionType || "";
      if (pid === undefined) {
        pid = lineup.current;
      }
      const cardSlugs = pid
        .map((p) => {
          if (p) {
            return p.card.Slug;
          } else return "";
        })
        .filter((p) => p !== "");
      gwRange = gwRange || historicalPerformance.range;
      c = c !== undefined ? c : lineup.captain;
      if (divType && t1OrAbove(props.user.tier)) {
        historicalPerformance.abortSignal.abort();
        const newAbortController = new AbortController();
        setHistoricalPerformance({ ...historicalPerformance, loading: true, abortSignal: newAbortController });
        props
          .fetch("/decision-api/decision/lineupHistoricalPerformance", {
            method: "POST",
            headers: {
              Accept: "application/json, text/plain, */*",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              cardSlugs: cardSlugs,
              captain: c ? c.card.Slug : "",
              divisionType: divType,
              gwNumberMin: gwRange.min,
              gwNumberMax: gwRange.max,
            }),
            signal: newAbortController.signal,
          })
          .then((response) => response.json())
          .then((res) => {
            setHistoricalPerformance({
              ...historicalPerformance,
              data: res,
              range:
                res.performance?.length > 0
                  ? {
                      min: res.performance[0].gwNumber,
                      max: res.performance[res.performance.length - 1].gwNumber,
                    }
                  : { min: 0, max: 0 },
              loading: false,
            });
            ReactTooltip.rebuild();
          })
          .catch(
            errorCatcher(() => {
              setHistoricalPerformance({ ...historicalPerformance, loading: false });
            }),
          );
      }
    },
    [t1OrAbove, historicalPerformance, selectedCompetitionInfo, lineup, competitions, props.user.tier],
  );

  const onChangeCaptain = useCallback(
    (c) => {
      getGraphStats(undefined, undefined, undefined, c, undefined);
      const hasLineupChanges = hasLineupChangesToSave(c, lineup.current, lineup.saved);
      setLineup({ ...lineup, captain: c, hasChangesToSave: hasLineupChanges });
    },
    [getGraphStats, lineup],
  );
  const onRemoveCaptain = useCallback(() => {
    getGraphStats(undefined, undefined, undefined, null, undefined);
    const hasLineupChanges = hasLineupChangesToSave(null, lineup.current, lineup.saved);
    setLineup({ ...lineup, captain: null, hasChangesToSave: hasLineupChanges });
  }, [getGraphStats, lineup]);
  const onRemoveCardOnLineup = useCallback((c, p) => removeCard(p, c), [removeCard]);

  const onRevealPlayer = useCallback(
    (player, position) => {
      setRevealing({ ...revealing, [player.PlayerId + "-" + position]: true });
      props
        .fetch("/decision-api/decision/lub/revealPickScore", {
          method: "POST",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            division_id: selectedLineupOrDivision.divisionId,
            player_id: player.PlayerId,
            position: position,
          }),
        })
        .then((response) => response.json())
        .then((res) => {
          if (res.rights && res.revealed) {
            const ps = {
              score: res.revealed.pick_score_group,
              details: res.revealed.pick_score_details,
            };
            const newPickScoreReveal = {
              current: res.rights.current,
              max: res.rights.max,
              authorized: res.rights.authorized,
              players: {
                ...pickScoreReveal.players,
                [player.PlayerId + "-" + position]: ps,
              },
            };
            setPickScoreReveal(newPickScoreReveal);
          }
        })
        .catch(
          errorCatcher(() => {
            ReactTooltip.rebuild();
          }),
        )
        .finally(() => {
          const rev = { ...revealing };
          delete rev[player.PlayerId + "-" + position];
          setRevealing(rev);
        });
    },
    [pickScoreReveal, selectedLineupOrDivision],
  );

  const fetchPlayers = useCallback(
    (f) => {
      const lg = f.league || filters.league;
      let scarcity = f.scarcity || filters.scarcity;
      const lP = f.lineupPosition || lineup.position;
      const p = f.position || filters.position;
      const divId = f.division || selectedLineupOrDivision.divisionId;
      const availabilityStatus = f.availabilityStatus || filters.availabilityStatus;
      const showNGPlayers = f.showNGPlayers !== undefined ? f.showNGPlayers : filters.showNGPlayers;
      const cardSeasonType = f.cardSeasonType !== undefined ? f.cardSeasonType : filters.cardSeasonType;
      const comProjections = f.communityProjections || filters.communityProjections;
      const cost = f.cost || filters.cost;
      const l5 = f.l5 || filters.l5;
      const l15 = f.l15 || filters.l15;
      const l40 = f.l40 || filters.l40;
      const lineupID = f.lineupId || selectedLineupOrDivision.team?.lineup?.id;
      const eligibilityCompetitions = f.eligibilityCompetitions || filters.eligibilityCompetitions;
      if (divId) {
        let lineupPositionValue = lP !== undefined ? lP : "";
        let positionValue = p !== undefined && p !== "" ? p : "all";
        if (lineupPositionValue !== "") {
          // Get breakdown only when we know the connected user or targeted user
          breakdown.abortSignal.abort();
          const newAbortController = new AbortController();
          setBreakdown({ ...breakdown, loading: true, abortSignal: newAbortController });
          props
            .fetch("/breakdown-api/decision/lub/breakdown", {
              method: "POST",
              headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                userSorareId: targetUserId !== undefined ? targetUserId : "",
                lineupPosition: capitalize(lineupPositionValue),
                positions: positionValue !== "all" ? [positionValue] : [],
                scarcities: scarcity !== "all" ? [scarcity] : [],
                leagues: lg !== "all" ? [lg] : [],
                competitionsEligible: Object.keys(eligibilityCompetitions).filter((k) => k !== allStarDefaultCompetition.id),
                availabilityStatuses: availabilityStatus !== "all" ? [availabilityStatus] : [],
                not_playing: showNGPlayers,
                division_full_id: divId,
                draft_id: draftId,
                lineup_id: lineupID,
                with_player_last_games: true,
                communityProjections: comProjections,
                cost: cost,
                l5: l5,
                l15: l15,
                l40: l40,
                include_picked_players: true,
                seasonType: cardSeasonType,
              }),
              signal: newAbortController.signal,
            })
            .then((response) => response.json())
            .then((res) => {
              setBreakdown({
                ...breakdown,
                loading: false,
                players: res.breakdown,
                odds: res.full_odds,
                standings: res.standings,
                pickScoreRevealRights: res.pick_score_reveal_rights,
                so5ScoresDetailed: res.so5_scores_detailed,
                gwStatus: res.gw_status,
              });
              setSelectedGw({
                ...selectedGw,
                status: res.gw_status,
              });
              ReactTooltip.rebuild();
            })
            .catch(
              errorCatcher(() => {
                ReactTooltip.rebuild();
                setBreakdown({ ...breakdown, loading: false });
              }),
            );
        }
      }
    },
    [breakdown, lineup, selectedGw, selectedLineupOrDivision, filters, targetUserId, draftId],
  );

  const createLineup = useCallback(
    (lineupOrDiv, lineup) => {
      let captainId = "";
      if (lineup.captain !== null) captainId = lineup.captain.card.TokenId;
      const lCards = lineup.current?.map((p) => p?.card?.TokenId);
      setLineup({ ...lineup, saving: true });
      props
        .fetch(`/decision-api/decision/lub/drafts/id/${draftId}/lineups/new`, {
          method: "POST",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            createdFor: targetUserId,
            divisionId: lineupOrDiv.divisionId,
            teamNumber: lineupOrDiv.team?.lineupTeamNumber, // Optional
            managerTeamId: lineupOrDiv.team?.managerTeam?.id, // Optional
            draftId: draftId,
            cards: lCards,
            captain: captainId,
          }),
        })
        .then((response) => response.json())
        .then((res) => {
          if (res.error === undefined) {
            addValidationMessage({
              msg: "Lineup created",
              warning: false,
              timeout: 2000,
            });
            resetSuggestion(true);
            const newSavedLineup = {
              lineupId: res.id,
              divisionId: res.divisionId,
              lineupTeamNumber: res.teamNumber > 1 ? res.teamNumber : undefined,
              cards: lineup.current,
              createdAt: res.createdAt,
              isIncomplete: lCards.some((c) => c === null),
            };
            setCompetitions({
              ...competitions,
              savedLineupsById: {
                ...competitions.savedLineupsById,
                [res.id]: newSavedLineup,
              },
            });
            setSelectedLineupOrDivision({
              ...lineupOrDiv,
              divisionId: newSavedLineup.divisionId,
              team: {
                lineup: {
                  id: newSavedLineup.lineupId,
                },
                managerTeam: lineupOrDiv.team?.managerTeam?.id ? { id: lineupOrDiv.team?.managerTeam?.id } : undefined,
                lineupTeamNumber: newSavedLineup.lineupTeamNumber,
              },
            });
            setRefresh(refresh + 1);
            resetSuggestion(true);
            setLineup({ ...lineup, saved: lineup.current, hasChangesToSave: false, saving: false });
            onSaveLineup && onSaveLineup(res.id);
          } else {
            addValidationMessage({
              msg: "Unable to create lineup",
              warning: true,
            });
            setLineup({ ...lineup, saving: false });
          }
        })
        .catch(
          errorCatcher(() => {
            setLineup({ ...lineup, saving: false });
            addValidationMessage({
              msg: "Unable to create lineup",
              warning: true,
            });
          }),
        );
    },
    [selectedLineupOrDivision, competitions, draftId, gw, refresh, targetUserId],
  );

  const saveLineup = useCallback(
    (lineupId, lineup) => {
      let captainId = "";
      if (lineup.captain !== null) captainId = lineup.captain.card.TokenId;
      const lCards = lineup.current.map((p) => (p ? p.card.TokenId : null));
      setLineup({ ...lineup, saving: true });
      props
        .fetch(`/decision-api/decision/lub/drafts/id/${draftId}/lineups/id/${lineupId}`, {
          method: "PUT",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            cards: lCards,
            captain: captainId,
          }),
        })
        .then((response) => response.json())
        .then((res) => {
          setLineup({ ...lineup, saved: lineup.current, hasChangesToSave: false, saving: false });
          if (res.error === undefined) {
            addValidationMessage({
              msg: "Lineup updated",
              warning: false,
              timeout: 2000,
            });
            setRefresh(refresh + 1);
            resetSuggestion(true);
            onSaveLineup && onSaveLineup(res.id);
          } else {
            addValidationMessage({
              msg: "Unable to update lineup",
              warning: true,
            });
          }
        })
        .catch(
          errorCatcher(() => {
            setLineup({ ...lineup, saving: false });
            addValidationMessage({
              msg: "Unable to update lineup",
              warning: true,
            });
          }),
        );
    },
    [lineup, draftId, refresh],
  );

  const deleteLineup = useCallback(
    (lineupId) => {
      setLineup({ ...lineup, saving: true });
      props
        .fetch(`/decision-api/decision/lub/drafts/id/${draftId}/lineups/id/${lineupId}`, {
          method: "DELETE",
        })
        .then((response) => response.json())
        .then((res) => {
          if (res.error === undefined) {
            addValidationMessage({
              msg: "Lineup deleted",
              warning: false,
              timeout: 2000,
            });
            resetSuggestion(true);
            setLineup({
              ...lineup,
              lineupId: "",
              current: initialLineupPlayers,
              saving: false,
              hasChangesToSave: false,
            });
            setSelectedLineupOrDivision(getItemFromCompAndOptionalTeam(competitions.competitionsByDivId[res.divisionId]));
            setRefresh(refresh + 1);
            onSaveLineup && onSaveLineup("");
          } else {
            addValidationMessage({
              msg: "Unable to update lineup",
              warning: true,
            });
          }
        })
        .catch(
          errorCatcher(() => {
            addValidationMessage({
              msg: "Unable to update lineup",
              warning: true,
            });
            setLineup({ ...lineup, saving: false });
          }),
        );
    },
    [competitions, lineup],
  );

  const updateCommonCards = useCallback(() => {
    props
      .fetch(`/apiv2/user/updateCommonCards`, { method: "POST" })
      .then((response) => response.json())
      .then((res) => {
        fetchPlayers({});
      })
      .catch(errorCatcher());
  }, [fetchPlayers, props.fetch]);

  const loadSavedLineupsAndCompetitions = useCallback(() => {
    if (draftId && sport && gw) {
      competitions.abortSignal.abort();
      const newAbort = new AbortController();
      setCompetitions({ ...competitions, loading: true, abortSignal: newAbort });
      const params = new URLSearchParams();
      params.append("sport", sport);
      params.append("gwNumber", gw);
      params.append("createdFor", targetUserId);
      params.append("withOdds", "true");
      params.append("withPrizePool", "true");
      params.append("withSuggestionsRights", "true");
      params.append("withDivisionMaxScores", "true");
      params.append("splitTeamsInGroups", "true");
      params.append("groupBy", "enteredAndAvailability");

      props
        .fetch(`/decision-api/decision/lub/drafts/id/${draftId}/lineups/v2?${params.toString()}`, {
          signal: newAbort.signal,
        })
        .then((response) => response.json())
        .then((res) => {
          const newCompetitions = {
            ...competitions,
            loading: false,
          };
          if (res.error === undefined) {
            // Update data
            const allCompetitions = [
              ...res.enteredCompetitions,
              ...res.availableCompetitions,
              ...res.disabledCompetitions,
              ...res.unavailableCompetitions,
            ];
            newCompetitions.teamsByIds = getTeamsByLineupId(allCompetitions);
            newCompetitions.savedLineupsById = getLineupsByIdFromTeamByIds(newCompetitions.teamsByIds.byLineupId.team);
            newCompetitions.competitionsByDivId = getCompetitionsByDivId(allCompetitions);
            newCompetitions.suggestionLimits = res.suggestionRights;
            newCompetitions.maxScores = res.divisionMaxScores;
            newCompetitions.maxScoresNoCaptain = res.divisionMaxScoresNoCaptain;
            newCompetitions.prizePools = res.prizePool;
            newCompetitions.enteredCompetitions = res.enteredCompetitions;
            newCompetitions.availableCompetitions = res.availableCompetitions;
            newCompetitions.disabledCompetitions = res.disabledCompetitions;
            newCompetitions.unavailableCompetitions = res.unavailableCompetitions;

            // Try to select the right meaningful lineup or division
            const { mostSuitable, hasChanged } = getMostSuitableDivOrLineupToSelect(
              selectedLineupOrDivision,
              allCompetitions,
              newCompetitions.teamsByIds,
            );
            if (mostSuitable !== null && hasChanged) {
              onChangeDivisionOrLineup(mostSuitable);
            }
            if (res.fullOdds) {
              setOdds({ ...odds, full: res.fullOdds });
            }
            setProjections(res.projectedLineups);
            if (lineup.position === "") {
              setLineup({
                ...lineup,
                position: so5_positions[0],
              });
              fetchPlayers({
                position: so5_positions[0],
                division: mostSuitable.divisionId,
                lineupId: mostSuitable.team?.lineup?.id,
              });
            }
          }
          setCompetitions(newCompetitions);
        })
        .catch(
          errorCatcher(() => {
            setCompetitions({ ...competitions, loading: false });
          }),
        );
    }
  }, [draftId, lineup, gw, sport, targetUserId, selectedLineupOrDivision]);

  const samePlayerCheck = useCallback(
    (lineup) => {
      const hasSamePlayers = lineupHasSamePlayers(lineup);
      if (hasSamePlayers) {
        addValidationMessage({
          msg: "Lineup is invalid: at least two cards with the same player",
          warning: true,
        });
      }
    },
    [addValidationMessage],
  );

  const emptySpotsCheck = useCallback(
    (lineup) => {
      const emptySpots = emptySpotsFromLineup(lineup);
      if (emptySpots.length > 0) {
        addValidationMessage({
          msg: "No card for position(s): " + emptySpots.join(", "),
          warning: true,
        });
      }
    },
    [addValidationMessage],
  );

  const emptyLineupCheck = useCallback(
    (lineup) => {
      const isLineupEmpty = lineup.filter((c) => c !== null && c !== undefined).length === 0;
      if (isLineupEmpty) {
        addValidationMessage({
          msg: "Couldn't suggest lineup. Not enough available cards.",
          warning: true,
        });
        resetSuggestion(true);
      }
    },
    [addValidationMessage, resetSuggestion],
  );

  const stopSuggesting = useCallback(() => {
    setSuggestion({ ...suggestion, suggesting: [false, false, false, false, false] });
  }, [suggestion]);

  const fetchSuggestions = (sugg, lineu) => {
    const s = sugg || suggestion;
    const l = lineu || lineup;

    const newLocked = { ...s.lockedCards };
    const discarded = s.discardedCards;
    const suggestions = s.results;
    const lockedCardIds = l.current.map((p) => p?.card.TokenId).filter((c) => c !== undefined && c !== null);
    if (s.mode === false) {
      //First suggestion
      if (l.current[0] !== null) {
        newLocked["Goalkeeper"] = l.current[0].card.TokenId;
      }
      if (l.current[1] !== null) {
        newLocked["Defender"] = l.current[1].card.TokenId;
      }
      if (l.current[2] !== null) {
        newLocked["Midfielder"] = l.current[2].card.TokenId;
      }
      if (l.current[3] !== null) {
        newLocked["Forward"] = l.current[3].card.TokenId;
      }
      if (l.current[4] !== null) {
        newLocked["Extra"] = l.current[4].card.TokenId;
      }
    }
    let newSuggestion = { ...s, mode: true, lockedCards: newLocked };
    setSuggestion(newSuggestion);
    let nextComb = {};
    let nextCombinationFound = false;
    let combinationsLeft = [];
    if (suggestions.type === "discard" && suggestions.combinations.length > 0) {
      for (let index = 0; index < suggestions.combinations.length; index++) {
        const element = suggestions.combinations[index];
        if (nextCombinationFound) {
          combinationsLeft.push(element);
        }
        if (!nextCombinationFound && isCombinationEligible(newSuggestion.results.cards, element, newLocked, discarded)) {
          nextComb = element;
          nextCombinationFound = true;
        }
      }
      const newResults = { ...suggestions, combinations: combinationsLeft };
      newSuggestion = { ...newSuggestion, results: newResults };
      setSuggestion(newSuggestion);
    }
    if (nextCombinationFound) {
      const newLineup = buildLineupFromCombinationByDiscard(suggestions.cards, nextComb.TokenIds, suggestions.pick_score_map);
      const newSuggesting = checkWhichLineupPositionShouldBeSuggesting(l.current, newLineup);
      newSuggestion = { ...newSuggestion, suggesting: newSuggesting };
      setSuggestion(newSuggestion);
      setTimeout(() => {
        // Simulate wait to let the user see the suggestion
        emptySpotsCheck(newLineup);
        const newLineupProjections = getProjectionsFromLineup(newLineup);
        setProjections({ ...projections, ...newLineupProjections });
        const newCaptain = getBestCaptainOnSuggestion(selectedCompetitionInfo, newLineup, lockedCardIds, l.captain);
        const hasLineupChanges = hasLineupChangesToSave(newCaptain, newLineup, l.saved);
        setLineup({ ...l, current: newLineup, captain: newCaptain, hasChangesToSave: hasLineupChanges });
        newSuggestion = { ...newSuggestion, suggesting: allLineupNotSuggesting };
        setSuggestion(newSuggestion);
      }, 700);
    } else if (combinationsLeft.length === 0 && suggestions.type === "discard" && !suggestions.has_next_combination) {
      // Do nothing, there is no suggestion left for discard, no need to call the backend once again
    } else {
      // All positions are possible to be changed as we don't have any combination left
      newSuggestion = { ...newSuggestion, suggesting: allLineupSuggesting };
      setSuggestion(newSuggestion);
      const startTime = Date.now();
      props
        .fetch(`/suggestions/decision/lub/suggestions`, {
          method: "POST",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            user_sorare_id: targetUserId,
            division: selectedLineupOrDivision.divisionId,
            discarded_cards: Object.keys(discarded),
            locked_cards: newLocked,
            draft_id: draftId,
            lineup_id: selectedLineupOrDivision.team?.lineup?.id,
          }),
        })
        .then((response) => getPaginatedJSONResponse(response))
        .then(({ res, hasNext }) => {
          if (res.combinations?.length > 0) {
            const bestComb = res.combinations[0].TokenIds;
            const newLineup = buildLineupFromCombinationByDiscard(res.cards, bestComb, res.pick_score_map);
            const delay = Math.max(0, 700 - (Date.now() - startTime));
            setTimeout(() => {
              const newLineupProjections = getProjectionsFromLineup(newLineup);
              emptySpotsCheck(newLineup);
              setProjections({ ...projections, ...newLineupProjections });
              const newSuggestionResults = {
                combinations: res.combinations,
                has_next_combination: hasNext,
                has_more_players_combinations: res.has_more_players_combinations,
                cards_by_position: null,
                type: res.type,
                cards: res.cards,
                pick_score_map: res.pick_score_map,
              };
              const newCaptain = getBestCaptainOnSuggestion(selectedCompetitionInfo, newLineup, lockedCardIds, l.captain);
              const hasLineupChanges = hasLineupChangesToSave(newCaptain, newLineup, l.saved);
              setLineup({ ...l, current: newLineup, captain: newCaptain, hasChangesToSave: hasLineupChanges });
              samePlayerCheck(newLineup);
              newSuggestion = { ...newSuggestion, suggesting: allLineupNotSuggesting, results: newSuggestionResults };
              setSuggestion(newSuggestion);
            }, delay);
          } else if (res.cards_by_position && Object.keys(res.cards_by_position).length > 0) {
            const pickedPlayersIds = l.current.map((c, i) => c?.card?.PlayerId).filter((c) => c !== undefined);
            const cardsByPosition = res.cards_by_position;
            const pickScoresByPlayerId = res.pick_score_map;
            const cards = res.cards;
            const newLineup = buildSuggestedLineup(l.current, pickedPlayersIds, cards, cardsByPosition, pickScoresByPlayerId);
            const delay = Math.max(0, 700 - (Date.now() - startTime));
            setTimeout(() => {
              const newLineupProjections = getProjectionsFromLineup(newLineup);
              setProjections({ ...projections, ...newLineupProjections });
              const newSuggestionResults = {
                combinations: null,
                cards_by_position: cardsByPosition,
                type: res.type,
                cards: cards,
                pick_score_map: pickScoresByPlayerId,
              };
              const newSuggestionBrowseInfo = buildSuggestionBrowseInfo(newLineup, cardsByPosition, cards);
              const newCaptain = getBestCaptainOnSuggestion(selectedCompetitionInfo, newLineup, lockedCardIds, l.captain);
              const hasLineupChanges = hasLineupChangesToSave(newCaptain, newLineup, l.saved);
              emptySpotsCheck(newLineup);
              samePlayerCheck(newLineup);
              setLineup({ ...l, current: newLineup, captain: newCaptain, hasChangesToSave: hasLineupChanges });
              newSuggestion = {
                ...newSuggestion,
                suggesting: allLineupNotSuggesting,
                results: newSuggestionResults,
                browseInfo: newSuggestionBrowseInfo,
              };
              setSuggestion(newSuggestion);
            }, delay);
          } else {
            const delay = Math.max(0, 300 - (Date.now() - startTime));
            setTimeout(() => {
              emptyLineupCheck(l.current);
              const newSuggestionResults = {
                ...suggestions,
                type: res.type,
                combinations: [],
                has_next_combination: hasNext,
                has_more_players_combinations: {},
              };
              newSuggestion = { ...newSuggestion, suggesting: allLineupNotSuggesting, results: newSuggestionResults };
              setSuggestion(newSuggestion);
            }, delay);
          }
        })
        .catch(
          errorCatcher((e) => {
            stopSuggesting();
          }),
        );
    }
  };

  const onRemoveLockedCard = useCallback(
    (tokenId) => {
      const newLockedCards = removeLockedCard(tokenId, suggestion.lockedCards);
      setSuggestion({ ...suggestion, lockedCards: newLockedCards });
    },
    [suggestion],
  );

  const addLockedCard = useCallback(
    (tokenId, position) => {
      suggestion.lockedCards[position] = tokenId;
      setSuggestion({ ...suggestion, lockedCards: suggestion.lockedCards });
    },
    [suggestion],
  );

  const onDisplayDiscardDetails = useCallback(() => {
    setSuggestion({ ...suggestion, discardPanelOpen: !suggestion.discardPanelOpen });
  }, [suggestion]);

  const closeDiscardDetails = useCallback(() => {
    setSuggestion({ ...suggestion, discardPanelOpen: false });
  }, [suggestion]);

  const onDiscardCard = useCallback(
    (entry) => {
      const newDiscard = buildDiscardKey(entry.card);
      const newPrevious = lineup.current.slice();
      const newDiscardedCards = { ...suggestion.discardedCards, [newDiscard]: entry };
      const newLockedCards = removeLockedCard(entry.card.TokenId, suggestion.lockedCards);
      const newSuggestion = {
        ...suggestion,
        discardedCards: newDiscardedCards,
        lockedCards: newLockedCards,
      };
      const newLineup = { ...lineup, previous: newPrevious };
      setLineup(newLineup);
      setSuggestion(newSuggestion);
      fetchSuggestions(newSuggestion, newLineup);
    },
    [suggestion, lineup, fetchSuggestions],
  );

  const onDiscardRevert = useCallback(
    (entry) => {
      const newDiscardToRemove = buildDiscardKey(entry.card);
      const newPrevious = lineup.current.slice();
      const newDiscardedCards = { ...suggestion.discardedCards };
      delete newDiscardedCards[newDiscardToRemove];
      const newSuggestionResults = {
        ...initialSuggestionResults,
        type: "discard",
        has_next_combination: true,
      };
      const newLockedCards = removeLockedCard(entry.card.TokenId, suggestion.lockedCards);
      const newSuggestion = {
        ...suggestion,
        previous: newPrevious,
        discardedCards: newDiscardedCards,
        results: newSuggestionResults,
        lockedCards: newLockedCards,
        discardPanelOpen: Object.keys(newDiscardedCards).length > 0,
      };
      setSuggestion(newSuggestion);
      fetchSuggestions(newSuggestion);
    },
    [suggestion, lineup, fetchSuggestions],
  );

  const onDiscardRevertAll = useCallback(() => {
    const newPrevious = lineup.current.slice();
    const newDiscardedCards = {};
    const newSuggestionResults = {
      ...initialSuggestionResults,
      type: "discard",
      has_next_combination: true,
    };
    const newSuggestion = {
      ...suggestion,
      previous: newPrevious,
      discardedCards: newDiscardedCards,
      results: newSuggestionResults,
      discardPanelOpen: false,
    };
    setSuggestion(newSuggestion);
    fetchSuggestions(newSuggestion);
  }, [suggestion, lineup, fetchSuggestions]);

  const onChangeDiscardState = useCallback(
    (newState) => {
      if (newState === "previous") {
        const newSuggesting = lineup.previous.map((p, i) => {
          const a = p?.card?.TokenId;
          const b = lineup.current[i]?.card?.TokenId;
          return a !== b;
        });
        setSuggestion({ ...suggestion, suggesting: newSuggesting });
        setTimeout(() => {
          // Simulate wait to let the user see the suggestion
          setSuggestion({ ...suggestion, suggesting: allLineupNotSuggesting });
        }, 500);
      }
    },
    [suggestion, lineup],
  );

  const onRevertToPreviousLineup = useCallback(() => {
    setLineup({ ...lineup, current: lineup.previous });
    resetSuggestion(true);
    addValidationMessage({
      msg: "Discarded lineup has been reverted",
      warning: false,
      timeout: 2000,
    });
  }, [lineup, resetSuggestion, addValidationMessage]);

  const afterBrowse = (currentPos, newIndex, indexes) => {
    const currentIndex = so5_positions.indexOf(currentPos);
    indexes[currentIndex] = newIndex;
    const newLineup = [...lineup.current];
    const cardTokenId = suggestion.browseInfo.cardsByPosition[currentPos][indexes[currentIndex]];
    const newCard = suggestion.results.cards[cardTokenId];
    if (newCard) {
      newCard.pick_score_group = suggestion.results.pick_score_map?.[newCard.card.PlayerId]?.[currentPos];
    }
    newLineup[currentIndex] = suggestion.results.cards[cardTokenId];
    const newLineupProjections = getProjectionsFromLineup(newLineup);
    const newPickedPlayers = pickedPlayersFromLineup(newLineup);
    const newHasPrev = hasPrevByPositions(
      indexes,
      suggestion.browseInfo.cardsByPosition,
      suggestion.results.cards,
      newPickedPlayers,
      newLineup,
    );
    const newHasNext = hasNextByPositions(
      indexes,
      suggestion.browseInfo.cardsByPosition,
      suggestion.results.cards,
      newPickedPlayers,
      newLineup,
    );
    const newBrowseInfo = {
      cardsByPosition: suggestion.browseInfo.cardsByPosition,
      indexes: indexes,
      hasPrev: newHasPrev,
      hasNext: newHasNext,
    };
    setProjections({ ...projections, ...newLineupProjections });
    const hasLineupChanges = hasLineupChangesToSave(lineup.captain, newLineup, lineup.saved);
    setLineup({ ...lineup, current: newLineup, hasChangesToSave: hasLineupChanges });
    setSuggestion({
      ...suggestion,
      browseInfo: newBrowseInfo,
    });
  };

  const onBrowsePrevious = useCallback(
    (pos) => {
      const posIndex = so5_positions.indexOf(pos);
      const newIndexes = [...suggestion.browseInfo.indexes];
      const pickedPlayers = pickedPlayersFromLineup(lineup.current);
      let newIndex = -1;
      const cardInLineupPlayerId = lineup.current[posIndex]?.card?.PlayerId;
      for (let i = newIndexes[posIndex] - 1; i >= 0; i--) {
        // Get the first previous not picked
        const cardTokenId = suggestion.browseInfo.cardsByPosition?.[pos]?.[i];
        const card = suggestion.results.cards?.[cardTokenId];
        const playerId = card?.card?.PlayerId;
        if (card && (!pickedPlayers[playerId] || (cardInLineupPlayerId && cardInLineupPlayerId === playerId))) {
          newIndex = i;
          break;
        } else {
          console.log("Ignoring card " + cardTokenId + ", because player " + card.player.DisplayName + " is already picked");
        }
      }
      if (newIndex === -1) {
        console.error("No previous card found, but should not happen here");
      }
      afterBrowse(pos, newIndex, newIndexes);
    },
    [lineup, suggestion],
  );

  const onBrowseNext = useCallback(
    (pos) => {
      const posIndex = so5_positions.indexOf(pos);
      const newIndexes = [...suggestion.browseInfo.indexes];
      const pickedPlayers = pickedPlayersFromLineup(lineup.current);
      let newIndex = -1;
      const cardInLineupPlayerId = lineup.current[posIndex]?.card?.PlayerId;
      for (let i = newIndexes[posIndex] + 1; i < suggestion.browseInfo.cardsByPosition?.[pos].length; i++) {
        // Get the first next not picked
        const cardTokenId = suggestion.browseInfo.cardsByPosition?.[pos]?.[i];
        const card = suggestion.results.cards?.[cardTokenId];
        const playerId = card?.card?.PlayerId;
        if (card && (!pickedPlayers[card.card.PlayerId] || (cardInLineupPlayerId && cardInLineupPlayerId === playerId))) {
          newIndex = i;
          break;
        } else {
          console.log("Ignoring card " + cardTokenId + ", because player " + card.player.DisplayName + " is already picked");
        }
      }
      if (newIndex === -1) {
        console.error("No next card found, but should not happen here");
      }
      afterBrowse(pos, newIndex, newIndexes);
    },
    [lineup, suggestion],
  );

  const canDiscardSuggestionByPosition = useCallback(() => {
    return lineup.current.map((c, i) => {
      if (suggestion.results.type !== "discard" || c === null || c === undefined) {
        return false;
      }
      // If we had to discard this one + the ones already discarded
      // Is there any combination left with cards not discarded for this position ?
      const newDiscard = buildDiscardKey(c.card);
      const potentialDiscardedCards = { ...suggestion.discardedCards, [newDiscard]: c };
      for (let j = 0; j < suggestion.results?.combinations?.length; j++) {
        const combination = suggestion.results?.combinations[j];
        const combinationForPosition = combination.TokenIds[so5_positions[i]];
        const combinationDiscardCard = suggestion.results.cards[combinationForPosition]?.card;
        if (combinationDiscardCard === undefined) {
          continue;
        }
        const combinationDiscardCardDiscardId = buildDiscardKey(combinationDiscardCard);
        if (Object.keys(potentialDiscardedCards).includes(combinationDiscardCardDiscardId)) {
          continue;
        } else {
          return true;
        }
      }
      if (suggestion.results.has_next_combination) {
        // No combination left but apparently there maybe more on the server
        // Check if it's really the case
        if (suggestion.results.has_more_players_combinations[so5_positions[i]]) {
          return true;
        }
      }
      return false;
    });
  }, [lineup, suggestion]);

  const onChangePickedTab = (tab) => {
    setIsOnHistoryTab(tab === "historical");
  };

  const onChangeHistoricalGraphOlderPage = useCallback(() => {
    changeHistoricalGraphPage(historicalPerformance.range.min - 8, historicalPerformance.range.min - 1);
  }, [historicalPerformance]);

  const onChangeHistoricalGraphNewerPage = useCallback(() => {
    changeHistoricalGraphPage(historicalPerformance.range.max + 1, historicalPerformance.range.max + 8);
  }, [historicalPerformance]);

  const changeHistoricalGraphPage = useCallback(
    (newMin, newMax) => {
      const newValue = {
        min: newMin,
        max: newMax,
      };
      setHistoricalPerformance({ ...historicalPerformance, range: newValue });
      getGraphStats(undefined, undefined, undefined, undefined, newValue);
    },
    [historicalPerformance],
  );
  const allowCommonCards = selectedCompetitionInfo?.comp?.rules?.allowedScarcities?.includes("COMMON");
  const selectedPlayerEntry = getSelectedPlayer(lineup.position, lineup.current);
  const selectedLineupId = selectedLineupOrDivision.team?.lineup?.id;
  const selectedDivisionId = selectedLineupOrDivision.divisionId;
  return (
    <div className={"h-full w-full bg-surface"}>
      <div className={"mx-2 sm:mx-4 mb-8 4xl:mx-auto 4xl:w-10/12 4.5xl:w-9/12"}>
        <div className={"space-y-4"}>
          <div className={"flex flex-col md:flex-row justify-between gap-2"}>
            <div className={"flex flex-col gap-2 lg:flex-row w-full sm:w-9/12"}>
              <div className={"flex flex-col sm:flex-row gap-2 items-center"}>
                <div className={"h-10 w-full sm:w-auto"}>
                  <LineupBuilderDivisionOrLineupPicker
                    loading={competitions.loading}
                    selectedItem={selectedLineupOrDivision}
                    enteredCompetitions={competitions.enteredCompetitions}
                    availableCompetitions={competitions.availableCompetitions}
                    disabledCompetitions={competitions.disabledCompetitions}
                    unavailableCompetitions={competitions.unavailableCompetitions}
                    onChange={(v) => interceptAction(() => onChangeDivisionOrLineup(v))}
                    sport={sport}
                    preferences={preferences}
                  />
                </div>
                <LUBFilterAndPreferencesToggle
                  gw={gw}
                  draftId={draftId}
                  targetUserId={targetUserId}
                  division={selectedCompetitionInfo?.comp}
                  pickedPosition={lineup.position}
                  onApplyFilters={(f) => {
                    fetchPlayers(f);
                    setFilters(f);
                  }}
                  preferences={preferences}
                  onPreferenceChange={onPreferenceChange}
                  onResetPreferences={onResetPreferences}
                  onOpenPreferences={reloadPreferencesFromLocalStorage}
                  tier={props.user.tier}
                />
              </div>
              {allowCommonCards && (
                <div>
                  <Button adapting context={"neutral"} label={"Update my common cards"} onClick={updateCommonCards} />
                </div>
              )}
            </div>
            <div className={"flex flex-row gap-1 w-full justify-center md:w-3/12 md:justify-end items-center"}>
              <LearnMoreAboutLUBTiers />
              <div className={"hidden sm:block h-full border-r border-outline-variant h-6"}></div>
              <div className={"hidden sm:block"}>
                <ViewLUBTableLegend tab={legendTab} onClose={() => setLegendTab("")} />
              </div>
            </div>
          </div>
          <div className={"w-full gap-4 flex flex-col lg:flex-row"}>
            <div className={"flex flex-row justify-center lg:justify-start w-full lg:max-w-[inherit] lg:w-[30em]"}>
              <div ref={ref} className={`w-full xs:max-w-[25em] lg:max-w-[inherit] lg:w-[30em]`}>
                <Manager>
                  <Reference innerRef={lineupRef}>
                    {(pro) => (
                      <div className={"relative"} ref={pro.ref}>
                        <LineupBuilderLineup
                          lineupInfo={lineup}
                          selectedLineup={selectedLineupOrDivision}
                          division={selectedCompetitionInfo}
                          suggestion={suggestion}
                          historicalPerformance={historicalPerformance}
                          odds={odds.full}
                          standings={breakdown.standings}
                          saveLineup={() => {
                            if (selectedLineupId) {
                              if (lineup.current.length === 0 || lineup.current?.filter((c) => c !== null).length === 0) {
                                deleteLineup(selectedLineupId);
                              } else {
                                saveLineup(selectedLineupId, lineup);
                              }
                            } else {
                              createLineup(selectedLineupOrDivision, lineup);
                            }
                          }}
                          resetLineup={() => resetLineup()}
                          validationMessages={validationMessages}
                          onCloseValidationMessage={onCloseValidationMessage}
                          setCaptain={onChangeCaptain}
                          removeCaptain={onRemoveCaptain}
                          changePosition={onChangePosition}
                          removeCard={onRemoveCardOnLineup}
                          projections={projections}
                          preferences={preferences}
                          targetUserId={targetUserId}
                          gw={gw}
                          draftId={draftId}
                          pickScoreReveal={pickScoreReveal}
                          onSuggest={fetchSuggestions}
                          onDiscard={onDiscardCard}
                          removeLockedCard={onRemoveLockedCard}
                          addLockedCard={addLockedCard}
                          suggestionCombinationHasNext={canDiscardSuggestionByPosition()}
                          onBrowsePrevious={onBrowsePrevious}
                          onBrowseNext={onBrowseNext}
                          historicalGraph={historicalPerformance}
                          onChangeHistoricalGraphOlderPage={onChangeHistoricalGraphOlderPage}
                          onChangeHistoricalGraphNewerPage={onChangeHistoricalGraphNewerPage}
                          onDisplayDiscardDetails={onDisplayDiscardDetails}
                          isDisplayingDiscardDetails={suggestion.discardPanelOpen && mdQueries["lg"]}
                          onChangeDiscardState={onChangeDiscardState}
                          onRevertToPreviousLineup={onRevertToPreviousLineup}
                          onChangePickedTab={onChangePickedTab}
                          loadingComps={competitions.loading}
                          team={selectedLineupOrDivision.team}
                        />
                        {suggestion.discardPanelOpen && mdQueries["lg"] && (
                          <div
                            ref={discardPanelRef}
                            className={
                              "absolute right-0 top-2/4 transform -translate-y-2/4 translate-x-[calc(100%+0.8em)] dim rounded-lg max-h-screen"
                            }
                          >
                            <DiscardedPlayersModale
                              discardedCards={suggestion.discardedCards}
                              lineup={lineup.current}
                              lineupId={selectedLineupId}
                              close={closeDiscardDetails}
                              onDiscardRevert={onDiscardRevert}
                              onDiscardRevertAll={onDiscardRevertAll}
                              targetUserId={targetUserId}
                              divId={selectedDivisionId}
                              onLoad={() => {
                                console.log("updated");
                              }}
                              gw={gw}
                              width={
                                lineupRef?.current?.offsetWidth
                                  ? `min(100vw - ${lineupRef?.current?.offsetWidth}px - 4rem, 1500px)`
                                  : undefined
                              }
                              draftId={draftId}
                              tableProps={{
                                isCardInCurrentLineup,
                                pickCard,
                                odds: odds.full,
                                checkPlayer,
                                standings: breakdown.standings,
                                preferences,
                                divRules: selectedCompetitionInfo?.comp?.rules,
                                divId: selectedDivisionId,
                                so5ScoresDetailed: breakdown.so5ScoresDetailed,
                                oppScoresDetailed: breakdown.oppScoresDetailed,
                                pickScoreRevealRights: breakdown.pickScoreRevealRights,
                                loading: breakdown.loading,
                                lineupPoints,
                                pickScoreReveal,
                                revealing,
                                onPickScoreReveal: onRevealPlayer,
                                selectedEntry: getSelectedPlayer(lineup.position, lineup.current),
                                gwStatus: selectedGw.status,
                              }}
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </Reference>
                  <DiscardedPlayersModaleMobile
                    discardedCards={suggestion.discardedCards}
                    lineup={lineup.current}
                    lineupId={selectedLineupId}
                    open={suggestion.discardPanelOpen && !mdQueries["lg"]}
                    close={closeDiscardDetails}
                    onDiscardRevert={onDiscardRevert}
                    onDiscardRevertAll={onDiscardRevertAll}
                    targetUserId={targetUserId}
                    divId={selectedDivisionId}
                    gw={gw}
                    draftId={draftId}
                    tableProps={{
                      isCardInCurrentLineup,
                      pickCard,
                      odds: odds.full,
                      checkPlayer,
                      standings: breakdown.standings,
                      preferences,
                      divRules: selectedCompetitionInfo?.comp?.rules,
                      divId: selectedDivisionId,
                      so5ScoresDetailed: breakdown.so5ScoresDetailed,
                      oppScoresDetailed: breakdown.oppScoresDetailed,
                      pickScoreRevealRights: breakdown.pickScoreRevealRights,
                      loading: breakdown.loading,
                      lineupPoints,
                      pickScoreReveal,
                      revealing,
                      onPickScoreReveal: onRevealPlayer,
                      selectedEntry: selectedPlayerEntry,
                      gwStatus: selectedGw.status,
                    }}
                  />
                </Manager>
              </div>
            </div>
            <div className={`w-full lg:w-full`} style={mdQueries["sm"] ? { height: height } : {}} id={"table_scroll"}>
              <LineupBuilderList
                currentLineupId={selectedLineupId}
                players={breakdown.players}
                containerHeight={height}
                containerOffset={"3em"}
                isCardInCurrentLineup={isCardInCurrentLineup}
                pickCard={pickCard}
                odds={odds.full}
                checkPlayer={checkPlayer}
                standings={breakdown.standings}
                preferences={preferences}
                divRules={selectedCompetitionInfo?.comp?.rules}
                divId={selectedDivisionId}
                so5ScoresDetailed={breakdown.so5ScoresDetailed}
                oppScoresDetailed={breakdown.oppScoresDetailed}
                pickScoreRevealRights={breakdown.pickScoreRevealRights}
                loading={breakdown.loading}
                lineupPoints={lineupPoints}
                openLegend={(tab) => setLegendTab(tab)}
                pickScoreReveal={pickScoreReveal}
                revealing={revealing}
                onPickScoreReveal={onRevealPlayer}
                selectedEntry={selectedPlayerEntry}
                gwStatus={selectedGw.status}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

const getCompetitionInfo = (selectedDivOrTeam, competitions) => {
  let divisionId = "";
  let divisionType = "";
  if (selectedDivOrTeam.team) {
    divisionId = selectedDivOrTeam.team.competitionId;
    divisionType = selectedDivOrTeam.team.competitionType;
  }
  const comp = competitions?.competitionsByDivId?.[divisionId];
  const suggestionLimits = competitions?.suggestionLimits?.[divisionId];
  const maxScoresNormal = competitions?.maxScores?.[divisionId];
  const maxScoresNoCaptain = competitions?.maxScoresNoCaptain?.[divisionId];
  const prizePool = competitions?.prizePools?.[divisionId];
  const loading = competitions.loading;
  return {
    comp,
    suggestionLimits,
    maxScores: {
      normal: maxScoresNormal,
      noCaptain: maxScoresNoCaptain,
    },
    prizePool,
    divisionType,
    divisionId,
    loading,
    team: selectedDivOrTeam.team,
  };
};

const getSelectedPlayer = (position, lineup) => {
  const index = so5_positions.findIndex((e) => e === position);
  if (index === -1) return null;
  return lineup[index];
};

const buildDiscardKey = (card) => {
  return card.PlayerId + "_" + card.Scarcity + "_" + card.Position;
};

const isCardInLineup = (tokenId, lineup, lineupPosition) => {
  const indexInLineup = lineup ? lineup.findIndex((player) => player?.card?.TokenId === tokenId) : -1;
  return {
    value: indexInLineup > -1,
    position: indexInLineup > -1 ? so5_positions[indexInLineup] : "",
    isSelectedPosition: indexInLineup > -1 ? so5_positions[indexInLineup] === lineupPosition : false,
  };
};

const isPlayedAbsentFromLineup = (playerId, lineup) => {
  let notHere = true;
  lineup?.current?.map((player) => {
    if (player !== null) {
      if (player.card.PlayerId === playerId) {
        notHere = false;
      }
    }
  });
  return notHere;
};

const getNextLineupPositionToSelect = (selectedPosition, cardPosition) => {
  let index;
  let nextPosition;
  if (selectedPosition === "Goalkeeper") {
    index = 0;
    nextPosition = "Defender";
  } else if (selectedPosition === "Defender" && cardPosition === "Defender") {
    index = 1;
    nextPosition = "Midfielder";
  } else if (selectedPosition === "Midfielder" && cardPosition === "Midfielder") {
    index = 2;
    nextPosition = "Forward";
  } else if (selectedPosition === "Forward" && cardPosition === "Forward") {
    index = 3;
    nextPosition = "Extra";
  } else {
    index = 4;
    nextPosition = "";
  }
  return {
    index,
    nextPosition,
  };
};

const getMostSuitableDivOrLineupToSelect = (selectedLineupOrDivision, allCompetitions, teamByIds) => {
  // Try to select the right meaningful and suitable lineup or division by default
  // Starting from selected lineupId if possible
  // Then by selected manager team
  // Then by first comp (+team if it exists)
  let mostSuitable = null;
  if (allCompetitions.length > 0) {
    const selectedLineup = selectedLineupOrDivision.team?.lineup?.id || "";
    const selectedLineupExists = selectedLineup && teamByIds.byLineupId.team[selectedLineup];
    const selectedManagerTeam = selectedLineupOrDivision.team?.managerTeam?.id || "";
    const selectedManagerTeamExists = selectedManagerTeam && teamByIds.byManagerTeam.team[selectedManagerTeam];
    const selectedDivisionAndTeamNumber =
      selectedLineupOrDivision.divisionId && selectedLineupOrDivision.team?.lineupTeamNumber
        ? `${selectedLineupOrDivision.divisionId}_${selectedLineupOrDivision.team.lineupTeamNumber}`
        : "";
    const selectedDivisionAndTeamNumberExists =
      selectedDivisionAndTeamNumber && teamByIds.notCreatedByDivIdAndTeamNumber.team[selectedDivisionAndTeamNumber];

    if (selectedLineupExists) {
      // If lineup provided does exist, select it
      const selectedComp = teamByIds.byLineupId.comp[selectedLineup];
      const selectedTeam = teamByIds.byLineupId.team[selectedLineup];
      mostSuitable = getItemFromCompAndOptionalTeam(selectedComp, selectedTeam);
    } else if (selectedManagerTeamExists) {
      // else if division provided does exist, select the first lineup of this division or if no lineup, select the division
      const selectedComp = teamByIds.byManagerTeam.comp[selectedManagerTeam];
      const selectedTeam = teamByIds.byManagerTeam.team[selectedManagerTeam];
      mostSuitable = getItemFromCompAndOptionalTeam(selectedComp, selectedTeam);
    } else if (selectedDivisionAndTeamNumberExists) {
      const selectedComp = teamByIds.notCreatedByDivIdAndTeamNumber.comp[selectedDivisionAndTeamNumber];
      const selectedTeam = teamByIds.notCreatedByDivIdAndTeamNumber.team[selectedDivisionAndTeamNumber];
      mostSuitable = getItemFromCompAndOptionalTeam(selectedComp, selectedTeam);
    } else if (allCompetitions.length > 0) {
      // Get the first competition
      // If it's multi entry, select the first team or select the single entry
      const firstComp = allCompetitions[0];
      if (firstComp.isMultiEntry) {
        const allTeams = [...firstComp.multiEntryTeams.used, ...firstComp.multiEntryTeams.remaining];
        if (allTeams.length > 0) {
          allTeams.sort((a, b) => {
            if (a.competitionId !== b.competitionId) {
              return a.competitionId < b.competitionId ? -1 : 1;
            }
            if (a.lineupTeamNumber !== null && b.lineupTeamNumber !== null) {
              return a.lineupTeamNumber < b.lineupTeamNumber ? -1 : 1;
            }
            return a.name < b.name ? -1 : 1;
          });
          mostSuitable = getItemFromCompAndOptionalTeam(firstComp, allTeams[0]);
        }
      } else {
        mostSuitable = getItemFromCompAndOptionalTeam(firstComp, firstComp.singleEntryTeam);
      }
      if (mostSuitable === null) {
        mostSuitable = getItemFromCompAndOptionalTeam(firstComp, null);
      }
    }
  }
  const hasChanged = !isEqual(mostSuitable, selectedLineupOrDivision);
  return { mostSuitable, hasChanged };
};

const removeLockedCard = (tokenId, lockedCards) => {
  const newLockedCards = { ...lockedCards };
  for (const position in newLockedCards) {
    if (newLockedCards[position] === tokenId) {
      delete newLockedCards[position];
      break;
    }
  }
  return newLockedCards;
};

const buildSuggestedLineup = (currentLineup, pickedPlayersIds, cards, cardsByPosition, pickScoresByPlayerId) => {
  const newLineup = [...currentLineup];

  // Directly chose the best goalkeeper not picked yet as it can't be set to extra position
  if (newLineup[0] === null && cardsByPosition["Goalkeeper"]) {
    for (let i = 0; i < cardsByPosition["Goalkeeper"].length; i++) {
      newLineup[0] = cards[cardsByPosition["Goalkeeper"]?.[i]];
      if (newLineup[0] === undefined || newLineup[0] === null || !pickedPlayersIds.includes(newLineup[0].card.PlayerId)) {
        break;
      }
    }
  }
  if (currentLineup[0] === null && newLineup[0]) pickedPlayersIds.push(newLineup[0]?.card.PlayerId);

  // Then, browse extra to distribute to the other positions, in order to avoid having the same player in multiple positions
  // And to avoid the same player twice in the lineup in the most optimised way, we have to choose the best position to put the player in.
  // The best are ordered in the extra position, so we can just browse it and pick the first player not already picked, instead of browsing all the positions choices
  for (let i = 0; i < cardsByPosition["Extra"].length; i++) {
    const extra = cards[cardsByPosition["Extra"]?.[i]];
    if (extra === undefined || extra === null || newLineup.every((c) => c !== null)) {
      break;
    }
    if (!pickedPlayersIds.includes(extra.card.PlayerId)) {
      // Player not already picked
      const cardPosition = extra.card.Position;
      const posIndex = so5_positions.indexOf(cardPosition);
      if (newLineup[posIndex]) {
        // Position is already used, we have to put it in extra if it's not already used as well
        if (newLineup[4] === null) {
          newLineup[4] = extra;
          pickedPlayersIds.push(extra.card.PlayerId);
        }
      } else {
        // Position is not used yet, we can put it there
        newLineup[posIndex] = extra;
        pickedPlayersIds.push(extra.card.PlayerId);
      }
    }
  }
  newLineup.forEach((c, i) => {
    if (c) {
      newLineup[i].pick_score_group = pickScoresByPlayerId?.[c.card.PlayerId]?.[so5_positions[i]];
    }
  });
  return newLineup;
};

const getProjectionsFromLineup = (lineup) => {
  return lineup.reduce((acc, cur) => {
    if (cur) {
      return { ...acc, [cur.card.PlayerId]: cur.projected_lineup };
    }
    return acc;
  }, {});
};

const getBestCaptainOnSuggestion = (selectedCompetitionInfo, lineup, lockedCardIds, currentCaptain) => {
  const isDivWithCaptain = selectedCompetitionInfo?.comp?.rules?.withCaptain;
  if (isDivWithCaptain) {
    const captainAvailableScarcities = selectedCompetitionInfo?.comp?.rules?.captainAllowedScarcities || [];
    const captainScores = lineup.map((c) => {
      const isAllowed = captainAvailableScarcities.length === 0 || captainAvailableScarcities.includes(c?.card?.Scarcity);
      if (isAllowed) {
        return c?.score_for_captain;
      }
      return -1;
    });
    const tokenIds = lineup.map((c) => c?.card?.TokenId);
    const maxIndex = captainScores.indexOf(Math.max(...captainScores));
    const possibleCaptain = lineup[maxIndex];
    if (
      possibleCaptain &&
      captainScores[maxIndex] > -1 &&
      (currentCaptain === null || !lockedCardIds.includes(currentCaptain.card.TokenId) || !tokenIds.includes(currentCaptain.card.TokenId))
    ) {
      return lineup[maxIndex];
    }
  }
  return currentCaptain;
};

const lineupHasSamePlayers = (lineup) => {
  const allPlayerIds = lineup.filter((c) => c !== null).map((c) => c.card.PlayerId);
  const uniquePlayerIds = new Set(lineup.filter((c) => c !== null).map((c) => c.card.PlayerId));
  return allPlayerIds.length !== uniquePlayerIds.size;
};

const buildLineupFromCombinationByDiscard = (cards, bestComb, pickScoresByPlayerId) => {
  return so5_positions.map((pos) => {
    const comb = bestComb[pos];
    if (comb === null || comb === undefined || comb === "") {
      return null;
    }
    const card = cards[comb];
    if (card) card.pick_score_group = pickScoresByPlayerId?.[card.card.PlayerId]?.[pos];
    return card;
  });
};

const emptySpotsFromLineup = (lineup) => {
  return lineup.map((c, i) => (c === null ? so5_positions[i] : "")).filter((c) => c !== "");
};

const hasNextByPositions = (cardIndexesInSuggestion, cardsByPosition, cards, pickedPlayers, lineup) => {
  return cardIndexesInSuggestion.map((d, i) => {
    if (d >= cardsByPosition[so5_positions[i]]?.length - 1) {
      return false; // Last item, no next
    } // At least one not picked player after
    const position = so5_positions[i];
    const cardInLineup = lineup[i];
    const hasNotPickedNext =
      cardsByPosition[position]?.slice(d + 1)?.some((c) => {
        const playerId = cards[c].card.PlayerId;
        return playerId && (!pickedPlayers[playerId] || (cardInLineup && cardInLineup.card.PlayerId === playerId)); // if player is in lineup at the same position exactly, it can be picked again
      }) || false;
    return hasNotPickedNext;
  });
};

const hasPrevByPositions = (cardIndexesInSuggestion, cardsByPosition, cards, pickedPlayers, lineup) => {
  return cardIndexesInSuggestion.map((d, i) => {
    if (d <= 0) {
      return false; // First item, no previous
    } // At least one not picked player before
    const position = so5_positions[i];
    const cardInLineup = lineup[i];
    const hasNotPickedPrev =
      cardsByPosition[position]?.slice(0, d)?.some((c) => {
        const playerId = cards[c].card.PlayerId;
        return playerId && (!pickedPlayers[playerId] || (cardInLineup && cardInLineup.card.PlayerId === playerId)); // if player is in lineup at the same position exactly, it can be picked again
      }) || false;
    return hasNotPickedPrev;
  });
};

const buildSuggestionBrowseInfo = (lineup, cardsByPosition, cards) => {
  const pickedPlayers = {};
  lineup.forEach((c) => {
    if (c) pickedPlayers[c.card.PlayerId] = true;
  });
  let gkIndex = cardsByPosition["Goalkeeper"]?.indexOf(lineup[0]?.card?.TokenId) || 0;
  if (gkIndex === -1) gkIndex = 0;
  let defIndex = cardsByPosition["Defender"]?.indexOf(lineup[1]?.card?.TokenId) || 0;
  if (defIndex === -1) defIndex = 0;
  let midIndex = cardsByPosition["Midfielder"]?.indexOf(lineup[2]?.card?.TokenId) || 0;
  if (midIndex === -1) midIndex = 0;
  let fwdIndex = cardsByPosition["Forward"]?.indexOf(lineup[3]?.card?.TokenId) || 0;
  if (fwdIndex === -1) fwdIndex = 0;
  let extraIndex = cardsByPosition["Extra"]?.indexOf(lineup[4]?.card?.TokenId) || 0;
  if (extraIndex === -1) extraIndex = 0;
  const newIndexes = [gkIndex, defIndex, midIndex, fwdIndex, extraIndex];
  const hasNextByPos = hasNextByPositions(newIndexes, cardsByPosition, cards, pickedPlayers, lineup);
  const hasPrevByPos = hasPrevByPositions(newIndexes, cardsByPosition, cards, pickedPlayers, lineup);
  return {
    cardsByPosition: cardsByPosition,
    indexes: newIndexes,
    hasNext: hasNextByPos,
    hasPrev: hasPrevByPos,
  };
};

const allLineupNotSuggesting = new Array(5).fill(false);
const allLineupSuggesting = new Array(5).fill(true);
const checkWhichLineupPositionShouldBeSuggesting = (previousLineup, nextLineup) => {
  if (previousLineup === null || previousLineup === undefined || previousLineup.length === 0) {
    return allLineupNotSuggesting;
  }
  return previousLineup.map((c, i) => c.card.TokenId === null || c.card.TokenId !== nextLineup[i]?.card?.TokenId);
};

const pickedPlayersFromLineup = (lineup) => {
  const pickedPlayers = {};
  lineup.forEach((p) => {
    if (p) pickedPlayers[p.card.PlayerId] = true;
  });
  return pickedPlayers;
};

const isCombinationEligible = (cards, combination, lockedCards, discardedCards) => {
  for (const position in combination.TokenIds) {
    if (position === "") {
      return false;
    }
    if (lockedCards[position]) {
      if (lockedCards[position] !== combination.TokenIds[position]) {
        return false;
      }
    }
    const discard =
      cards[combination.TokenIds[position]].card.PlayerId +
      "_" +
      cards[combination.TokenIds[position]].card.Scarcity +
      "_" +
      cards[combination.TokenIds[position]].card.Position;
    if (Object.keys(discardedCards).includes(discard)) {
      //Already discarded
      return false;
    }
  }
  return true;
};

export const hasLineupChangesToSave = (captain, current, saved) => {
  let lineupHasChangesToSave = false;
  if (current) {
    if (saved) {
      const currentCaptain = captain?.card?.TokenId || "";
      const savedCaptain = saved.find((c) => c?.captain)?.card?.TokenId || "";
      lineupHasChangesToSave =
        current.some((card, index) => {
          if (card === null && saved[index] === null) return false;
          if (card === null || saved[index] === null) return true;
          return card.card.TokenId !== saved[index].card.TokenId;
        }) || savedCaptain !== currentCaptain;
    } else {
      lineupHasChangesToSave = !current.every((card) => card === null);
    }
  }
  return lineupHasChangesToSave;
};

const getTeamsByLineupId = (comps) => {
  const res = {
    byLineupId: {
      comp: {},
      team: {},
    },
    byManagerTeam: {
      comp: {},
      team: {},
    },
    notCreatedByDivIdAndTeamNumber: {
      comp: {},
      team: {},
    },
  };
  comps.forEach((comp) => {
    // create a const with all fields in comp variable except singleEntryTeam and multiEntryTeams
    const { singleEntryTeam, multiEntryTeams, ...compWithoutTeams } = comp;
    if (singleEntryTeam && singleEntryTeam.lineup) {
      res.byLineupId.team[singleEntryTeam.lineup.id] = singleEntryTeam;
      res.byLineupId.comp[singleEntryTeam.lineup.id] = compWithoutTeams;
    } else if (multiEntryTeams) {
      multiEntryTeams.used?.forEach((team) => {
        if (team.lineup) {
          res.byLineupId.team[team.lineup.id] = team;
          res.byLineupId.comp[team.lineup.id] = compWithoutTeams;
        }
        if (comp.isPromotionRelegation) {
          multiEntryTeams.used?.forEach((team) => {
            if (team.managerTeam) {
              if (team.managerTeam.id) {
                res.byManagerTeam.team[team.managerTeam.id] = team;
                res.byManagerTeam.comp[team.managerTeam.id] = compWithoutTeams;
              } else {
                res.notCreatedByDivIdAndTeamNumber.team[team.competitionId + "_" + team.teamNumber] = team;
                res.notCreatedByDivIdAndTeamNumber.comp[team.competitionId + "_" + team.teamNumber] = compWithoutTeams;
              }
            }
          });
        }
      });
      if (comp.isPromotionRelegation) {
        multiEntryTeams.remaining?.forEach((team) => {
          if (team.managerTeam && team.managerTeam.id) {
            if (team.managerTeam.id) {
              res.byManagerTeam.team[team.managerTeam.id] = team;
              res.byManagerTeam.comp[team.managerTeam.id] = compWithoutTeams;
            } else {
              res.notCreatedByDivIdAndTeamNumber.team[team.competitionId + "_" + team.teamNumber] = team;
              res.notCreatedByDivIdAndTeamNumber.comp[team.competitionId + "_" + team.teamNumber] = compWithoutTeams;
            }
          }
        });
      }
    }
  });
  return res;
};

const getLineupsByIdFromTeamByIds = (teamsByLineupIds) => {
  const res = {};
  if (teamsByLineupIds) {
    Object.entries(teamsByLineupIds).forEach(([lineupId, team]) => {
      res[lineupId] = team.lineup;
    });
  }
  return res;
};

const getCompetitionsByDivId = (comps) => {
  const res = {};
  comps.forEach((comp) => {
    res[comp.id] = comp;
    if (comp.isMultiEntry) {
      comp.multiEntryTeams.used?.forEach((team) => {
        res[team.competitionId] = comp;
      });
      comp.multiEntryTeams.remaining?.forEach((team) => {
        res[team.competitionId] = comp;
      });
    }
  });
  return res;
};
