import React, { Component } from "react";
import { Anchor, Box, Text, TextInput } from "grommet";
import ls from "local-storage";
import { withUser } from "../../userContext";
import { getManagerPlayers } from "../util/managerPlayers";
import GreenButton from "../util/greenButton";
import { errorCatcher } from "../util/errors";
import LoginBanner from "../../img/login-banner.png";
import HomeAnnouncementBanner from "../../img/home-announcement-banner.png";
import SDLogoWhite from "../../img/logo-landing.svg";
import { affilatedSorareLink } from "../util/url";

const queryString = require("query-string");

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = { username: "", pwd: "", errorMsg: "", sorareLink: "" };
  }

  async componentDidMount() {
    const parsed = queryString.parse(this.props.location.search);
    if (await this.props.isConnected()) {
      let dest = "";
      if (parsed.to) {
        dest = parsed.to;
      }
      this.props.history.push("/" + dest);
    } else {
      // prevents unwanted redirection to the app if the app authentication is abandoned
      ls.set("loginReturnLink", "");
      this.getLoginURL();
    }
  }

  getLoginURL = () => {
    this.props
      .fetch("/apiv2/user/sorare-login-url")
      .then((response) => response.json())
      .then((res) => {
        this.setState({ sorareLink: res });
      })
      .catch(errorCatcher());
  };

  handleChangeUser = (event) => {
    this.setState({ username: event.target.value });
  };

  handleChangePwd = (event) => {
    this.setState({ pwd: event.target.value });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const parsed = queryString.parse(this.props.location.search);
    let dest = "";
    if (parsed.to) {
      dest = parsed.to;
    }
    this.props
      .fetch("/apiv2/auth/login", {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: this.state.username,
          password: this.state.pwd,
        }),
      })
      .then((response) => response.json())
      .then(async (res) => {
        if (res.error !== undefined) {
          this.setState({ errorMsg: res.error });
        } else {
          let user = {
            username: res.user.Username,
            sorareSlug: res.sorareUser.Slug,
            sorarePictureUrl: res.sorareUser.PictureUrl,
            players: [],
            preferredUnit: res.user.Currency,
            preferredScarcity: res.user.MobileScarcity || "LIMITED",
            preferredLayout: res.user.CardBackground,
            secondUnit: res.user.MobileFiat,
            tier: res.plan,
            email: res.user.Email,
            termsAccepted: res.user.TermsAccepted,
            verified: res.user.Verified,
            sorareAverages: res.user.SorareAverages,
            hideCommonCards: res.user.HideCommonCards,
            hideIneligibleCards: res.user.HideIneligibleCards,
          };
          if (user.sorareSlug !== undefined && user.sorareSlug !== null && user.sorareSlug !== "") {
            user.players = await getManagerPlayers(this.props, user.sorareSlug);
          }
          this.props.setUser(user);
          if (res.user.TermsAccepted && res.user.Email !== undefined && res.user.Email !== null && res.user.Email !== "") {
            this.props.history.push("/" + dest);
          } else {
            this.props.history.push("/validateRegistration");
          }
        }
      })
      .catch(errorCatcher());
  };

  render() {
    let errorMsg = this.state.errorMsg;
    return (
      <div className={"flex flex-col md:flex-row md:space-x-8 space-y-8 md:space-y-0 min-h-screen"}>
        <div className={"md:w-6/12"}>
          <div className={"space-y-8 text-left w-10/12 mx-auto mt-8 md:mt-16 self-center"}>
            <div className={"text-left mx-auto text-headers font-bold"}>
              <h3 className={"text-3xl font-bold font-headers"}>To enjoy the whole SorareData experience, please login.</h3>
            </div>

            <div className={"md:w-auto font-medium"}>
              <a href={this.state.sorareLink}>
                <GreenButton margin={"auto"} color={"#1D77FF"} primary label="Login with your Sorare account" />
              </a>
            </div>

            <div className={"mx-auto"}>
              <h4 className={"font-normal"}>Log in with your SorareData account.</h4>
            </div>
            <div className={"w-10/12"} style={{ maxWidth: "30em" }}>
              <form onSubmit={this.handleSubmit}>
                <TextInput size="medium" type="text" name="username" placeholder="Username" onChange={this.handleChangeUser} />
                <br />
                <TextInput type="password" name="password" placeholder="Password" onChange={this.handleChangePwd} />
                <br />
                <div className={"flex flex-row gap-4"}>
                  <GreenButton margin={"auto"} type="submit" primary label="Sign in" onSubmit={this.handleSubmit} />
                  <p className={"my-auto text-brand-text cursor-pointer font-semibold"}>
                    <a href={"/ask-reset-password"}>Forgot your password?</a>
                  </p>
                </div>
              </form>
            </div>
            <div className={"flex flex-col space-y-4"}>
              <Text>
                No Sorare account?{" "}
                <Anchor href={affilatedSorareLink} target={"_blank"}>
                  Create a Sorare account.
                </Anchor>
              </Text>
            </div>
            <Box margin={{ top: "small" }}>
              <Text>{errorMsg}</Text>
            </Box>
          </div>
          <div
            className="w-10/12 mx-auto mt-8 p-4 rounded-lg space-y-4 shadow-lg sm:p-3"
            style={{
              backgroundImage: "url('" + HomeAnnouncementBanner + "')",
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          >
            <div className="flex flex-row space-x-4 py-1 mx-auto">
              <span className="flex p-2 rounded-lg">
                <img className="h-6 w-6 text-white" src={SDLogoWhite} />
              </span>
              <div className={"pr-4 my-auto"}>
                <p className={"text-center text-white font-medium font-headers"}>
                  Get even more out of SorareData by subscribing to our{" "}
                  <b>
                    <a target={"_blank"} href={"https://www.youtube.com/channel/UC5uxFJ8HpynQrl3dyp32Y8Q"} rel="noreferrer">
                      YouTube
                    </a>
                  </b>{" "}
                  and{" "}
                  <b>
                    <a target={"_blank"} href={"https://open.spotify.com/show/6XWLpPDTlYK2ycGt87b1dK"} rel="noreferrer">
                      Spotify
                    </a>
                  </b>{" "}
                  channels.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          className={"h-screen md:h-auto md:w-6/12"}
          style={{
            backgroundImage: "url('" + LoginBanner + "')",
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        ></div>
      </div>
    );
  }
}

export default withUser(Login);
