// store/UserProvider.js
import React, { createContext, Component } from "react";
import ls from "local-storage";
import { getCookie } from "./components/util/cookies";
import { getManagerPlayers } from "./components/util/managerPlayers";
import { Mutex } from "async-mutex";
import { getNavigatorLang } from "./components/util/locale";
import { featureFlippingHeaders } from "./featureFlipping";

export class AuthenticationError extends Error {
  constructor(message) {
    super(message); // (1)
    this.name = "AuthenticationError"; // (2)
  }
}

/**
 * `createContext` contient 2 propriétés :
 * `Provider` et `Consumer`. Nous les rendons accessibles
 * via la constante `UserContext`, et on initialise une
 * propriété par défaut : "name" qui sera une chaine vide.
 * On exporte ce contexte afin qu'il soit exploitable par
 * d'autres composants par la suite via le `Consumer`
 */
export const UserContext = createContext({
  user: { preferredUnit: "eth" },
  setUser: () => {},
  logout: () => {},
  checkUser: () => {},
});

/**
 * la classe UserProvider fera office de... Provider (!)
 * en wrappant son enfant direct
 * dans le composant éponyme. De cette façon, ses values
 * seront accessible de manière globale via le `Consumer`
 */
class UserProvider extends Component {
  mutex = new Mutex();
  state = {
    user: { preferredUnit: "eth", featureFlipping: {} },
    setUser: (user) => {
      //console.log("SETTING USER");
      ls.remove("accessToken"); // deprecated, now using cookies. Keeping it for now to clean existing storages
      ls.remove("players");
      ls.set("username", user.username);
      ls.set("linkedAccount", user.sorareSlug);
      ls.set("sorareId", user.sorareId);
      ls.set("profilePicture", user.sorarePictureUrl);
      ls.set("players", user.players);
      ls.set("currency", user.preferredUnit);
      ls.set("scarcity", user.preferredScarcity);
      ls.set("layout", user.preferredLayout || "white");
      ls.set("tier", user.tier);
      ls.set("secondUnit", user.secondUnit);
      ls.set("sport", user.preferredSport || "all");
      ls.set("termsAccepted", user.termsAccepted || false);
      ls.set("email", user.email || false);
      ls.set("verified", user.verified || false);
      ls.set("sorareAverages", user.sorareAverages || false);
      ls.set("hideValuations", user.hideValuations || false);
      ls.set("hideCommonCards", user.hideCommonCards || false);
      ls.set("hideIneligibleCards", user.hideIneligibleCards || false);
      this.setState({ user: user });
    },
    fetch: (input, init) => {
      init = init || {};
      return fetch(input, init).then((response) => {
        const tierHeader = response.headers.get("X-Sd-Tier");
        if (tierHeader !== undefined && tierHeader !== null && tierHeader !== "" && this.state.user.tier !== tierHeader) {
          ls.set("tier", tierHeader);
          this.state.setUser({ ...this.state.user, tier: tierHeader });
        }
        Object.entries(featureFlippingHeaders).forEach(([env, name]) => {
          const enabledFeaturesHeader = response.headers.get(env);
          if (
            enabledFeaturesHeader !== undefined &&
            enabledFeaturesHeader !== null &&
            enabledFeaturesHeader !== "" &&
            this.state.user?.featureFlipping?.[name] !== enabledFeaturesHeader
          ) {
            this.state.setUser({
              ...this.state.user,
              featureFlipping: {
                ...this.state.user.featureFlipping,
                [name]: enabledFeaturesHeader,
              },
            });
          }
        });
        if (response.status === 401) {
          // The access token is not valid, trying to recall fetch with empty token
          // So that it uses refresh token
          return this.mutex.acquire().then((release) => {
            return fetch("/apiv2/auth/refreshTokens", {
              method: "POST",
              headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
              },
            })
              .then((response) => {
                if (response.status !== 201) {
                  // the refresh token is not valid either, we force logout
                  this.state.logoutLocal();
                  release();

                  return Promise.reject(response);
                }
                return response.json();
              })
              .then((res) => {
                let tier = res.plan;
                ls.set("tier", tier);
                this.state.setUser({ ...this.state.user, tier: tier });
                // The refresh worked, we should have new fresh cookies to fetch
                return fetch(input, init).then((response) => {
                  release();
                  return response;
                });
              });
          });
        }
        // Access cookie happens to be fresh, we can return as is
        return response;
      });
    },
    changeCurrency: (currency) => {
      ls.set("currency", currency);
      this.state.setUser({ ...this.state.user, preferredUnit: currency });
    },
    changeSport: (sport) => {
      ls.set("sport", sport);
      this.state.setUser({ ...this.state.user, preferredSport: sport });
    },
    changeScarcity: (scarcity) => {
      ls.set("scarcity", scarcity);
      this.state.setUser({ ...this.state.user, preferredScarcity: scarcity.toUpperCase() });
    },
    changeSecondUnit: (currency) => {
      ls.set("secondUnit", currency);
      this.state.setUser({ ...this.state.user, secondUnit: currency });
    },
    changeLayout: (layout) => {
      ls.set("layout", layout);
      this.state.setUser({ ...this.state.user, preferredLayout: layout });
    },
    changeSorareAverages: (sAvg) => {
      ls.set("sorareAverages", sAvg);
      this.state.setUser({ ...this.state.user, sorareAverages: sAvg });
    },
    changeHideValuations: (hideValuations) => {
      ls.set("hideValuations", hideValuations);
      this.state.setUser({ ...this.state.user, hideValuations: hideValuations });
    },
    changeHideCommonCards: (hideCommonCards) => {
      ls.set("hideCommonCards", hideCommonCards);
      this.state.setUser({ ...this.state.user, hideCommonCards: hideCommonCards });
    },
    changeHideIneligibleCards: (hideIneligibleCards) => {
      ls.set("hideIneligibleCards", hideIneligibleCards);
      this.state.setUser({ ...this.state.user, hideIneligibleCards: hideIneligibleCards });
    },
    logoutLocal: () => {
      ls.remove("username");
      ls.remove("linkedAccount");
      ls.remove("players");
      ls.remove("currency");
      ls.remove("scarcity");
      ls.remove("secondUnit");
      ls.remove("hideValuations");
      ls.remove("showAnnouncement");
      ls.remove("scarcities");
      ls.remove("layout");
      ls.remove("profilePicture");
      ls.remove("tier");
      ls.remove("sport");
      ls.remove("email");
      ls.remove("termsAccepted");
      ls.remove("verified");
      ls.remove("sorareAverages");
      ls.remove("hideCommonCards");
      ls.remove("hideIneligibleCards");
      this.setState({
        user: {
          preferredUnit: "eth",
          preferredScarcity: "LIMITED",
          preferredLayout: "white",
          preferredSport: "all",
          sorareAverages: false,
          hideValuations: false,
          hideCommonCards: false,
          hideIneligibleCards: false,
        },
      });
    },
    internalLogout: (path) => {
      return fetch(path, { method: "POST" }).then((response) => {
        if (response.status === 401) {
          // either it's already disconnected (= refresh token are not valid anymore)
          // either it's just the access token has expired (= refresh token is still valid)
          // So we have to check either way, to ensure the user is really disconnected on the backend
          return this.mutex.acquire().then((release) => {
            return fetch("/apiv2/auth/refreshTokens", {
              method: "POST",
              headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
              },
            })
              .then((response) => {
                if (response.status !== 201) {
                  this.state.logoutLocal();
                  release();
                  return Promise.reject(response);
                }
                return response.json();
              })
              .then((res) => {
                return fetch(path, { method: "POST" })
                  .then((response) => {
                    this.state.logoutLocal();
                    release();
                    return response;
                  })
                  .catch((err) => {
                    this.state.logoutLocal();
                    release();
                    return Promise.reject(err);
                  });
              });
          });
        }
        // Usual case: log out is accepted by the server
        this.state.logoutLocal();
        return response;
      });
    },
    logout: async () => {
      return this.state.internalLogout("/apiv2/auth/logout");
    },
    logoutAllDevices: () => {
      return this.state.internalLogout("/apiv2/auth/logoutAllDevices");
    },
    isConnected: () => {
      return (
        ls.get("username") !== undefined &&
        ls.get("username") !== null &&
        ls.get("username") !== "" &&
        ls.get("username") !== "undefined" /* to clean up bad local storage */
      );
    },
    isConnectedWithSorare: () => {
      return ls.get("linkedAccount") !== undefined && ls.get("linkedAccount") !== null && ls.get("linkedAccount") !== "";
    },
    isRegistrationValidated: () => {
      return ls.get("email") !== undefined && ls.get("email") !== null && ls.get("email") !== "" && ls.get("termsAccepted") === "true";
    },
    getLang: () => {
      // Prepare the way to i18n
      return getNavigatorLang();
    },
    forceProfileReload: () => {
      this.state
        .fetch("/apiv2/auth/profile")
        .then((response) => {
          if (response.status === 401) {
            this.state.logoutLocal();
            return Promise.reject(response);
          }
          if (response.status !== 200) {
            return Promise.reject(response);
          }
          return response.json();
        })
        .then(async (res) => {
          var players = [];
          if ((players === undefined || players === null) && res.sorareSlug !== "") {
            players = await getManagerPlayers({ fetch: this.fetch }, res.sorareSlug);
          }
          this.state.setUser({
            ...this.state.user,
            username: res.user.Username,
            secondUnit: res.user.MobileFiat,
            sorareSlug: res.sorareUser.Slug,
            sorareId: res.sorareUser.SorareId,
            sorarePictureUrl: res.sorareUser.PictureUrl,
            players: players,
            preferredUnit: res.user.Currency,
            preferredScarcity: (res.user.MobileScarcity || "LIMITED").toUpperCase(),
            preferredLayout: res.user.CardBackground,
            tier: res.rights.Plan,
            preferredSport: res.user.PreferredSport,
            email: res.user.Email,
            termsAccepted: res.user.TermsAccepted,
            verified: res.user.Verified,
            sorareAverages: res.user.SorareAverages,
            hideValuations: res.user.HideValuations,
          });
        });
    },
    getProfile: async () => {
      if (this.state.user.username === undefined) {
        const hasUserNameLocally =
          ls.get("username") !== undefined &&
          ls.get("username") !== null &&
          ls.get("username") !== "" &&
          ls.get("username") !== "undefined"; /* to clean up bad local storage */
        if (hasUserNameLocally) {
          // Just reload user state from local storage
          let sorareSlug = ls.get("linkedAccount");
          let sorareId = ls.get("sorareId");
          let sorarePictureUrl = ls.get("profilePicture");
          let players = ls.get("players") || [];
          let currency = ls.get("currency") || "eth";
          let secondUnit = ls.get("secondUnit") || "";
          let scarcity = ls.get("scarcity") || "LIMITED";
          let scarcities = ls.get("scarcities") || ["LIMITED", "RARE"];
          let sport = ls.get("sport") || "all";
          scarcities = scarcities.filter(function (v) {
            return v !== "";
          });
          let layout = ls.get("layout") || "white";
          let tier = ls.get("tier") || "free";
          if ((players === undefined || players === null) && sorareSlug !== "") {
            players = await getManagerPlayers({ fetch: this.fetch }, sorareSlug);
          }
          let sorareAverages = ls.get("sorareAverages") || false;
          this.state.setUser({
            ...this.state.user,
            username: ls.get("username"),
            sorareSlug: sorareSlug,
            sorareId: sorareId,
            sorarePictureUrl: sorarePictureUrl,
            players: players,
            preferredUnit: currency,
            preferredScarcity: scarcity,
            preferredLayout: layout,
            tier: tier,
            preferredSport: sport,
            secondUnit: secondUnit,
            email: ls.get("email"),
            termsAccepted: ls.get("termsAccepted"),
            verified: ls.get("verified"),
            sorareAverages: sorareAverages,
            hideValuations: ls.get("hideValuations") || false,
            hideCommonCards: ls.get("hideCommonCards") || false,
            hideIneligibleCards: ls.get("hideIneligibleCards") || false,
          });
        } else {
          // Don't have anything in local storage or state
          const accessCookie = getCookie("sd-acccess-token");
          if (accessCookie) {
            // But cookies say we should be connected, get info from server
            this.state.forceProfileReload();
          }
        }
      }
    },
  };

  componentDidMount() {
    this.state.getProfile();
  }

  render() {
    return (
      /**
       * la propriété value est très importante ici, elle rend ici
       * le contenu du state disponible aux `Consumers` de l'application
       */
      <UserContext.Provider value={this.state}>{this.props.children}</UserContext.Provider>
    );
  }
}

/**
 * La fonction `withUser` sera notre HOC
 * qui se chargera d'injecter les propriétés de notre contexte
 * à n'importe quel composant qui l'appellera
 */
// eslint-disable-next-line react/display-name
export const withUser = (Component) => (props) => (
  <UserContext.Consumer>{(user) => <Component {...props} {...user} />}</UserContext.Consumer>
);

export default UserProvider;
