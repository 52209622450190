import React, { useEffect, useState } from "react";
import { formatPrice } from "../util/formatMoney";
import { getThemeColor, useTheme } from "../../themeContext";
import { Area, Bar, CartesianGrid, Cell, ComposedChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { ReactComponent as IconCertified } from "../../img/icons-certified.svg";
import { ReactComponent as InfoIcon } from "@material-design-icons/svg/round/info.svg";
import { ReactComponent as KeyBoardDoubleArrowUp } from "@material-design-icons/svg/round/keyboard_double_arrow_up.svg";
import { ReactComponent as ShoppingCartIcon } from "@material-design-icons/svg/round/shopping_cart.svg";
import { ReactComponent as LongArrow } from "../../img/long-arrow.svg";
import { errorCatcher } from "../util/errors";
import { withUser } from "../../userContext";
import AbortController from "abort-controller";
import { getLinkToSorareCard } from "../util/cardLink";
import { formatNumber } from "../util/formatNumber";
import IsNaN from "lodash-es/isNaN";
import { sdDark } from "../../themeColors";
import Slider from "rc-slider";
import { abbrv_football_positions, so5_positions } from "../util/positions";
import { getScarcityInfo } from "../util/scarcities";
import { Button } from "../util/button";
import { LineupStrength } from "./lineupStrength";
import PlayerAvailability from "../players/playerAvailability";
import UnknownClub from "../../img/unknown_club.png";
import { capitalize } from "../util/capitalize";
import ScoreAverage from "./scoreAverage";
import { getValuationOriginShortOnCard } from "../util/valuationOrigin";
import { PlayerCommunityProjectionWithHover } from "../util/communityPrediction";
import { sorareFootball } from "../util/sports";
import { PickScoreDetails } from "../util/pickScore";
import { GameInLineVs } from "./gameInLine";
import { BetaBanner } from "../util/betaBanner";
import { playing_status_labels } from "../util/playingStatus";

export const CardRecommendation = withUser((props) => {
  const { shouldRecoCard, divisionId, lineupCards, captainPosition, cap240Indicators, darkMode, divLogo, gwNumber } = props;
  const [cardRecommendations, setCardRecommendations] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(cardRecommendations?.bestChoiceIndex || 0);
  const [loading, setLoading] = useState(false);
  const [abortController, setAbortController] = useState(new AbortController());

  useEffect(() => {
    setSelectedIndex(cardRecommendations?.bestChoiceIndex || 0);
  }, [cardRecommendations]);

  const cards = lineupCards.map((c) => (c ? c.card.TokenId : null));

  useEffect(() => {
    if (divisionId && lineupCards) {
      setLoading(true);
      abortController.abort();
      const newAbortController = new AbortController();
      setAbortController(newAbortController);
      props
        .fetch(`/decision-api/decision/lub/cardRecommendations`, {
          method: "POST",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            divisionId: divisionId,
            lineup: cards,
            captainPosition: captainPosition,
          }),
          signal: newAbortController.signal,
        })
        .then((response) => response.json())
        .then((res) => {
          if (res?.error) {
            setCardRecommendations(null);
            console.error(res);
          } else {
            setCardRecommendations(res);
          }
          setLoading(false);
        })
        .catch(
          errorCatcher(() => {
            setLoading(false);
          }),
        );
    }
  }, []);

  let recommendationContent = <></>;
  let lineupDistributionContent;
  if (loading) {
    recommendationContent = <div className={"h-56 w-full rounded-2xl animate-pulse-sm bg-skeleton-from bg-opacity-10"}></div>;
    lineupDistributionContent = <div className={"h-64 w-full rounded-2xl animate-pulse-sm bg-skeleton-from bg-opacity-10"}></div>;
  } else if (cardRecommendations === null || cardRecommendations === undefined) {
    // console.log("Nothing to display");
    lineupDistributionContent = <div>{cap240Indicators && <Cap240IndicatorHover cap240Indicators={cap240Indicators} />}</div>;
  } else {
    const error = !shouldRecoCard
      ? "Lineup needs at least 4 cards"
      : cardRecommendations.noRecommendationReason !== undefined &&
        cardRecommendations.noRecommendationReason !== null &&
        cardRecommendations.noRecommendationReason !== ""
      ? cardRecommendations.noRecommendationReasonMessage
      : null;
    if (error) {
      recommendationContent = (
        <div className={"h-56 w-full relative flex justify-center items-center"}>
          <div className={"absolute h-full w-full flex justify-center items-center text-on-surface-variant font-medium"}>
            <div>
              <p className={"text-sm"}>No improvement recommendation available</p>
              <p className={"text-xs"}>({error})</p>
            </div>
          </div>
          <KeyBoardDoubleArrowUp className={"w-14 h-14 fill-transparent-inverse-surface-low opacity-10"} />
        </div>
      );
    } else {
      const displayedRecommendation = cardRecommendations.recommendations?.[selectedIndex];
      recommendationContent = (
        <CardRecommendationContent
          cardRecommendations={cardRecommendations}
          darkMode={darkMode}
          lineup={lineupCards}
          selectedIndex={selectedIndex}
          currentLineupStrength={cardRecommendations.currentLineupStrength}
          displayedRecommendation={displayedRecommendation}
          onChangeLevel={(index) => setSelectedIndex(index)}
          divLogo={divLogo}
          gwNumber={gwNumber}
        />
      );
    }
    const recommendedCardLineupStrength = cardRecommendations.recommendations?.[selectedIndex]?.cardRecommendation?.lineupStrength;
    lineupDistributionContent = (
      <div>
        {cap240Indicators && <Cap240IndicatorHover cap240Indicators={cap240Indicators} />}
        <LineupStrengthDistribution
          distribution={cardRecommendations.lineupStrengthDistribution}
          currentLineupStrength={cardRecommendations.currentLineupStrength}
          isStrengthWithCaptain={cardRecommendations.isStrengthWithCaptain}
          lineupStrengthWithRecommended={recommendedCardLineupStrength}
          darkMode={darkMode}
        />
      </div>
    );
  }

  return (
    <div className={"p-2 text-on-surface"}>
      <div className={"flex flex-col gap-5"}>
        <div>{recommendationContent}</div>
        <div className={"border-t border-outline-variant"}></div>
        <div className={"flex flex-col gap-3"}>
          <div className="bg-surface-container-low rounded-xl p-3 font-medium text-sm">
            <div className="flex flex-row gap-3 items-center">
              <InfoIcon className="w-14 h-14 fill-on-surface-variant" />
              <span className="text-left text-on-surface-variant">
                The best possible theoretical lineup has a score of 100. Each player contributes to the lineup score, using its pick score
                and power
              </span>
            </div>
          </div>
          {lineupDistributionContent}
        </div>
      </div>
    </div>
  );
});

const CardRecommendationContent = (props) => {
  const {
    cardRecommendations,
    darkMode,
    lineup,
    currentLineupStrength,
    selectedIndex,
    displayedRecommendation,
    onChangeLevel,
    divLogo,
    gwNumber,
  } = props;

  const allLevels = cardRecommendations.recommendations.map((r) => r.priceSensibilityLevel);
  const firstLevel = 0;
  const lastLevel = allLevels.length - 1;
  const replacedPosition = displayedRecommendation?.replacedPosition;
  const replacePositionIndex = so5_positions.indexOf(replacedPosition);
  const replacedCard = lineup[replacePositionIndex];
  const recommendedCard = displayedRecommendation?.cardRecommendation;
  const replacedCardInfo = replacedCard ? getScarcityInfo(replacedCard.card.Scarcity, replacedCard?.player.Sport) : {};
  return (
    <div className={"flex flex-col gap-4 text-left "}>
      <BetaBanner featureKey={"card-recommendation"} />
      {cardRecommendations.maximalLineupStrengthGain > 0 && (
        <div
          className={
            "bg-quality-scale-excellent-quality-container-dim-fixed rounded-lg p-3 flex flex-row gap-3 text-quality-scale-excellent-on-dim-quality-container-fixed text-sm font-medium items-center"
          }
        >
          <div>
            <KeyBoardDoubleArrowUp className={"w-10 h-10 fill-quality-scale-excellent-on-dim-quality-container-fixed"} />
          </div>
          <div>
            <p>Add up to {cardRecommendations.maximalLineupStrengthGain} points to your lineup strength by replacing a single card!</p>
          </div>
        </div>
      )}
      <div className={"flex flex-col gap-3 font-medium"}>
        <h3 className={"font-headers text-md font-semibold m-0 mb-1"}>Proposed upgrade</h3>
        <div className={"flex flex-col gap-2"}>
          <p className={"text-sm"}>Adjust priority toward price or performances.</p>
          <div className={"flex flex-row gap-3 font-medium text-sm items-center justify-between"}>
            <p className={"cursor-pointer"} onClick={() => onChangeLevel(firstLevel)} title={"Best quality card with a fair price"}>
              Price
            </p>
            <CardRecommendationLevelRange
              darkMode={darkMode}
              selected={selectedIndex}
              allLevels={allLevels}
              onChange={(index) => onChangeLevel(index)}
            />
            <p
              className={"cursor-pointer"}
              onClick={() => onChangeLevel(lastLevel)}
              title={"Performance, the best card possible whatever the price, if it's not in previous step already"}
            >
              Perf.
            </p>
          </div>
        </div>
        <div className={"flex flex-col gap-2"}>
          {replacedCard ? (
            <p className={"text-sm font-semibold"}>
              Replace{" "}
              <span className={replacedCardInfo.color}>
                {replacedCard?.player.DisplayName} {replacedCard?.card?.SerialNumber}/{replacedCardInfo.supply}
              </span>{" "}
              ({abbrv_football_positions[replacedPosition]}):
            </p>
          ) : (
            <p className={"text-sm font-semibold"}>Fill missing position ({abbrv_football_positions[replacedPosition]}):</p>
          )}
          <RecommendedCard card={recommendedCard} currentLineupStrength={currentLineupStrength} darkMode={darkMode} gwNumber={gwNumber} />
        </div>
      </div>
      <div className="bg-surface-container-low rounded-xl p-3 font-medium text-sm">
        <div className="flex flex-row gap-3 items-center">
          <img src={divLogo} className="w-8 h-8 fill-on-surface-variant" />
          {currentLineupStrength.top !== recommendedCard.lineupStrength.top ? (
            <span className="text-left text-on-surface-variant">
              Boost your lineup strength from the top <b>{currentLineupStrength.top}%</b> to the top{" "}
              <b>{recommendedCard.lineupStrength.top}%</b> with this adjustment, backed by historical data.
            </span>
          ) : (
            <span className="text-left text-on-surface-variant">
              Your current lineup is already in the top <b>{currentLineupStrength.top}%</b>, and adding this card won’t significantly
              enhance your team’s performance relative to others, based on historical data.
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

const RecommendedCard = withUser((props) => {
  const { card, currentLineupStrength, darkMode, gwNumber } = props;
  if (!card) {
    return null;
  }
  const linkToCard = getLinkToSorareCard(card.player.sport, card.card.slug, "cardRecommendation");
  const cardColorInfo = getScarcityInfo(card.card.scarcity, card.player.sport);
  const price = formatPrice(card.price[props.user.preferredUnit], props.user.preferredUnit);
  const floor = formatPrice(card.floorPrice[props.user.preferredUnit], props.user.preferredUnit);
  const priceAvg = formatPrice(card.realTimeAverage.average[props.user.preferredUnit], props.user.preferredUnit);
  const leftSize = "123px";
  return (
    <div className={"rounded-2xl overflow-hidden"}>
      <div className={"flex flex-row gap-3 p-3 bg-surface-container-low"}>
        <div className={"flex flex-col gap-1 items-center"}>
          <a target="_blank" rel="noreferrer" href={linkToCard}>
            <img style={{ maxWidth: leftSize, width: leftSize }} src={card.card.pictureUrl} />
          </a>
          <p className={"font-medium text-xs flex items-center text-on-surface-variant w-full justify-center"}>
            <span
              className={"truncate text-ellipsis text-center flex flex-row items-center gap-1"}
              style={{ maxWidth: leftSize, width: leftSize }}
              title={`Sold by ${card.card.ownerName}`}
            >
              <a target="_blank" rel="noreferrer" href={linkToCard} className={"truncate text-ellipsis"}>
                Sold by {card.card.ownerName}
              </a>
              {card.card.ownerSlug === "sorare" && <IconCertified className="h-4 w-4 fill-sorare-brand" />}
            </span>
          </p>
        </div>
        <div className={"flex flex-col gap-2 w-full h-full"}>
          <div className={"flex flex-col gap-2 justify-between h-full"}>
            <div className={"flex flex-col gap-1"}>
              <div className={"flex flex-row gap-2 items-center"}>
                <h4 className={"m-0 font-semibold text-md hover:text-primary"}>
                  <a target="_blank" rel="noreferrer" href={"/player/" + card.player.playerId}>
                    {card.player.name}
                  </a>
                </h4>
                <span className={"flex flex-row gap-1 items-center"}>
                  <PlayerAvailability availability={card.playerStatus} size={"xs"} />
                  <span className={"text-sm font-semibold text-on-surface-variant"}>
                    {((card.adjustedCardPowerAfterBuy.power - 1) * 100).toFixed(1)}%
                  </span>
                </span>
              </div>
              <div className={"flex flex-row gap-2"}>
                <div className={"w-5 h-5 self-center"}>
                  <img
                    className={"w-full h-full object-contain"}
                    src={card.team.pictureUrl !== "" ? card.team.pictureUrl : UnknownClub}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = UnknownClub;
                    }}
                  />
                </div>
                <p className={"text-sm text-on-surface-variant font-semibold"}>
                  {card.player.position} · {playing_status_labels[card.player.playingStatus] || capitalize(card.player.playingStatus)}
                </p>
              </div>
            </div>
            <div className={"grid grid-cols-5 grid-rows-5 gap-2 text-sm"}>
              <a target="_blank" rel="noreferrer" href={linkToCard} className={"col-span-2 row-span-2 flex w-full"}>
                <div
                  className={`w-full flex flex-col gap-1 justify-between rounded-lg bg-surface-container-highest p-2 font-semibold hover:bg-surface-container-high cursor-pointer ${
                    card.isCardInSeason ? `${cardColorInfo.color} font-semibold` : "font-medium text-on-surface-variant"
                  }`}
                >
                  <p className={"text-xs"}>{card.isCardInSeason ? "In-season" : "Classic"}</p>
                  <p className={"font-semibold"}>{price}</p>
                </div>
              </a>
              <div className={"col-span-3 row-span-2 flex flex-row justify-around text-on-surface-variant items-center font-semibold"}>
                <div className={"flex flex-col gap-1.5 items-center"}>
                  <p className={"text-xs font-medium"}>Floor</p>
                  <p>{floor}</p>
                </div>
                <div className={"border border-r border-outline-variant h-6"} />
                <div className={"flex flex-col gap-1.5 items-center"}>
                  <p className={"text-xs font-medium"}>{getValuationOriginShortOnCard(card.realTimeAverage.origin)}</p>
                  <p>{priceAvg}</p>
                </div>
              </div>
              <div className={"col-span-2 row-span-3 rounded-lg bg-surface-container-highest grid grid-rows-1 grid-cols-2 gap-2 p-2"}>
                <div className={"flex flex-col gap-1.5 items-center"}>
                  <p className={"text-xs font-medium text-on-surface-variant"}>L5</p>
                  <div className={"w-full px-1"}>
                    <ScoreAverage
                      size={"w-7 h-7"}
                      playedSize={"w-7"}
                      font={"text-xs"}
                      avg={parseFloat(card.l5)}
                      period={5}
                      played={card.l5Played}
                      sAvg={parseFloat(card.sorareL5)}
                      sApps={card.appsLast5}
                    />
                  </div>
                </div>
                <div className={"flex flex-col gap-1.5 items-center"}>
                  <p className={"text-xs font-medium text-on-surface-variant"}>L15</p>
                  <div className={"w-full px-1"}>
                    <ScoreAverage
                      size={"w-7 h-7"}
                      playedSize={"w-7"}
                      font={"text-xs"}
                      avg={parseFloat(card.l15)}
                      period={15}
                      played={card.l15Played}
                      sAvg={parseFloat(card.sorareL15)}
                      sApps={card.appsLast15}
                    />
                  </div>
                </div>
              </div>
              <div className={"col-span-3 row-span-3 rounded-lg bg-surface-container-highest flex flex-col gap-1 p-2 justify-center"}>
                <p className={"text-xs font-medium text-on-surface-variant text-center"}>GW #{gwNumber}</p>
                <div className={"flex flex-col w-full"}>
                  <div className={"flex flex-row gap-2 justify-center items-center -mt-2"}>
                    <PickScoreDetails
                      main={card.pickScore.score}
                      revealInfo={{ authorized: true, max: null, current: 0 }}
                      detailed={card.pickScore.radar}
                    />
                    <div className={"flex flex-row items-center"}>
                      <GameInLineVs
                        adapting={true}
                        playerTeam={card.playingTeam.teamId}
                        team1={{
                          TeamId: card.playingTeam.teamId,
                          PictureUrl: card.playingTeam.pictureUrl,
                          ClubName: card.playingTeam.displayName,
                        }}
                        team2={{
                          TeamId: card.playingOpponentTeam.teamId,
                          PictureUrl: card.playingOpponentTeam.pictureUrl,
                          ClubName: card.playingOpponentTeam.displayName,
                        }}
                        game={{
                          GameId: card.mainGame.gameId,
                          HomeTeamId: card.mainGame.homeTeamId,
                        }}
                      />
                    </div>
                  </div>
                  <div className={"px-[5%] w-full"}>
                    <PlayerCommunityProjectionWithHover
                      playerTeamId={card.playingTeam.teamId}
                      projection={card.projectedLineup}
                      sport={sorareFootball}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={"flex flex-row gap-2 justify-between items-center p-3 bg-surface-container-highest"}>
        <div className={"flex flex-row items-center gap-3"}>
          <LineupStrength lineupScoreNormalized={currentLineupStrength.normalized} size={"sm"} darkMode={darkMode} />
          <GainIcon gain={card.lineupStrength.gain} />
          <LineupStrength lineupScoreNormalized={card.lineupStrength.normalized} size={"sm"} darkMode={darkMode} />
        </div>
        <div>
          <a target="_blank" rel="noreferrer" href={linkToCard}>
            <Button context="secondary" icon={<ShoppingCartIcon className={"w-4 h-4 fill-primary"} />} label={"Buy now on Sorare"} />
          </a>
        </div>
      </div>
    </div>
  );
});

const GainIcon = (props) => {
  const { gain } = props;
  return (
    <div className={"flex flex-row items-center justify-center h-full relative"}>
      <LongArrow className={"w-10 fill-on-surface-variant"} />
      <p className={"absolute left-0 bottom-0 text-xs h-full pt-3 text-on-surface-variant"}>+{gain}</p>
    </div>
  );
};

const CardRecommendationLevelRange = (props) => {
  const { selected, allLevels, onChange, darkMode } = props;
  const levelsLength = allLevels.length;
  const { theme } = useTheme();
  const trackColor = getThemeColor(darkMode ? sdDark : theme, "transparent-inverse-surface-low");
  const handleColor = getThemeColor(darkMode ? sdDark : theme, "primary");
  // array from 0 to levelsLength -1
  const marks = {};
  for (let i = 0; i < levelsLength; i++) {
    marks[i] = i;
  }

  return (
    <Slider
      trackStyle={[{ backgroundColor: trackColor, backgroundOpacity: 0.1 }]}
      handleStyle={[{ backgroundColor: handleColor, border: "solid 3px " + handleColor }]}
      min={0}
      max={levelsLength - 1}
      step={1}
      included={false}
      dots={true}
      disabled={levelsLength === 1}
      value={selected}
      onChange={(v) => onChange(v)}
    />
  );
};

const LineupStrengthDistribution = (props) => {
  const { distribution, currentLineupStrength, lineupStrengthWithRecommended, isStrengthWithCaptain, darkMode } = props;
  const { theme } = useTheme();
  const title = isStrengthWithCaptain
    ? "Lineup Strength distribution in this competition (including captain power)"
    : "Lineup Strength distribution in this competition";
  if (distribution === null || distribution == undefined || distribution?.length === 0) {
    return (
      <div className={"dark flex flex-col bg-surface-container-low rounded-2xl gap-3 p-4"}>
        <p className={"text-sm text-on-surface-variant font-medium self-center text-center"}>{title}</p>
        <div className={"flex flex-row justify-center items-center p-5 font-medium"}>
          <p className={"text-on-surface-variant text-xs text-center"}>No data for this competition</p>
        </div>
      </div>
    );
  }
  const cartesianGridStroke = getThemeColor(darkMode ? sdDark : theme, "outline-variant");
  const onSurfaceVariant = getThemeColor(darkMode ? sdDark : theme, "on-surface-variant");
  const barBlue = getThemeColor(darkMode ? sdDark : theme, "graphic-charts-default-chart-6");
  const currentLineupColor = getThemeColor(darkMode ? sdDark : theme, "graphic-charts-default-chart-1");
  const strengthRecommendedColor = getThemeColor(darkMode ? sdDark : theme, "graphic-charts-default-chart-1");

  const maxShare = Math.max(...distribution.map((d) => d.share));
  const maxY = maxShare;
  const firstY = 5;
  // the first number to be over maxY divisible by 5
  let lastY = maxY + (5 - (maxY % 5));
  if (lastY === 0 || IsNaN(lastY)) {
    lastY = 100;
  }
  const yTicks = Array.from(Array((lastY - firstY) / 5 + 1).keys()).map((i) => firstY + i * 5);
  const minStrength = Math.min(...distribution.filter((d) => d.share > 0).map((d) => d.strength));
  const distributionNormalized = [];
  distribution.forEach((d) => {
    if (d.strength >= minStrength) {
      distributionNormalized.push({
        strength: d.strength,
        share: d.share,
        count: d.count,
        currentStrength: currentLineupStrength ? (d.strength >= currentLineupStrength.normalized ? lastY + 10 : null) : null,
        strengthWithRecommended: lineupStrengthWithRecommended
          ? d.strength >= lineupStrengthWithRecommended.normalized
            ? lastY + 10
            : null
          : null,
      });
    }
  });
  distributionNormalized.push({
    strength: 100,
    share: 0,
    currentStrength: currentLineupStrength.normalized,
    strengthWithRecommended: lineupStrengthWithRecommended ? lineupStrengthWithRecommended.normalized : undefined,
  });
  const totalXTicks = [0, 25, 40, 55, 70, 85, 100];
  const xTicks = totalXTicks.filter((x) => x >= minStrength);

  return (
    <div className={"dark flex flex-col bg-surface-container-low rounded-2xl gap-3 p-4"}>
      <p className={"text-sm text-on-surface-variant font-medium self-center text-center"}>{title}</p>
      <div className={"text-on-surface-variant text-xxs"}>
        <div className={"flex w-10 self-start"}>
          <p className={"text-end"}>
            Lineups
            <br />
            share
          </p>
        </div>
        <ResponsiveContainer width="100%" height={200}>
          <ComposedChart
            width={150}
            height={200}
            data={distributionNormalized}
            margin={{
              top: 0,
              right: 40,
              left: -10,
              bottom: 0,
            }}
          >
            <CartesianGrid stroke={cartesianGridStroke} />
            {currentLineupStrength.normalized > 0 && (
              <Area
                type="step"
                dataKey="currentStrength"
                yAxisId={"share"}
                fillOpacity={0.2}
                strokeOpacity={0.2}
                fill={currentLineupColor}
                stroke={currentLineupColor}
              />
            )}
            <Area
              type="step"
              dataKey="strengthWithRecommended"
              yAxisId={"share"}
              fillOpacity={0.4}
              strokeOpacity={0.4}
              fill={strengthRecommendedColor}
              stroke={strengthRecommendedColor}
            />
            <XAxis
              dataKey="strength"
              type="category"
              tick={{ fill: onSurfaceVariant }}
              ticks={xTicks}
              axisLine={{ stroke: cartesianGridStroke, strokeOpacity: 0.1 }}
              style={{
                fontSize: "0.7rem",
                fontFamily: "Inter, sans-serif",
                color: onSurfaceVariant,
              }}
              padding={{ left: 5 }}
            />
            <YAxis
              dataKey="share"
              axisLine={{ stroke: cartesianGridStroke, strokeOpacity: 0.1 }}
              type="number"
              yAxisId={"share"}
              tick={{ fill: onSurfaceVariant }}
              ticks={yTicks}
              interval={0}
              tickLine={false}
              padding={{ top: 10 }}
              style={{
                fontSize: "0.7rem",
                fontFamily: "Inter, sans-serif",
                fontWeight: 500,
                fill: onSurfaceVariant,
              }}
              tickFormatter={(x) => {
                return parseFloat(x).toFixed(0) + "%";
              }}
            />
            <Tooltip
              content={
                <LineupStrengthDistributionTooltip
                  currentLineupStrength={currentLineupStrength}
                  lineupStrengthWithRecommended={lineupStrengthWithRecommended}
                />
              }
            />
            <Bar stackId="a" dataKey="share" opacity={0.5} yAxisId={"share"} radius={[1000, 1000, 0, 0]}>
              {distribution.map((entry, index) => {
                return <Cell opacity={1} stroke={barBlue} fill={barBlue} />;
              })}
            </Bar>
          </ComposedChart>
        </ResponsiveContainer>
        <div className={"flex justify-end -mt-2 mr-5"}>
          <p>Strength</p>
        </div>
        <div className={"flex flex-row justify-center gap-2"}>
          {currentLineupStrength.normalized > 0 && (
            <div className={"flex gap-2 items-center"}>
              <div className={"h-5 w-10 bg-opacity-20 bg-graphic-charts-default-chart-1"} />
              <p className={"text-xs font-medium text-on-surface-variant"}>Top {currentLineupStrength.top}% (current)</p>
            </div>
          )}
          {lineupStrengthWithRecommended && (
            <div className={"flex gap-2 items-center"}>
              <div className={"h-5 w-10 bg-opacity-40 bg-graphic-charts-default-chart-1"} />
              <p className={"text-xs font-medium text-on-surface-variant"}>Top {lineupStrengthWithRecommended.top}% (recommended)</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const LineupStrengthDistributionTooltip = (props) => {
  const { payload, currentLineupStrength, lineupStrengthWithRecommended } = props;
  if (payload === undefined || payload === null || payload.length === 0) {
    return null;
  }
  return (
    <div
      className={`bg-surface-container rounded-lg flex flex-col shadow ring-0 outline-0 focus:outline-0 focus:ring-0 overflow-hidden text-on-surface-variant p-3`}
    >
      <ul className={"flex flex-col items-start text-xs font-medium"}>
        <li>
          Lineup strength: <span className={"font-semibold"}>{payload[0].payload.strength}</span>
        </li>
        <li>
          Lineups count: <span className={"font-semibold"}>{payload[0].payload.count}</span> (
          <span className={"font-semibold"}>{payload[0].payload.share}%</span>)
        </li>
        {payload[0].payload.currentStrength && (
          <li>
            Current lineup strength:{" "}
            <span className={"font-semibold"}>
              {currentLineupStrength.normalized} (top {currentLineupStrength.top}%)
            </span>
          </li>
        )}
        {payload[0].payload.strengthWithRecommended && (
          <li>
            Lineup strength with recommended card:{" "}
            <span className={"font-semibold"}>
              {lineupStrengthWithRecommended.normalized} (top {lineupStrengthWithRecommended.top}%)
            </span>
          </li>
        )}
      </ul>
    </div>
  );
};

const Cap240IndicatorHover = (props) => {
  const { cap240Indicators } = props;
  return (
    <>
      {cap240Indicators?.length > 0 && (
        <>
          <hr />
          <div className="bg-cap240-indicator bg-center bg-no-repeat bg-cover rounded-xl p-3 font-semibold text-xs">
            <div className="text-center grid grid-cols-1 gap-2">
              <span className="text-white text-md">Odds of reaching Sorare thresholds :</span>
              <div className="grid grid-cols-3 gap-2">
                {cap240Indicators?.map((indicator, index) => (
                  <div className="bg-surface-container bg-opacity-70 rounded-xl grid grid-cols-1 gap-1 px-1 py-3" key={index}>
                    <span className="text-xl">
                      {formatNumber(indicator.percentage, undefined, {
                        maximumFractionDigits: 0,
                        minimumFractionDigits: 0,
                      })}
                      %
                    </span>
                    <span className="text-on-surface-variant text-xs font-medium">
                      {formatPrice(indicator.usdAmount, "usd", undefined, 0)} · {indicator.score} pts
                    </span>
                  </div>
                ))}
              </div>
              <span className="text-white text-[10px]">odds are computed with historical data on the last 5 GW</span>
            </div>
          </div>
        </>
      )}
    </>
  );
};

const getDefaultSensibilityLevel = (recommendations) => {
  if (recommendations === undefined || recommendations === null) {
    return null;
  }
  const length = recommendations.length;
  if (length === 0) {
    return null;
  }
  if (length <= 2) {
    return recommendations[0].priceSensibilityLevel;
  }
  if (length <= 4) {
    return recommendations[1].priceSensibilityLevel;
  }
  return recommendations[2].priceSensibilityLevel;
};
