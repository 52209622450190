export const card_editions_objects = [
  { value: "classic", name: "Classic" },
  { value: "Special", name: "Special" },
  { value: "soraries", name: "Soraries" },
  { value: "legend", name: "Legend card" },
  { value: "player_of_the_year", name: "Player Of The Year" },
  { value: "GW200_edition", name: "GW 200 Edition" },
  { value: "Champion Edition", name: "Champion Edition" },
  { value: "player_edition", name: "Player Edition" },
  { value: "special_weekly", name: "Special Weekly Edition" },
  { value: "Normal_Ticket_Edition", name: "Ticket Edition" },
  { value: "ticket_edition", name: "VIP Ticket Edition" },
  { value: "player_of_the_month", name: "Player Of The Month" },
  { value: "west_ham_fa_cup", name: "West Ham FA Cup Edition" },
  { value: "psg_50th_anniversary", name: "PSG 50th anniversary" },
  { value: "liquid_dream", name: "Liquid Dream Edition" },
  { value: "Sunrise Edition", name: "Sunrise Edition" },
  { value: "early_ambassador", name: "Early Ambassador Edition" },
  { value: "stand_for_peace", name: "Stand for peace" },
  { value: "celebration", name: "Celebration" },
];
