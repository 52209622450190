import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import UnknownClub from "../../img/unknown_club.png";
import { t1OrAbove } from "../util/handleSubscriptionTier";
import LockerRound from "../util/orangeOpenLockerBrandBg";
import { Button } from "../util/button";
import { ReactComponent as IconInfoHelp } from "../../img/icons-information-help.svg";
import LUBHistoricalGraphExample1 from "../../img/lub-historical-example-1.png";
import LUBHistoricalGraphExample2 from "../../img/lub-historical-example-2.png";
import { ReactComponent as LeftArrow } from "../../img/icons-arrow-left-no-color.svg";
import { withUser } from "../../userContext";
import PlayCircle from "../../img/play_circle.svg";
import SDLogo from "../../img/new_logo.svg";
import { Bar, CartesianGrid, Cell, ComposedChart, Line, Rectangle, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { getColorFromScarcity } from "../util/divisions";
import IsNaN from "lodash-es/isNaN";
import ordinal_suffix_of from "../util/ordinalNumber";
import { CardReward, CoinReward, MoneyReward } from "./prizePool";
import { abbrv_football_positions, so5_positions } from "../util/positions";
import { lineupDecisiveActionIcons } from "../SO5/SO5LineupCard";
import { CaptainLabel } from "./lineupBuilderLineup";
import ScoreEllipse from "../util/scoreEllipse";
import SorareScoreAverage from "./sorareScoreAverage";
import { formatPrice, formatPriceNoCurrency } from "../util/formatMoney";
import PlayerScoreModal from "../players/playerScoreModal";
import { scarcities_colors } from "../util/scarcities";
import Spinner from "../loader/spinner";
import { ReactComponent as ValidIcon } from "../../img/icons-circle-check-v2-no-color.svg";
import { ReactComponent as InvalidIcon } from "../../img/icons-warning-losange-no-color.svg";
import PopperPortal from "../players/helpers/popper";
import { NoGraphResultsImage } from "../util/noGraphResultImage";
import { getThemeColor, useTheme } from "../../themeContext";
import { isMobile, isTablet } from "react-device-detect";

export const ProtectedHistoricalGraph = withUser((props) => {
  if (t1OrAbove(props.user.tier)) {
    return <HistoricalGraphBox {...props} />;
  }
  return <DummyHistoricalGraph onVisible={props.onVisible} />;
});

const DummyHistoricalGraph = (props) => {
  const { onVisible } = props;
  const { ref, entry } = useInView({
    trackVisibility: true,
    threshold: 0.75,
    delay: 100,
  });
  useEffect(() => {
    if (entry?.isVisible) {
      onVisible && onVisible();
    }
  }, [entry?.isVisible]);

  return (
    <div ref={ref} className={"relative"}>
      <MoreInsightBlockForm />
    </div>
  );
};

const MoreInsightBlockForm = () => {
  return (
    <div
      className={
        "flex flex-col justify-center items-center w-full shadow-xl rounded-xl bg-center bg-clip-border bg-cover bg-no-repeat bg-upgrade-bg-lub-form rounded p-5 gap-3"
      }
    >
      <div className={"flex flex-col items-center justify-center gap-2"}>
        <div className="flex flex-row justify-center self-center">
          <LockerRound containerStyle="w-7 h-7" />
        </div>
        <div className="flex flex-col justify-center text-center gap-1">
          <div>
            <p className={`text-center text-lg text-black font-black text-left font-headers`}>Want more insights?</p>
          </div>
          <div>
            <p className={`text-center text-xs font-semibold text-left`}>Become a Star member to unlock historical reward level charts</p>
          </div>
          <div>
            <a href={"https://youtu.be/kYLPKrHArtE"} target={"blank_"}>
              <div className={"flex flew-row gap-1 justify-center items-center hover:opacity-80 text-xs font-semibold"}>
                <p className={"text-primary "}>Learn more about the subscription tiers</p>
                <div className={"w-4 h-4 self-center inline-flex"}>
                  <img className={"w-full h-full object-contain"} src={PlayCircle} />
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
      <div className="flex flex-row gap-3 w-full justify-center items-center">
        <a href={"/product"}>
          <Button label={"Upgrade"} />
        </a>
      </div>
      <div className={"py-2 px-16 flex flex-col justify-center items-center gap-3"}>
        <img className={"rounded-lg"} style={{ filter: "drop-shadow(0px 4px 36px rgba(0, 0, 0, 0.1))" }} src={LUBHistoricalGraphExample1} />
        <img className={"rounded-lg"} style={{ filter: "drop-shadow(0px 4px 36px rgba(0, 0, 0, 0.1))" }} src={LUBHistoricalGraphExample2} />
      </div>
    </div>
  );
};

const LegendModale = (props) => {
  const { hasThreshold, isCapped, tierScarcities, scoreColors } = props;
  const [focus, setFocus] = useState(false);

  return (
    <div
      className={"relative flex flex-col gap-1 justify-center self-center cursor-default h-full"}
      onMouseEnter={() => setFocus(true)}
      onMouseLeave={() => setFocus(false)}
    >
      {focus && (
        <PopperPortal active={focus}>
          <div className={"bg-surface-container rounded-xl flex flex-col shadow w-52 focus:outline-0 focus:ring-0"}>
            <div className={"p-3 border-b border-transparent-inverse-surface-low border-opacity-10 text-on-surface"}>
              <h3 className={"m-0 font-headers text-md"}>Legend</h3>
            </div>
            <div className={"flex flex-col"}>
              {hasThreshold && (
                <LegendModaleLine
                  icon={
                    <div className={"flex flex-col justify-between items-center gap-1"}>
                      <div className={"w-5 h-4 flex items-center justify-center"}>
                        <div className={"w-5 border-b-2 border-dotted"} style={{ borderColor: lineColors.bounty.bg }} />
                      </div>
                    </div>
                  }
                  label={"Threshold"}
                />
              )}
              <LegendModaleLine
                border={hasThreshold}
                icon={
                  <div className={"w-5 h-4 flex flex-row items-center justify-center gap-1"}>
                    {Object.keys(tierScarcities).map((s) => (
                      <div className={"w-2 h-2 rounded-full"} style={{ backgroundColor: getColorFromScarcity(s) }} />
                    ))}
                  </div>
                }
                label={"Tier"}
              />
              <LegendModaleLine
                border
                icon={
                  <div className={"flex flex-col justify-between items-center gap-2"}>
                    <div className={"w-4 h-4 rounded-t-full"} style={{ backgroundColor: scoreColors.bg, opacity: 0.5 }} />
                  </div>
                }
                label={"Score"}
              />
              {isCapped && (
                <>
                  <LegendModaleLine
                    icon={
                      <div className={"flex flex-col justify-between items-center gap-2"}>
                        <div className={"w-4 h-4 rounded-t-full opacity-50"} style={{ backgroundColor: lineColors.cost }} />
                      </div>
                    }
                    label={"Cost"}
                    border
                  />
                  <LegendModaleLine icon={<ValidIcon width="13px" height="13px" fill={"#B3B3B3"} />} label={"Team validated"} border />
                  <LegendModaleLine icon={<InvalidIcon width="16px" height="16px" fill={"#B3B3B3"} />} label={"Team rejected"} border />
                </>
              )}
            </div>
          </div>
        </PopperPortal>
      )}
      <IconInfoHelp className={"h-5 w-5 fill-on-surface-variant"} />
    </div>
  );
};

const LegendModaleLine = (props) => {
  const { icon, label, border } = props;
  return (
    <div
      className={`px-3 h-10 py-1 flex flex-row  items-center gap-2 ${
        border ? "border-t border-transparent-inverse-surface-low border-opacity-10" : ""
      }`}
    >
      <div className={"flex flex-row gap-1 text-xxs font-semibold w-8 justify-center"}>{icon}</div>
      <div className={"text-xs font-semibold text-on-surface"}>{label}</div>
    </div>
  );
};

const HistoricalGraphBox = (props) => {
  const {
    historicalGraph,
    onChangeHistoricalGraphOlderPage,
    onChangeHistoricalGraphNewerPage,
    loadingHistoricalPerformance,
    lineup,
    captain,
    onVisible,
    divName,
    divLogo,
    divRarity,
    isCapped,
    hasThreshold,
  } = props;
  const { performance, teams, games, hasOlder, hasNewer } = historicalGraph || {};
  const [selectedGw, setSelectedGw] = useState(null);
  const { ref, entry } = useInView({
    trackVisibility: true,
    threshold: 0.75,
    delay: 100,
  });
  useEffect(() => {
    if (entry?.isVisible) {
      onVisible && onVisible();
    }
  }, [entry?.isVisible]);

  useEffect(() => {
    if (performance && performance.length > 0) {
      setSelectedGw(performance[performance.length - 1].gwNumber);
    }
  }, [performance]);

  const atLeastOnePlayerPicked = lineup.length > 0 && lineup.filter((p) => p !== null).length > 0;
  const selectedGwPerformance = performance?.find((p) => p.gwNumber === selectedGw);
  const selectedGwRewards = selectedGwPerformance?.ranking?.rewards;
  let selectedGwPerformanceAtLeastOneReward =
    selectedGwRewards &&
    ((selectedGwRewards.cards && selectedGwRewards.cards.length > 0) ||
      (selectedGwRewards.money.primary.price &&
        selectedGwRewards.money.primary.price !== "" &&
        selectedGwRewards.money.primary.price !== "0") ||
      selectedGwRewards.coins > 0);
  if (isCapped && selectedGwPerformance?.isValidForCap !== undefined && !selectedGwPerformance.isValidForCap) {
    selectedGwPerformanceAtLeastOneReward = false;
  }
  const hasMeaningfulPerformance =
    selectedGwRewards &&
    ((selectedGwRewards.cards && selectedGwRewards.cards.length > 0) ||
      (selectedGwRewards.money.primary.price &&
        selectedGwRewards.money.primary.price !== "" &&
        selectedGwRewards.money.primary.price !== "0"));
  let colorRank = hasMeaningfulPerformance ? "text-success-green" : "text-on-surface";
  let tierScarcities = {};
  performance?.map((d) => {
    if (d.tierScoreThresholds) {
      Object.entries(d.tierScoreThresholds).forEach(([scarcity, v]) => {
        tierScarcities[scarcity] = true;
      });
    }
  });
  const scoreColors = divRarity ? dataColors[divRarity.toLowerCase().replace(" ", "_")] : {};
  const hasData = atLeastOnePlayerPicked;
  const isOnMobile = isMobile || isTablet;
  return (
    <div className={"flex flex-col gap-2"}>
      <div ref={ref} className={"bg-surface-container rounded-md flex flex-col"}>
        <div
          className={"flex flex-row justify-between gap-1 border-b border-transparent-inverse-surface-low border-opacity-10 px-3 py-1.5"}
        >
          <div className={"flex flex-row items-center gap-2"}>
            <h4 className={"text-primary text-sm"}>Historical performance</h4>
            {atLeastOnePlayerPicked && (
              <LegendModale hasThreshold={hasThreshold} isCapped={isCapped} tierScarcities={tierScarcities} scoreColors={scoreColors} />
            )}
            {loadingHistoricalPerformance && performance !== null && performance !== undefined && performance.length > 0 && (
              <Spinner className={"w-4 h-4"} />
            )}
          </div>
          <div className={"flex flex-row gap-1 py-2"}>
            <div
              className={`flex justify-center items-center w-7 ${hasOlder && " hover:opacity-80 cursor-pointer"}`}
              onClick={hasOlder ? () => onChangeHistoricalGraphOlderPage() : undefined}
            >
              <LeftArrow className={hasOlder ? "fill-primary" : "fill-surface-disable"} />
            </div>
            <div className={"border-r border-transparent-inverse-surface-low border-opacity-10"}></div>
            <div
              className={`flex justify-center items-center w-7 ${hasNewer && " hover:opacity-80 cursor-pointer"}`}
              onClick={hasNewer ? () => onChangeHistoricalGraphNewerPage() : undefined}
            >
              <LeftArrow className={`transform rotate-180 ${hasNewer ? "fill-on-disable" : "fill-surface-disable"}`} />
            </div>
          </div>
        </div>
        <div className={"flex flex-col gap-2 p-2"}>
          {(performance === null || performance === undefined) && loadingHistoricalPerformance ? (
            <div className={"p-4"}>
              <Spinner className={"h-10 w-10"} />
            </div>
          ) : (
            <HistoricalGraph
              data={performance}
              selectedGw={selectedGw}
              onSelectGw={(gw) => setSelectedGw(gw)}
              rarity={divRarity}
              isCapped={isCapped}
              hasData={hasData}
            />
          )}
        </div>
      </div>
      {selectedGwPerformance &&
        (hasData ? (
          <div className={"flex flex-col gap-2"}>
            <div className={"bg-surface-container rounded-md flex flex-col"}>
              <div className={"flex flew-row gap-2 sm:gap-4 py-2 px-2 sm:py-4 sm:px-3 items-center"}>
                {divLogo && <img className={"w-8 h-8 sm:w-10 sm:h-10"} src={divLogo} />}
                <div className={"flex flex-col justify-between"}>
                  <h4 className={"m-0 text-xxs sm:text-md uppercase text-on-surface-variant font-semibold"}>{divName}</h4>
                  <div className={"flex flex-row gap-2 text-sm sm:text-lg font-semibold items-center"}>
                    {selectedGwPerformance.ranking.rank && (
                      <>
                        <p className={`${colorRank}`}>{ordinal_suffix_of(selectedGwPerformance.ranking.rank)}</p>
                        <div className={"border-r border-transparent-inverse-surface-low border-opacity-10 h-4"}></div>
                      </>
                    )}
                    <p className={"text-on-surface"}>{selectedGwPerformance.totalScore.toFixed(2)} pts</p>
                  </div>
                </div>
              </div>
              <div className={"grid grid-cols-5 px-2 gap-x-1 sm:px-8 sm:gap-x-6 gap-y-1"}>
                <>
                  {lineup.map((l, posIndex) => {
                    if (l === null || l === undefined) {
                      const position = so5_positions[posIndex];
                      return (
                        <div
                          style={{ aspectRatio: "70 / 113" }}
                          className={`flex flex-col rounded-lg justify-center items-center w-full bg-on-disable`}
                        >
                          <div className={"relative self-center flex justify-center items-center w-full"}>
                            <div className={"absolute flex flex-col justify-center items-center"}>
                              <p className={`font-semibold text-inverse-on-surface text-xs hidden lg:block`}>{position}</p>
                              <p className={`font-semibold text-inverse-on-surface text-xs lg:hidden`}>
                                {abbrv_football_positions[position]}
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    }
                    const card = l.card;
                    const isCaptain = captain && captain.card.Slug === card.Slug;
                    const cardPerf = selectedGwPerformance.lineup[card.Slug];
                    const icons = lineupDecisiveActionIcons(
                      cardPerf?.stats,
                      card.Position,
                      "w-5 h-5 self-center rounded-full",
                      "w-full h-full object-contain shadow rounded-full",
                    );
                    return (
                      <div className={"h-full relative flex flex-col self-end justify-end overflow-hidden"}>
                        <a href={"/card/" + card.TokenId} target={"_blank"} rel="noreferrer">
                          <div className={"absolute z-10 top-0 w-full h-full grid grid-cols-1 grid-rows-4 justify-between"}>
                            <div className={`grid grid-cols-2 z-10 top-0 w-full`}>
                              <div className={"flex w-full justify-center"}>
                                {isCaptain && <CaptainLabel isCaptain borderWidth={"2px"} />}
                              </div>
                            </div>
                          </div>
                        </a>
                        <div className={"flex flex-row self-end justify-end"}>
                          <img className={"object-contain"} src={card.PictureUrl} />
                        </div>
                        <div className={"items-center flex flex-col -mt-2.5 z-10 h-5"}>
                          <div className={`flex flex-row justify-center mx-auto z-10 -space-x-2.5`}>{icons}</div>
                        </div>
                      </div>
                    );
                  })}
                </>
                <>
                  {lineup.map((l) => (
                    <ScoreInLineup lineupCard={l} selectedGwPerformance={selectedGwPerformance} games={games} teams={teams} />
                  ))}
                </>
                <>
                  {lineup.map((l) => {
                    if (l === null || l === undefined) {
                      return <div></div>;
                    }
                    const card = l.card;
                    const cardPerf = selectedGwPerformance.lineup[card.Slug];
                    return (
                      <div className={"flex flex-row items-center justify-center"}>
                        <div className={"w-8 h-8"}>
                          <SorareScoreAverage avg={cardPerf?.cost} />
                        </div>
                      </div>
                    );
                  })}
                </>
              </div>
              <div
                className={
                  "border-t border-transparent-inverse-surface-low border-opacity-10 p-3 flex flex-row gap-1 justify-between items-center mt-3"
                }
              >
                <div>
                  {selectedGwPerformanceAtLeastOneReward ? (
                    <LineupRewards rewards={selectedGwPerformance.ranking.rewards} />
                  ) : (
                    <p className={"text-on-surface-variant text-sm font-semibold"}>
                      No reward
                      {isCapped && !selectedGwPerformance.isValidForCap && <span> - Cap limit reached</span>}
                    </p>
                  )}
                </div>
                <div>
                  <img src={SDLogo} className={"w-6 h-6 sd-light:opacity-50 sd-dark:opacity-80"} />
                </div>
              </div>
            </div>
            {isOnMobile && <CustomTooltip data={selectedGwPerformance} />}
          </div>
        ) : (
          <div className={"bg-surface-container rounded-md flex flex-col"}>
            <div className={"flex flex-col justify-center items-center py-3 sm:py-6"}>
              <div className={"flex flex-col justify-center items-center text-sm w-2/3 text-center gap-1"}>
                <div className={"sm:h-32 sm:w-32"}>
                  <NoGraphResultsImage className={"h-16 sm:h32 w-16 sm:w-32"} />
                </div>
                <p className={"font-semibold text-on-surface"}>No data for selected players</p>
                <p className={"text-xs sm:text-md font-normal text-on-surface-variant"}>
                  Add players with scoring history to visualise their historical performance when aligned together
                </p>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

const ScoreInLineup = (props) => {
  const { lineupCard, selectedGwPerformance, games, teams } = props;
  const [open, setOpen] = useState(false);
  if (lineupCard === null || lineupCard === undefined) {
    return <div></div>;
  }
  const card = lineupCard.card;
  const player = lineupCard.player;
  const cardPerf = selectedGwPerformance.lineup[card.Slug];
  const stats = cardPerf?.stats;
  const noGame = stats?.GameId === null || stats?.GameId === undefined || stats?.GameId === "";
  const game = noGame ? undefined : games[stats.GameId];
  const homeTeam = noGame ? undefined : teams[game.HomeTeamId];
  const awayTeam = noGame ? undefined : teams[game.AwayTeamId];
  const gameForScoreModal = {
    game: game,
    home_team_logo: homeTeam?.PictureUrl,
    away_team_logo: awayTeam?.PictureUrl,
  };
  return (
    <div className={"flex flex-col gap-2 justify-center items-center"}>
      {noGame ? (
        <div className={"flex flex-col justify-center items-center text-center text-on-surface leading-tight font-semibold text-xs"}>
          No
          <br />
          game
        </div>
      ) : (
        <>
          {!noGame && (
            <PlayerScoreModal
              open={open}
              stats={stats}
              sport={player.Sport}
              player={player}
              position={card.Position}
              close={() => setOpen(false)}
              game={gameForScoreModal}
            />
          )}
          <div className={"cursor-pointer"} onClick={() => setOpen(true)}>
            <ScoreEllipse
              size="text-sm"
              score={cardPerf?.score}
              sport={player.Sport}
              power={1}
              formationPlace={stats?.FormationPlace}
              onGameSheet={stats?.OnGameSheet}
              minsPlayed={stats?.mins_played}
              game={game}
            />
          </div>
          <a href={"/game/" + stats.GameId} target={"_blank"} rel="noreferrer">
            <div
              className={"flex flex-row gap-1 justify-center items-center text-xxs sm:text-xs font-semibold"}
              title={homeTeam.DisplayName + " - " + awayTeam.DisplayName}
            >
              <div>
                <img src={homeTeam.PictureUrl || UnknownClub} className={"h-3 w-3 sm:h-4 sm:w-4"} />
              </div>
              <div>
                <span className={game.HomeGoals > game.AwayGoals ? "text-on-surface" : "text-on-surface-variant"}>{game.HomeGoals}</span>
                <span className={"text-on-surface-variant"}>-</span>
                <span className={game.AwayGoals > game.HomeGoals ? "text-on-surface" : "text-on-surface"}>{game.AwayGoals}</span>
              </div>
              <div>
                <img src={awayTeam.PictureUrl || UnknownClub} className={"h-3 w-3 sm:h-4 sm:w-4"} />
              </div>
            </div>
          </a>
        </>
      )}
    </div>
  );
};

const LineupRewards = (props) => {
  const { cards, money, coins } = props.rewards;
  const hasMoneyReward = money.primary.price > 0;
  const primaryValue = hasMoneyReward
    ? formatPriceNoCurrency(money.primary.price, money.primary.currency, undefined, money.primary.currency !== "eth" ? 0 : undefined)
    : "";
  const secondaryValue = hasMoneyReward ? formatPrice(money.secondary.price, money.secondary.currency) : "";
  return (
    <div className={"flex flex-row gap-1"}>
      {cards?.map((c) => (
        <CardReward scarcity={c.scarcity} tier={c.tier} />
      ))}
      {hasMoneyReward && <MoneyReward reward={money} primaryValue={primaryValue} secondaryValue={secondaryValue} />}
      {coins > 0 && <CoinReward coins={coins} />}
    </div>
  );
};

const lineColors = {
  cost: "#BDBDBD",
  bounty: {
    bg: "#9F28FC",
    border: "#6e1aad",
  },
};

const dataColors = {
  common: {
    bg: "#949494",
    border: "#4f4f4f",
  },
  limited: {
    bg: scarcities_colors.limited,
    border: "#997100",
  },
  rare: {
    bg: scarcities_colors.rare,
    border: "#B52504",
  },
  rare_pro: {
    bg: scarcities_colors.rare,
    border: "#B52504",
  },
  super_rare: {
    bg: scarcities_colors["super rare"],
    border: "#0264BE",
  },
  unique: {
    bg: scarcities_colors.unique,
    border: "#303030",
  },
  mix: {
    bg: scarcities_colors["custom series"],
    border: "#6823AA",
  },
};

const barSize = 18;

const CustomizedScoreLabel = (props) => {
  const { x, y, stroke, value, color, selectedIndex, index, onClick, isCapped } = props;
  const { theme } = useTheme();
  const backgroundColor = getThemeColor(theme, "surface-container");
  const backgroundColorVariant = getThemeColor(theme, "surface-container-high");
  const textColor = getThemeColor(theme, "on-surface-variant");
  const borderColor = getThemeColor(theme, "transparent-inverse-surface-low");
  const isSelected = selectedIndex === index;
  const offset = isCapped ? barSize + 0.5 : barSize / 3;
  return (
    <g
      cursor="pointer"
      transform={`translate(${x - offset},247)`}
      onClick={() => onClick(index)}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
    >
      <rect
        x="0"
        y="0"
        width="30"
        height="18"
        rx="1.5"
        fill={isSelected ? backgroundColor : backgroundColorVariant}
        stroke={isSelected ? color : borderColor}
      />
      <text
        x="15"
        y="9"
        dy="0.4em"
        textAnchor={"middle"}
        fontWeight={isSelected ? "800" : "600"}
        fontSize="10px"
        fill={isSelected ? color : textColor}
      >
        {value.toFixed(0)}
      </text>
    </g>
  );

  // Convert previous instruction to the same but
};

const CustomizedCostLabel = (props, payload) => {
  const { x, y, stroke, value, color, selectedIndex, index, onClick, validity } = props;
  const isSelected = selectedIndex === index;
  const isValid = validity[index];
  if (value < 50) {
    return null; // No label for small values
  }
  return (
    <g cursor="pointer" transform={`translate(${x + (barSize - 1) / 2 - 8},${y + 15})`} onClick={() => onClick(index)}>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_4685_294773" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
          <rect x="0.5" width="16" height="16" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_4685_294773)">
          {isValid ? (
            <path
              d="M7.56667 9.2L6.11667 7.75C5.99444 7.62778 5.84444 7.56667 5.66667 7.56667C5.48889 7.56667 5.33333 7.63333 5.2 7.76667C5.07778 7.88889 5.01667 8.04444 5.01667 8.23333C5.01667 8.42222 5.07778 8.57778 5.2 8.7L7.1 10.6C7.22222 10.7222 7.37778 10.7833 7.56667 10.7833C7.75556 10.7833 7.91111 10.7222 8.03333 10.6L11.8167 6.81667C11.9389 6.69444 12 6.54444 12 6.36667C12 6.18889 11.9333 6.03333 11.8 5.9C11.6778 5.77778 11.5222 5.71667 11.3333 5.71667C11.1444 5.71667 10.9889 5.77778 10.8667 5.9L7.56667 9.2ZM8.5 14.6667C7.57778 14.6667 6.71111 14.4917 5.9 14.1417C5.08889 13.7917 4.38333 13.3167 3.78333 12.7167C3.18333 12.1167 2.70833 11.4111 2.35833 10.6C2.00833 9.78889 1.83333 8.92222 1.83333 8C1.83333 7.07778 2.00833 6.21111 2.35833 5.4C2.70833 4.58889 3.18333 3.88333 3.78333 3.28333C4.38333 2.68333 5.08889 2.20833 5.9 1.85833C6.71111 1.50833 7.57778 1.33333 8.5 1.33333C9.42222 1.33333 10.2889 1.50833 11.1 1.85833C11.9111 2.20833 12.6167 2.68333 13.2167 3.28333C13.8167 3.88333 14.2917 4.58889 14.6417 5.4C14.9917 6.21111 15.1667 7.07778 15.1667 8C15.1667 8.92222 14.9917 9.78889 14.6417 10.6C14.2917 11.4111 13.8167 12.1167 13.2167 12.7167C12.6167 13.3167 11.9111 13.7917 11.1 14.1417C10.2889 14.4917 9.42222 14.6667 8.5 14.6667Z"
              fill={isSelected ? "white" : "#B3B3B3"}
            />
          ) : (
            <path
              d="M9.45 14.3C9.31667 14.4333 9.16945 14.5306 9.00833 14.5917C8.84722 14.6528 8.67778 14.6833 8.5 14.6833C8.32222 14.6833 8.15278 14.6528 7.99167 14.5917C7.83056 14.5306 7.68333 14.4333 7.55 14.3L2.2 8.95C2.06667 8.81667 1.96944 8.66945 1.90833 8.50833C1.84722 8.34722 1.81667 8.17778 1.81667 8C1.81667 7.82222 1.84722 7.65278 1.90833 7.49167C1.96944 7.33056 2.06667 7.18333 2.2 7.05L7.55 1.7C7.68333 1.56667 7.83056 1.46944 7.99167 1.40833C8.15278 1.34722 8.32222 1.31667 8.5 1.31667C8.67778 1.31667 8.84722 1.34722 9.00833 1.40833C9.16945 1.46944 9.31667 1.56667 9.45 1.7L14.8 7.05C14.9333 7.18333 15.0306 7.33056 15.0917 7.49167C15.1528 7.65278 15.1833 7.82222 15.1833 8C15.1833 8.17778 15.1528 8.34722 15.0917 8.50833C15.0306 8.66945 14.9333 8.81667 14.8 8.95L9.45 14.3ZM8.5 8.66667C8.68889 8.66667 8.84722 8.60278 8.975 8.475C9.10278 8.34722 9.16667 8.18889 9.16667 8V5.33333C9.16667 5.14444 9.10278 4.98611 8.975 4.85833C8.84722 4.73056 8.68889 4.66667 8.5 4.66667C8.31111 4.66667 8.15278 4.73056 8.025 4.85833C7.89722 4.98611 7.83333 5.14444 7.83333 5.33333V8C7.83333 8.18889 7.89722 8.34722 8.025 8.475C8.15278 8.60278 8.31111 8.66667 8.5 8.66667ZM8.5 10.6667C8.68889 10.6667 8.84722 10.6028 8.975 10.475C9.10278 10.3472 9.16667 10.1889 9.16667 10C9.16667 9.81111 9.10278 9.65278 8.975 9.525C8.84722 9.39722 8.68889 9.33333 8.5 9.33333C8.31111 9.33333 8.15278 9.39722 8.025 9.525C7.89722 9.65278 7.83333 9.81111 7.83333 10C7.83333 10.1889 7.89722 10.3472 8.025 10.475C8.15278 10.6028 8.31111 10.6667 8.5 10.6667Z"
              fill={isSelected ? "white" : "#B3B3B3"}
            />
          )}
        </g>
      </svg>
    </g>
  );
};

const CustomizedTierLabel = (props) => {
  const { x, y, stroke, value, colors, lastIndex, index, tier } = props;
  const toDisplay = index === lastIndex;
  if (!toDisplay) {
    return null;
  }
  return (
    <g transform={`translate(${x + barSize + 3},${y})`}>
      <text
        x="0"
        y="0"
        dy="0.36em"
        textAnchor={"middle"}
        fontWeight="600"
        fontSize="10px"
        strokeWidth={0.1}
        stroke={colors.border}
        fill={colors.bg}
      >
        {tier}
      </text>
    </g>
  );
};

const CustomTooltipLine = (props) => {
  const { label, value, border, decimals, hovered } = props;
  return (
    <div
      className={`px-3 h-8 py-1 flex flex-row justify-between items-center gap-10 ${
        border ? "border-t border-transparent-inverse-surface-low border-opacity-10" : ""
      } ${hovered ? "bg-containers-surface-hover-primarytint" : "bg-surface-container"}`}
    >
      <div className={"flex flex-row gap-1 text-xxs font-semibold text-on-surface"}>{label}</div>
      <div className={"text-xs font-semibold text-on-surface"}>{value.toFixed(decimals !== undefined ? decimals : 2)}</div>
    </div>
  );
};

const CustomTooltipCardLine = (props) => {
  const { cards, score, border, hovered } = props;
  if (cards === undefined || cards.length === 0) {
    return null;
  }
  const label = cards.map((c) => (
    <CardReward scarcity={c.scarcity.replace("_", " ")} tier={c.tier} cardSize={"w-2"} fontSize={"text-xxs"} />
  ));
  return <CustomTooltipLine hovered={hovered} border={border} label={label} value={score} />;
};

const CustomTooltip = (props) => {
  const { payload, data, hovered, coordinate } = props;
  if ((payload === undefined || payload === null || payload.length === 0) && (data === undefined || data === null)) {
    return null;
  }
  let totalScore;
  let totalCost;
  let threshold;
  let tiersByScore = {};
  if (payload) {
    // Coming from the diagram lib
    payload?.forEach((p) => {
      if (p.dataKey === "totalScore") {
        totalScore = p.value;
      } else if (p.dataKey === "totalCost") {
        totalCost = p.value;
      } else if (p.dataKey === "bountyThreshold") {
        threshold = p.value;
      } else {
        const tierInfo = p.dataKey?.split("Tier");
        if (tierInfo && tierInfo.length === 2) {
          tiersByScore[p.value] = [
            ...(tiersByScore[p.value] || []),
            {
              tier: tierInfo[1],
              scarcity: tierInfo[0],
              originalKey: p.dataKey,
            },
          ];
        }
      }
    });
  } else if (data) {
    // Coming from the initial data coming from the API
    totalScore = data.totalScore;
    totalCost = data.totalCost;
    threshold = data.bountyThreshold;
    data.tierScoreThresholds &&
      Object.entries(data.tierScoreThresholds).forEach(([scarcity, tiers]) => {
        tiers.forEach((score, tier) => {
          if (score !== null) {
            tiersByScore[score] = [
              ...(tiersByScore[score] || []),
              {
                tier: tier,
                scarcity: scarcity,
                originalKey: `${scarcity}Tier${tier}`,
              },
            ];
          }
        });
      });
  }

  const tierByScoreSorted = Object.keys(tiersByScore)
    .sort((a, b) => Number(b) - Number(a))
    .map((key) => {
      return {
        cards: tiersByScore[key],
        score: Number(key),
      };
    });
  return (
    <div
      className={`bg-surface-container rounded-lg flex flex-col shadow ring-0 outline-0 focus:outline-0 focus:ring-0 overflow-hidden ${
        coordinate !== undefined ? (coordinate.x < 234 ? "ml-5" : "mr-5") : ""
      }`}
    >
      <div className={"p-3 border-b border-transparent-inverse-surface-low border-opacity-10"}>
        <h3 className={"m-0 font-headers text-md text-on-surface"}>Tier details</h3>
      </div>
      <div className={"flex flex-col"}>
        {totalScore !== undefined && (
          <CustomTooltipLine label={"Your lineup score"} hovered={hovered === "totalScore"} value={totalScore} />
        )}
        {totalCost !== undefined && (
          <CustomTooltipLine border label={"Your lineup cost"} hovered={hovered === "totalCost"} value={totalCost} decimals={0} />
        )}
        {threshold !== undefined && (
          <CustomTooltipLine border label={"Threshold"} hovered={hovered === "bountyThreshold"} value={threshold} decimals={0} />
        )}
        {tierByScoreSorted.map((tiers, index) => (
          <CustomTooltipCardLine
            border
            key={index}
            hovered={tiers.cards.some((c) => c.originalKey === hovered)}
            cards={tiers.cards}
            score={tiers.score}
          />
        ))}
      </div>
    </div>
  );
};

const CustomCursor = (props) => {
  const { x, y, width, height, stroke, points } = props;
  const boundingBoxSize = 40;
  const lineWidth = 1;
  const { theme } = useTheme();
  const fillColor = getThemeColor(theme, "transparent-inverse-surface-low");
  return (
    <svg
      width={boundingBoxSize}
      height={height}
      x={points[0].x - boundingBoxSize / 2}
      y={y}
      viewBox={`0 0 ${boundingBoxSize} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_4685_294774"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width={boundingBoxSize}
        height={height}
      >
        <rect x="0.5" width={boundingBoxSize} height={height} fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_4685_294774)">
        <Rectangle
          fill="transparent"
          x={0}
          y={0}
          width={boundingBoxSize}
          height={height}
          onClick={() => props.onClick(props.payloadIndex)}
          cursor={"pointer"}
        />
        <Rectangle fill={fillColor} stroke={fillColor} x={boundingBoxSize / 2 - lineWidth / 2} y={0} width={lineWidth} height={height} />
      </g>
    </svg>
  );
};

const HistoricalGraph = (props) => {
  const { data, selectedGw, onSelectGw, rarity, isCapped, hasData } = props;
  const [hoveredData, setHoveredData] = useState("");
  const { theme } = useTheme();
  let hasThreshold = false;
  const tierColors = {};
  let mainScarcity = "";
  const scarcities = {};
  let nbDifferentRewardForTier = 0;
  const normalizedData =
    data?.map((d) => {
      const tiers = {};
      let reachedReward = false;
      if (d.tierScoreThresholds) {
        Object.entries(d.tierScoreThresholds).forEach(([scarcity, v]) => {
          if (mainScarcity === "" || nbDifferentRewardForTier < v.length) {
            nbDifferentRewardForTier = v.length;
            mainScarcity = scarcity;
          }
          scarcities[scarcity] = true;
          v.forEach((score, tier) => {
            if (score !== null) {
              if (d.totalScore >= score) {
                reachedReward = true;
              }
              tiers[scarcity + "Tier" + tier] = score;
              tierColors[scarcity + "Tier" + tier] = dataColors[scarcity.toLowerCase().replace(" ", "_")];
            }
          });
        });
      }
      if (d.bountyThreshold) {
        hasThreshold = true;
      }
      return {
        ...tiers,
        tiers: tiers,
        reachedReward: reachedReward,
        gwNumber: d.gwNumber,
        totalScore: d.totalScore,
        totalCost: d.totalCost,
        isValidForCap: d.isValidForCap,
        bountyThreshold: d.bountyThreshold,
        reachedBounty: d.bountyThreshold ? d.totalScore >= d.bountyThreshold : false,
      };
    }) || [];
  const lastTiers = normalizedData.length > 0 ? normalizedData[normalizedData.length - 1].tiers : {};
  let lastTiersSortedByScore = Object.entries(lastTiers).sort((a, b) => a[1] > b[1]);
  const tiersNotInLast = Object.entries(tierColors).filter(([t, v]) => !lastTiersSortedByScore.map((t) => t[0]).includes(t));
  lastTiersSortedByScore = [...tiersNotInLast, ...lastTiersSortedByScore];

  const maxScore = Math.max(
    ...normalizedData.map((d) => d.totalScore).filter((s) => s !== null && s !== undefined),
    ...normalizedData.map((d) => d.totalCost).filter((s) => s !== null && s !== undefined),
    ...Object.keys(tierColors)
      .map((c) => normalizedData.map((d) => d[c]))
      .flat(1)
      .filter((s) => s !== null && s !== undefined),
  );
  const minScore = Math.min(
    ...normalizedData.map((d) => d.totalScore).filter((s) => s !== null && s !== undefined),
    ...normalizedData.map((d) => d.totalCost).filter((s) => s !== null && s !== undefined),
    ...Object.keys(tierColors)
      .map((c) => normalizedData.map((d) => d[c]))
      .flat(1)
      .filter((s) => s !== null && s !== undefined),
  );
  const ticks = normalizedData.map((d) => d.gwNumber);
  const minY = minScore > 0 ? minScore : 0;
  const maxY = maxScore;
  // the first number to be under minY divisible by 50
  let firstY = minY - (minY % 50);
  if (firstY === 0 || IsNaN(firstY)) {
    firstY = 0;
  }
  // the first number to be over maxY divisible by 50
  let lastY = maxY + (50 - (maxY % 50));
  if (lastY === 0 || IsNaN(lastY)) {
    lastY = 50;
  }
  // array with numbers from firstY to lastY, with a step of 50
  const yTicks = Array.from(Array((lastY - firstY) / 50 + 1).keys()).map((i) => firstY + i * 50);
  const thresholdsReached = normalizedData.filter((d) => d.reachedBounty).length;
  const thresholdsReachedPercentage = ((thresholdsReached / normalizedData.length) * 100).toFixed(0);
  const rewardReached = normalizedData.filter((d) => d.reachedReward).length;
  const rewardReachedPercentage = ((rewardReached / normalizedData.length) * 100).toFixed(0);

  const onClickBar = (e) => {
    onSelectGw(e.gwNumber);
  };

  const onClickGwTick = (e) => {
    onSelectGw(e.value);
  };

  const onClickLabel = (index) => {
    const newSelectedGw = data[index]?.gwNumber;
    if (newSelectedGw) {
      onSelectGw(newSelectedGw);
    }
  };

  const onClickTierDot = (e, payload) => {
    payload?.payload?.gwNumber && onSelectGw(payload.payload.gwNumber);
  };

  const onClickTooltipCursor = (index) => {
    const newSelectedGw = data[index]?.gwNumber;
    if (newSelectedGw) {
      onSelectGw(newSelectedGw);
    }
  };

  const scoreColors = rarity ? dataColors[rarity.toLowerCase().replace(" ", "_")] : {};
  const lastNonNullIndex = normalizedData.map((d) => Object.keys(d.tiers).length > 0).lastIndexOf(true);
  const cartesianGridStroke = getThemeColor(theme, "transparent-inverse-surface-low");
  const onSurfaceVariant = getThemeColor(theme, "on-surface-variant");
  const isOnMobile = isMobile || isTablet;
  return (
    <div className={"flex flex-col"}>
      <ResponsiveContainer width="100%" height={300}>
        <ComposedChart
          width={300}
          height={300}
          data={normalizedData}
          margin={{
            top: 0,
            right: 0,
            left: -20,
            bottom: 0,
          }}
        >
          <defs>
            <linearGradient id="score" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={lineColors.score} stopOpacity={0.4} />
              <stop offset="60%" stopColor={lineColors.score} stopOpacity={0} />
            </linearGradient>
          </defs>
          <CartesianGrid stroke={cartesianGridStroke} />
          <XAxis
            dataKey="gwNumber"
            ticks={ticks}
            tickLine={false}
            tick={(e) => {
              const {
                payload: { value },
              } = e;
              const isSelected = value === selectedGw;
              e["fill"] = isSelected ? scoreColors.bg : onSurfaceVariant;
              e["dy"] = "1em";
              e["fontWeight"] = isSelected ? 600 : 500;
              return <text {...e}>#{value}</text>;
            }}
            padding={{ right: 10 }}
            type="category"
            axisLine={{ stroke: cartesianGridStroke, strokeOpacity: 0.1 }}
            style={{
              fontSize: "0.7rem",
              fontFamily: "Inter, sans-serif",
            }}
            cursor="pointer"
            onClick={onClickGwTick}
          />
          <Tooltip
            wrapperStyle={{ outline: "none" }}
            cursor={<CustomCursor onClick={onClickTooltipCursor} />}
            content={isOnMobile ? <div /> : <CustomTooltip hovered={hoveredData} />}
          />
          <YAxis
            dataKey="totalScore"
            axisLine={{ stroke: cartesianGridStroke, strokeOpacity: 0.1 }}
            type="number"
            yAxisId={"totalScore"}
            interval={0}
            ticks={yTicks}
            minTickGap={0}
            domain={[minY, maxY]}
            tickLine={false}
            padding={{ top: 10 }}
            style={{
              fontSize: "0.7rem",
              fontFamily: "Inter, sans-serif",
              fontWeight: 500,
              fill: onSurfaceVariant,
            }}
            tickFormatter={(x) => {
              return x.toFixed(0);
            }}
          />
          {isCapped && (
            <Bar
              stackId="b"
              dataKey="totalCost"
              cursor="pointer"
              barSize={barSize}
              yAxisId={"totalScore"}
              onMouseEnter={() => setHoveredData("totalCost")}
              onMouseLeave={() => setHoveredData("")}
              label={
                <CustomizedCostLabel
                  onClick={onClickLabel}
                  color={scoreColors.bg}
                  validity={normalizedData.map((d) => d.isValidForCap)}
                  selectedIndex={normalizedData.findIndex((d) => d.gwNumber === selectedGw)}
                />
              }
              isAnimationActive={false}
              radius={[1000, 1000, 0, 0]}
              onClick={onClickBar}
            >
              {normalizedData.map((entry, index) => {
                const isSelected = entry.gwNumber === selectedGw;
                const isValid = entry.isValidForCap;
                return (
                  <Cell
                    opacity={isSelected ? 1 : 0.5}
                    stroke={isSelected ? (isValid ? "#169A54" : "#CC2727") : "#BDBDBD"}
                    fill={isSelected ? (isValid ? "#50BF84" : "#FF3232") : "#BDBDBD"}
                  />
                );
              })}
            </Bar>
          )}
          <Bar
            stackId="a"
            dataKey="totalScore"
            cursor="pointer"
            barSize={barSize}
            opacity={0.5}
            onMouseEnter={() => setHoveredData("totalScore")}
            onMouseLeave={() => setHoveredData("")}
            yAxisId={"totalScore"}
            label={
              <CustomizedScoreLabel
                onClick={onClickLabel}
                color={scoreColors.bg}
                isCapped={isCapped}
                selectedIndex={normalizedData.findIndex((d) => d.gwNumber === selectedGw)}
                onMouseEnter={() => setHoveredData("totalScore")}
                onMouseLeave={() => setHoveredData("")}
              />
            }
            isAnimationActive={false}
            radius={[1000, 1000, 0, 0]}
            onClick={onClickBar}
          >
            {normalizedData.map((entry, index) => {
              const isSelected = entry.gwNumber === selectedGw;
              return (
                <Cell opacity={isSelected ? 1 : 0.5} stroke={isSelected ? scoreColors.border : scoreColors.bg} fill={scoreColors.bg} />
              );
            })}
          </Bar>
          <Line
            dataKey="bountyThreshold"
            yAxisId={"totalScore"}
            stroke={lineColors.bounty.bg}
            strokeWidth={1}
            type="monotone"
            strokeDasharray="3 3"
            isAnimationActive={false}
            activeDot={{
              cursor: "pointer",
              onMouseEnter: () => setHoveredData("bountyThreshold"),
              onMouseLeave: () => setHoveredData(""),
              r: 5,
              fill: lineColors.bounty.bg,
              stroke: lineColors.bounty.border,
              strokeWidth: 2,
              onClick: onClickTierDot,
            }}
            dot={false}
          />
          {lastTiersSortedByScore.map(([key, score]) => {
            const colors = tierColors[key];
            const tierNumber = key.split("Tier")[1];
            const tier = "T" + tierNumber;
            const displayLabel = hoveredData === "" || hoveredData === key || !hoveredData.includes("Tier");
            return (
              <Line
                connectNulls={true}
                dataKey={key}
                yAxisId={"totalScore"}
                stroke={colors.bg}
                strokeLinecap="round"
                strokeWidth={1}
                type="monotone"
                isAnimationActive={false}
                opacity={displayLabel ? 1 : 0.15}
                activeDot={
                  displayLabel
                    ? {
                        cursor: "pointer",
                        onMouseEnter: () => setHoveredData(key),
                        onMouseLeave: () => setHoveredData(""),
                        r: 5,
                        fill: colors.bg,
                        stroke: colors.border,
                        strokeWidth: 2,
                        onClick: onClickTierDot,
                      }
                    : false
                }
                dot={{
                  r: 4,
                  fill: colors.bg,
                  stroke: colors.border,
                  strokeWidth: 1,
                }}
                label={displayLabel && <CustomizedTierLabel colors={colors} tier={tier} lastIndex={lastNonNullIndex} />}
              />
            );
          })}
        </ComposedChart>
      </ResponsiveContainer>
      <div className={"flex flex-row gap-4 px-2 justify-evenly items-center"}>
        {hasThreshold && (
          <div className={"rounded-lg bg-surface-container-high px-2 py-2 flex flex-col text-center justify-center items-center w-1/2"}>
            <div className={"text-on-surface-variant text-sm font-semibold"}>THRESHOLD</div>
            {hasData ? (
              <div className={"text-on-surface text-md font-semibold"}>
                {thresholdsReached}/{normalizedData.length}
                <span className={"text-sm ml-1"}>({thresholdsReachedPercentage}%)</span>
              </div>
            ) : (
              <div className={"text-on-surface text-md font-semibold"}>---</div>
            )}
          </div>
        )}
        <div className={`rounded-lg bg-surface-container-high px-2 py-2 flex flex-col text-center justify-center items-center w-1/2`}>
          <div className={"text-on-surface-variant text-sm font-semibold"}>REWARDS</div>
          {hasData ? (
            <div className={"text-on-surface text-md font-semibold"}>
              {rewardReached}/{normalizedData.length}
              <span className={"text-sm ml-1"}>({rewardReachedPercentage}%)</span>
            </div>
          ) : (
            <div className={"text-on-surface text-md font-semibold"}>---</div>
          )}
        </div>
      </div>
    </div>
  );
};
