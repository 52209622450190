import React, { useEffect, useState } from "react";
import { Text } from "grommet";
import ordinal_suffix_of from "../util/ordinalNumber";
import { ReactTooltip } from "../util/tooltip.js";
import SO5LineupCard from "./SO5LineupCard";
import SDIcon from "../../img/sd-logo-dark-mode.svg";
import { t1OrAbove } from "../util/handleSubscriptionTier";
import { Button } from "../util/button";
import { withUser } from "../../userContext";
import { findPriceForUserUnit } from "../util/formatMoney";
import Coin from "../../img/coin.png";
import CardIcon from "../util/cardIcon";
import EthereumIconWhite from "../../img/ethereum_white.svg";
import VisibilityOff from "../../img/visibility_off_cccccc.svg";
import InfoIconGrey from "../../img/info-icon-grey.svg";
import { compareCardPositions } from "../util/sorting";
import { getDivisionAndRarityDisplayName } from "../util/divisions";

export const nextRewardsToText = (nextRewards, preferredUnit) => {
  const cards = [];
  if (nextRewards?.cards) {
    cards.push(...nextRewards.cards);
  } else if (nextRewards?.reward_cards) {
    // retro compatibility
    cards.push(...nextRewards.reward_cards);
  }
  const nextRewardAcc = cards.map((rc, index) => {
    if (index === 0) {
      return `Tier ${rc.tier} ${rc.scarcity.toLowerCase()}`;
    }
    return `tier ${rc.tier} ${rc.scarcity.toLowerCase()}`;
  });
  if (nextRewards?.eth > 0) {
    nextRewardAcc.push(findPriceForUserUnit(nextRewards.eth, nextRewards.fiat, preferredUnit, false, 3));
  }
  return nextRewardAcc?.join(" + ");
};

export const getNormalizedInfoOfLineupPlayer = (playerInLineup, lineup, gamesByTeamId, playerStats, playerPower) => {
  let SO5Score = "DNP";
  let totalPoints = "0.00";
  if (gamesByTeamId[playerInLineup.player.TeamId] === undefined && gamesByTeamId[playerInLineup.player.NationalTeam] === undefined) {
    SO5Score = parseFloat(0).toFixed(2);
  }
  let power = parseFloat(playerPower);
  let totalPointsNotFixed = 0;
  if (playerStats !== undefined) {
    SO5Score = playerStats.SO5Score;
    if (playerInLineup.card.Position === "Defender") {
      SO5Score = playerStats.SO5ScoreDF;
    } else if (playerInLineup.card.Position === "Midfielder") {
      SO5Score = playerStats.SO5ScoreMD;
    } else if (playerInLineup.card.Position === "Forward") {
      SO5Score = playerStats.SO5ScoreFW;
    } else if (playerInLineup.card.Position === "Goalkeeper") {
      SO5Score = playerStats.SO5ScoreGK;
    }
    if (
      playerInLineup.player.NationalTeam !== "" &&
      !(
        lineup.DivisionId.includes("global") ||
        lineup.DivisionId.includes("weekly") ||
        lineup.DivisionId.includes("starter") ||
        lineup.DivisionId.includes("training") ||
        lineup.DivisionId.includes("academy")
      ) &&
      playerStats?.TeamId === playerInLineup.player.NationalTeam
    ) {
      SO5Score = "0.0";
      totalPoints = "0.0";
    } else {
      totalPoints = parseFloat(power * SO5Score).toFixed(1);

      if (SO5Score !== "DNP") {
        totalPointsNotFixed = parseFloat(power * SO5Score);
        SO5Score = parseFloat(SO5Score).toFixed(1);
      } else {
        totalPoints = 0.0;
      }
    }
  }
  let game = gamesByTeamId[playerInLineup.player.TeamId];

  if (
    playerInLineup.player.NationalTeam !== "" &&
    (lineup.DivisionId.includes("global") ||
      lineup.DivisionId.includes("weekly") ||
      lineup.DivisionId.includes("rookie") ||
      lineup.DivisionId.includes("training") ||
      lineup.DivisionId.includes("academy"))
  ) {
    game = gamesByTeamId[playerInLineup.player.NationalTeam];
    if (game === undefined) {
      game = gamesByTeamId[playerInLineup.player.TeamId];
    }
  }

  let floatSO5Score = parseFloat(SO5Score);
  if (SO5Score === "DNP") {
    floatSO5Score = "DNP";
  }

  return {
    SO5Score,
    totalPoints,
    game,
    totalPointsNotFixed,
    power,
    floatSO5Score,
  };
};

function SO5LineupCards(props) {
  useEffect(() => ReactTooltip.rebuild(), []);
  const [overview, setOverview] = useState(false);
  const [rewardMoney, setRewardMoney] = useState("");
  const [shouldDisplayEthLogo, setShouldDisplayEthLogo] = useState(props.user.preferredUnit === "eth");
  const [nextRewardTextFormatted, setNextRewardTextFormatted] = useState("");

  useEffect(() => {
    setShouldDisplayEthLogo(props.user.preferredUnit === "eth");

    const nextReward = props.nextRewards[props.lineup.LineupId];
    const nextRewardText = nextRewardsToText(nextReward, props.user.preferredUnit);
    setNextRewardTextFormatted(nextRewardText);

    rewards?.map((r) => {
      if (r.lineupId === lineup.LineupId) {
        if (r.eth > 0) {
          setRewardMoney(findPriceForUserUnit(r.eth, r.fiat, props.user.preferredUnit, true, 4));
        }
      }
    });
  }, [props.user.preferredUnit]);

  let players = props.players.rare_players;
  if (players === null) players = props.players.common_players;
  else if (props.players.common_players != null) players = players.concat(props.players.common_players);

  players.sort(compareCardPositions);

  const stats = props.stats;
  const games = props.games;
  const comp = props.comp;
  const rank = props.rank;
  const top = props.top;
  const live = props.live;
  const lineup = props.lineup;
  const rewards = props.rewards;
  const next_rewards = props.nextRewards;
  const rewards_overview = props.rewardsOverview[lineup.LineupId];
  const compTitle = comp.DisplayNameShort || comp.DisplayName;
  const compSubtitle = getDivisionAndRarityDisplayName(comp);
  let rewardCards = [];
  let completion = comp.CompletionInPct;
  let rankingSystem = comp.RewardType;
  let topString = "";
  let colorRank = "text-brand-black";
  let maxRanks = ordinal_suffix_of(lineup.MaxRank) + " - " + ordinal_suffix_of(lineup.MinRank);
  if (rankingSystem === "top" || rankingSystem === "top_and_score") {
    if (rank <= comp.PlaceThreshold2) {
      colorRank = "text-[#99E97C]";
    } else if (rank <= comp.PlaceThreshold1) {
      colorRank = "text-[#99E97C]";
    } else {
      colorRank = "text-grey-b3";
    }
    topString = "(top " + parseFloat(top).toFixed(2) + "%)";
  }
  if (rankingSystem === "ranking") {
    if (rank <= comp.PlaceThreshold2) {
      colorRank = "text-[#99E97C]";
    } else if (rank <= comp.PlaceThreshold1) {
      colorRank = "text-[#99E97C]";
    } else {
      colorRank = "text-grey-b3";
    }
  }
  let totalLineupPointsComputed = 0;
  let textRewards = "No reward";
  let coins = 0;
  let eth = 0;
  {
    rewards !== null &&
      rewards.map((r) => {
        if (r.lineupId === lineup.LineupId) {
          textRewards = r.reward;
          rewardCards = r.reward_cards || [];
          if (r.coins > 0) {
            coins = r.coins;
          }
          if (r.eth > 0) {
            eth = r.eth.toFixed(4);
          }
        }
      });
  }
  let nextRewardText = "";
  if (rank > 1) {
    let nextReward = next_rewards[lineup.LineupId];
    nextRewardText = nextReward?.reward.reward;
  }
  let icon = comp.LogoUrl;
  let pointsLeftToThreshold = 0;
  if (lineup && comp && comp.RewardThreshold1 > 0 && rankingSystem !== "ranking") {
    if (props.pointsLeftToReachTargetThreshold !== undefined && props.pointsLeftToReachTargetThreshold !== null) {
      pointsLeftToThreshold = parseFloat(props.pointsLeftToReachTargetThreshold);
    } else {
      pointsLeftToThreshold = parseFloat(parseFloat(lineup.Score) - comp.RewardThreshold1);
    }
  }
  return players.length > 0 ? (
    <div className={"mx-auto text-center pt-1 space-y-1"} id={lineup.LineupId}>
      {live === undefined && (
        <div className={"flex flex-row pb-2 space-x-2 w-full relative"}>
          <img className={"w-10 h-10 self-center"} src={icon} />
          <div className={"flex flex-col w-6/12 self-end"}>
            <div>
              <p className="text-[#CCCCCC] flex justify-start space-x-2">
                <span className="text-base font-medium uppercase">{compTitle}</span>
                <span className="text-sm self-center">{compSubtitle}</span>
              </p>
            </div>
            <div className={"text-left flex flex-row space-x-2"}>
              <p>
                <span className={"text-xl font-semibold " + colorRank}>{ordinal_suffix_of(rank)}</span>
                <span data-tip={"Lineup score"} className={"text-xl font-semibold text-white"}>
                  {" "}
                  - {parseFloat(lineup.Score).toFixed(2)} pts
                </span>
              </p>
              {comp.RewardThreshold1 > 0 && rankingSystem !== "ranking" ? (
                <div className={"space-x-1 flex flex-row self-end"}>
                  <div className={"flex flex-row self-end"}>
                    <p
                      className={
                        pointsLeftToThreshold >= 0
                          ? "text-base font-semibold text-[#99E97C] self-end"
                          : "self-end text-base font-semibold text-warning-orange"
                      }
                      data-tip={"Points left to $ reward"}
                    >
                      {pointsLeftToThreshold.toFixed(2)}
                    </p>
                  </div>
                </div>
              ) : null}
              <div className={"flex self-center pt-1"}>
                <span data-tip={"Best to worst possible ranks"} className={"self-center text-[#CCCCCC] font-semibold text-sm"}>
                  {" "}
                  ({maxRanks})
                </span>
              </div>
            </div>
          </div>
          <div className={"absolute flex flex-row left-1/2 transform -translate-x-1/2 top-1/2 -translate-y-1/2 justify-center self-center"}>
            <div className={"w-8 h-8"}>
              <img className={"w-full h-full object-contain"} src={SDIcon} />
            </div>
          </div>
          <div className={"relative flex flex-row justify-end self-end w-6/12 space-x-4 space-y-2"}>
            <div className={"relative flex flex-col space-x-4 space-y-2 "}>
              <div className={" flex justify-center lg:justify-start self-end lg:pl-6 space-x-2 flex-row"}>
                {rewardCards[0] && rewardCards[0]?.scarcity !== "" && (
                  <div className={"flex flex-row space-x-2"}>
                    {rewardCards.map((reward, i) => {
                      if (reward.scarcity === "") {
                        return null;
                      }
                      return (
                        <div key={i} className={"px-2 py-0.5 rounded flex flex-row space-x-2"}>
                          <div className={"self-center"}>
                            <CardIcon size={"lg"} scarcity={reward.scarcity} />
                          </div>
                          <p className={"uppercase font-medium text-sm text-white text-center self-center"}>Tier {reward.tier}</p>
                        </div>
                      );
                    })}
                  </div>
                )}
                {eth > 0 && (
                  <>
                    {rewardCards[0] && rewardCards[0]?.scarcity !== "" && (
                      <div className={"border-l border-black-4D self-center h-4"}></div>
                    )}
                    <div className={"rounded text-sm text-white px-2 font-semibold flex flex-row space-x-1.5"}>
                      {shouldDisplayEthLogo && <img className={"self-center"} src={EthereumIconWhite} style={{ height: "16px" }} />}
                      <p className={"self-center"}>{rewardMoney}</p>
                    </div>
                  </>
                )}
                {coins > 0 && (
                  <>
                    {((rewardCards[0] && rewardCards[0]?.scarcity !== "") || eth > 0) && (
                      <div className={"border-l border-black-4D self-center h-4"}></div>
                    )}
                    <div className={"rounded text-sm text-white pl-2 font-semibold flex flex-row space-x-1.5"}>
                      <img className={"self-center"} src={Coin} style={{ height: "16px" }} />
                      <p className={"self-center"}>{coins}</p>
                    </div>
                  </>
                )}
                {textRewards === "No reward" && coins <= 0 && eth <= 0 && (
                  <>
                    <p className={"text-sm font-semibold text-[#CCCCCC]"}>No reward</p>
                  </>
                )}
              </div>
              <div className={"flex flex-row justify-end space-x-2 text-[#CCCCCC] text-sm font-medium"}>
                <div className={"self-center"}>
                  <p>
                    L15 Proj: {ordinal_suffix_of(lineup.ProjectedRank)}, {lineup.ProjectedScore.toFixed(2)} pts
                  </p>
                </div>
                <div className={"border-l border-black-4D self-center h-4"}></div>
                {rank > 1 && (
                  <span className={"text-[#CCCCCC] text-sm font-medium self-center"}>
                    {next_rewards[lineup.LineupId]?.gap.toFixed(2)} pts to {nextRewardTextFormatted}
                  </span>
                )}
                <div className={"w-4 self-center cursor-pointer"} onMouseEnter={() => setOverview(true)}>
                  <img className={"w-full object-contain"} src={InfoIconGrey} />
                </div>
              </div>
            </div>
            {props.user.sorareSlug === lineup.SorareUserSlug && (
              <div className={"flex flex-row space-x-2 justify-center"}>
                <div className={"flex flex-row space-x-2 justify-center cursor-pointer"} onClick={() => props.hideLineup(lineup.LineupId)}>
                  <div className={"flex flex-row self-center justify-center bg-[#F5F5F5] bg-opacity-10 cursor-pointer rounded-full"}>
                    <button
                      type="button"
                      {...props}
                      onClick={() => props.hideLineup(lineup.LineupId)}
                      className={`inline-flex items-center self-center rounded-full p-2 bg-[#F5F5F5] bg-opacity-10 cursor-pointer`}
                    >
                      <img src={VisibilityOff} />
                    </button>
                  </div>
                </div>
              </div>
            )}

            {overview === true && t1OrAbove(props.user.tier) && (
              <div
                className="absolute flex w-auto mt-8 p-4 self-end justify-end top-0 rounded-md shadow-lg bg-[#292929] focus:outline-none z-20"
                onMouseLeave={() => setOverview(false)}
              >
                <div className="py-1 space-y-2 text-left" role="none">
                  <p className={"text-base text-brand-dark font-semibold text-center"}>Leaderboard {completion}% complete</p>
                  <div className={"w-10/12 mx-auto flex flex-row justify-center text-white text-xxs font-semibold space-x-2"}>
                    <div className="bg-black-1f rounded-full h-1.5 w-full self-center">
                      <div className="bg-brand-dark h-1.5 rounded-full" style={{ width: completion + "%" }}></div>
                    </div>
                  </div>
                  <p className={"text-xs font-medium text-[#CCCCCC] text-center"}>
                    {comp.CardsLeft} cards left out of {comp.CardsLeft + comp.CardsPlayed + comp.CardsLive} total cards ({comp.CardsLive}{" "}
                    playing live)
                  </p>
                  <div className={"rounded-lg"}>
                    {rewards_overview.map((r, i) => {
                      let to = "to";
                      let color = "text-brand-dark";
                      let gap = r.gap;
                      if (r.gap >= 0) {
                        to = "from";
                        color = "text-[#99E97C]";
                      } else {
                        gap = r.gap * -1;
                      }
                      let bg = "bg-black-1f";
                      let startRange = r.reward.rank;
                      let endRange = 0;
                      if (i > 0) {
                        endRange = rewards_overview[i - 1].reward.rank + 1;
                      }
                      if (rank <= startRange && rank >= endRange) {
                        bg = "bg-black-33";
                      }
                      if (endRange > 0 && startRange === endRange) {
                        endRange = 0;
                      }
                      textRewards = r.reward.reward;
                      if (r.reward.reward === "No reward") {
                        endRange = 0;
                        startRange = startRange + "+";
                        if (rank >= r.reward.rank) {
                          bg = "bg-black-33";
                        }
                      }
                      let custom = "";
                      let border = "border-b border-black-4D";
                      if (i === 0) {
                        custom = "rounded-t-lg";
                      }
                      if (i === rewards_overview.length - 1) {
                        border = "";
                        custom = "rounded-b-lg";
                      }
                      const rewardMoney = findPriceForUserUnit(r.eth, r.fiat, props.user.preferredUnit, true, 3);

                      return (
                        <div key={i} className={"flex py-1.5 px-2 flex-row justify-between " + border + " " + custom + " " + bg}>
                          <div className={"self-center"}>
                            <p className={"text-xs font-semibold text-[#E6E6E6]"}>
                              {endRange > 0 && <span>{endRange}-</span>}
                              {startRange}
                            </p>
                          </div>
                          <div className={"w-16"}></div>
                          <div className={"flex flex-row space-x-2"}>
                            <p className={color + " font-medium text-xs self-center"}>
                              {gap.toFixed(2)} pts {to}
                            </p>
                            {r.eth > 0 && (
                              <div
                                className={
                                  "rounded text-sm bg-[#F5F5F5] bg-opacity-10 text-[#E6E6E6] text-white px-2 font-semibold flex flex-row space-x-1.5"
                                }
                              >
                                {shouldDisplayEthLogo && (
                                  <img className={"self-center"} src={EthereumIconWhite} style={{ height: "16px" }} />
                                )}
                                <p className={"self-center"}>{rewardMoney}</p>
                              </div>
                            )}
                            {r.reward_cards !== null &&
                              r.reward_cards.map((reward, i) => {
                                if (reward.scarcity === "") {
                                  return null;
                                }
                                return (
                                  <div
                                    key={i}
                                    className={"px-2 py-0.5 rounded bg-[#F5F5F5] bg-opacity-10 text-[#E6E6E6] flex flex-row space-x-2"}
                                  >
                                    <div className={"self-center"}>
                                      <CardIcon size={"lg"} scarcity={reward.scarcity} />
                                    </div>
                                    <p className={"font-semibold text-sm text-center self-center"}>Tier {reward.tier}</p>
                                  </div>
                                );
                              })}
                            {r.reward_cards === null && r.eth === 0 && (
                              <div className={"px-2 py-0.5 rounded bg-[#F5F5F5] bg-opacity-10 text-[#E6E6E6] flex flex-row space-x-2"}>
                                <p className={"font-semibold text-sm text-center self-center"}>No reward</p>
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}
            {overview === true && !t1OrAbove(props.user.tier) && (
              <div
                onMouseLeave={() => setOverview(false)}
                className="absolute w-80 mt-8 p-4 self-end justify-end top-0 rounded-md shadow-lg bg-focus bg-upgrade-bg-so5-live focus:outline-none z-20"
              >
                <div className="mt-36">
                  <p className="text-md text-black font-black text-center font-headers">Want more insights?</p>
                </div>
                <div className="mt-2 mb-4">
                  <p className="font-semibold text-center text-xs">
                    Become a Star member to unlock points to next reward and division completion
                  </p>
                </div>
                <div className="flex w-4/12 mx-auto items-center">
                  <a href={"/product"}>
                    <Button label={"Upgrade"} />
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      <div className={"grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-2 xl:gap-4 xl:gap-y-0 pb-2"}>
        {players.map((p) => {
          const captain = p.lineup_player.IsCaptain;
          const playerStats = stats[p.player.PlayerId];
          const { SO5Score, totalPoints, game, totalPointsNotFixed, power, floatSO5Score } = getNormalizedInfoOfLineupPlayer(
            p,
            lineup,
            games,
            playerStats,
            p.lineup_player.Power,
          );
          totalLineupPointsComputed += totalPointsNotFixed;
          return (
            <SO5LineupCard
              key={p.card.TokenId}
              game={game}
              totalPoints={totalPoints}
              player={p.player}
              power={power}
              SO5Score={SO5Score}
              floatSO5Score={floatSO5Score}
              stats={playerStats}
              card={p.card}
              captain={captain}
            />
          );
        })}
      </div>
    </div>
  ) : (
    <Text>No player in this lineup.</Text>
  );
}

export default withUser(SO5LineupCards);
