import React, { useEffect, useState } from "react";
import { withUser } from "../../userContext";
import SO5Game from "./SO5Game";
import { ReactTooltip } from "../util/tooltip.js";
import GLOBAL_MARGIN from "../util/margin";
import { isToday, isTomorrow, isYesterday } from "date-fns";
import format from "date-fns/format";
import IconFiltersGrey from "../../img/filterIconGrey.svg";
import IconFiltersBrand from "../../img/filterIconBrand.svg";
import BoxWithToggle from "../util/boxWithToggle";
import SelectSearch, { fuzzySearch } from "react-select-search";
import {
  challenger_europe_leagues_objects,
  champion_america_leagues_objects,
  champion_asia_leagues_objects,
  champion_europe_leagues_objects,
  leagues_no_div_objects,
  second_division_europe_objects,
} from "../util/leagues";
import { errorCatcher } from "../util/errors";
import { t2OrAbove } from "../util/handleSubscriptionTier";
import UpgradeLimitBox from "../util/upgradeLimitBox";
import { sorareFootball } from "../util/sports";

function SO5Games(props) {
  const [games, setGames] = useState([]);
  const [next, setNext] = useState(false);
  const [liveGames, setLiveGames] = useState(false);
  const [playersMap, setPlayersMap] = useState(null);
  const [linedupPlayers, setLinedupPlayers] = useState(false);
  const [activeDate, setActiveDate] = useState(new Date());
  const [showFilters, setShowFilters] = useState(true);
  const [showScores, setShowScores] = useState(true);
  const [displayPref, setDisplayPref] = useState("none");
  const [divisionPref, setDivisionPref] = useState([]);
  const [gamesByCompetition, setGamesByCompetition] = useState(new Map());
  const [gamesByRegion, setGamesByRegion] = useState(new Map());
  const [galleryPlayers, setGalleryPlayers] = useState(false);
  const [hideCommonPlayers, setHideCommonPlayers] = useState(props.user.hideCommonCards);

  const sport = props.sport;

  useEffect(() => {
    const interval = setInterval(() => {
      getGames();
    }, 300000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setHideCommonPlayers(props.user.hideCommonCards);
  }, [props.user]);

  useEffect(() => {
    getGames();
    getPlayersForUser();
  }, [props.gwNumber]);

  useEffect(() => {
    if (props.gw !== null) {
      let now = new Date();
      let start = new Date(props.gw.StartDate);
      let end = new Date(props.gw.EndDate);
      if (start < now && end > now) {
        changeDate(now);
      } else {
        changeDate(new Date(props.gw.StartDate));
      }
    }
  }, [props.gw]);

  useEffect(() => {
    groupGames(games);
  }, [activeDate, displayPref, divisionPref, liveGames, linedupPlayers, galleryPlayers]);

  const getGames = () => {
    if (props.gwNumber !== 0) {
      props
        .fetch("/apiv2/games/gameweek/" + props.gwNumber + "?sport=" + sport)
        .then((response) => response.json())
        .then((res) => {
          setGames(res);
          groupGames(res);
        })
        .catch(errorCatcher());
    }
  };

  const groupGames = (gms) => {
    let gmsByComp = new Map();
    let gmsByRegion = new Map();
    gmsByRegion.set("Champion Europe", []);
    gmsByRegion.set("Challenger Europe", []);
    gmsByRegion.set("Second Division Europe", []);
    gmsByRegion.set("America", []);
    gmsByRegion.set("Asia", []);
    gmsByRegion.set("All-Star", []);
    gms.map((g) => {
      if (displayGame(g) === true) {
        let comp = g.game.CompDisplayName;
        let entryComp = gmsByComp.get(comp);
        if (!entryComp) {
          entryComp = [];
        }
        entryComp.push(g);
        gmsByComp.set(comp, entryComp);
        let entryRegion;
        if (comp === "CAF Champions League") {
          entryRegion = gmsByRegion.get("All-Star");
          entryRegion.push(g);
          gmsByRegion.set("All-Star", entryRegion);
        } else if (comp === "CONMEBOL Libertadores") {
          entryRegion = gmsByRegion.get("America");
          entryRegion.push(g);
          gmsByRegion.set("America", entryRegion);
        } else if (comp === "UEFA Champions League") {
          entryRegion = gmsByRegion.get("Champion Europe");
          entryRegion.push(g);
          gmsByRegion.set("Champion Europe", entryRegion);
        }
        champion_europe_leagues_objects.map((ce) => {
          if (ce.name === comp) {
            if (comp === "Premier League") {
              if (g.home_team_league === "Russian Premier League") {
                entryRegion = gmsByRegion.get("Challenger Europe");
                entryRegion.push(g);
                gmsByRegion.set("Challenger Europe", entryRegion);
              } else {
                entryRegion = gmsByRegion.get("Champion Europe");
                entryRegion.push(g);
                gmsByRegion.set("Champion Europe", entryRegion);
              }
            } else {
              entryRegion = gmsByRegion.get("Champion Europe");
              entryRegion.push(g);
              gmsByRegion.set("Champion Europe", entryRegion);
            }
          }
        });
        challenger_europe_leagues_objects.concat([{ value: "Süper Lig", name: "Süper Lig" }]).map((ce) => {
          if (ce.name === comp) {
            entryRegion = gmsByRegion.get("Challenger Europe");
            entryRegion.push(g);
            gmsByRegion.set("Challenger Europe", entryRegion);
          }
        });
        second_division_europe_objects.map((ce) => {
          if (ce.name === comp) {
            entryRegion = gmsByRegion.get("Second Division Europe");
            entryRegion.push(g);
            gmsByRegion.set("Second Division Europe", entryRegion);
          }
        });
        champion_america_leagues_objects
          .concat([
            {
              value: "Primera División del Chile",
              name: "Primera División del Chile",
            },
            { value: "MLS", name: "MLS" },
          ])
          .map((ce) => {
            if (ce.name === comp) {
              entryRegion = gmsByRegion.get("America");
              entryRegion.push(g);
              gmsByRegion.set("America", entryRegion);
            }
          });
        champion_asia_leagues_objects.map((ce) => {
          if (ce.name === comp) {
            entryRegion = gmsByRegion.get("Asia");
            entryRegion.push(g);
            gmsByRegion.set("Asia", entryRegion);
          }
        });
      }
    });
    setGamesByCompetition(gmsByComp);
    setGamesByRegion(gmsByRegion);
  };

  const getPlayersForUser = (hc) => {
    if (hc === undefined) {
      hc = hideCommonPlayers;
    }
    console.log(props.user.sorareSlug, props.gwNumber);
    if (props.user.sorareSlug !== undefined && props.user.sorareSlug !== null && props.user.sorareSlug !== "") {
      if (props.gwNumber !== 0) {
        props
          .fetch("/apiv2/games/playersInGames/" + props.gwNumber + "/" + props.user.sorareSlug + "?sport=" + sport + "&showCommons=" + !hc)
          .then((response) => response.json())
          .then((res) => {
            setPlayersMap(res);
          })
          .catch(errorCatcher());
      }
    }
  };

  const displayGame = (g) => {
    let display = true;
    if (linedupPlayers && !galleryPlayers) {
      if (playersMap !== null) {
        let hasOnePlayerLinedUp = false;
        if (playersMap[g.game.GameId] !== undefined) {
          playersMap[g.game.GameId].map((p) => {
            if (p.off_lineups === false) {
              hasOnePlayerLinedUp = true;
            }
          });
          display = hasOnePlayerLinedUp;
          if (display === false) {
            return display;
          }
        } else {
          display = false;
          return display;
        }
      }
    }
    if (galleryPlayers) {
      if (playersMap !== null) {
        if (playersMap[g.game.GameId] === null || playersMap[g.game.GameId] === undefined) {
          display = false;
          return display;
        }
      }
    }
    if (
      g.game.Status !== "playing" &&
      g.game.Status !== "scheduled" &&
      g.game.Status !== "halftime" &&
      g.game.Status !== "inprogress" &&
      g.game.Status !== "created" &&
      liveGames
    ) {
      display = false;
      return display;
    }
    let gameTimeDate = new Date(g.game.GameTime);
    if (gameTimeDate.getDay() !== activeDate.getDay()) {
      display = false;
      return display;
    }
    if (divisionPref.length > 0) {
      if (divisionPref.indexOf(g.game.CompDisplayName) < 0) {
        display = false;
      }
    }
    return display;
  };

  const isGameInArray = (g, arr) => {
    return arr.map((a) => {
      console.log(a.game.GameId, g.Game.GameId);
      if (a.game.GameId === g.game.GameId) {
        return true;
      }
    });
  };

  let startDate = new Date(0);
  let endDate = new Date(0);
  let dates = [];

  if (props.gw !== null) {
    startDate = new Date(props.gw.StartDate);
    endDate = new Date(props.gw.EndDate);
    endDate.setHours(endDate.getHours() + 1);
    for (let start = startDate; start <= endDate; start.setHours(start.getHours() + 1)) {
      let newDate = new Date(start);
      if (dates.length > 0) {
        if (dates[dates.length - 1].getDay() !== newDate.getDay()) {
          dates.push(newDate);
        }
      } else {
        dates.push(newDate);
      }
    }
  }

  const inactiveDateClass = "text-sm font-semibold text-textGrey3 py-4 border-b-2 border-white cursor-pointer";
  const activeDateClass = "text-sm font-semibold text-brand border-b-2 border-brand py-4";

  const filterTitleStyle = "text-lg font-headers font-bold text-brand";
  const toggleBoxStyle = "shadow py-2 rounded";

  const changeDate = (d) => {
    setActiveDate(d);
  };

  return (
    <div className={GLOBAL_MARGIN}>
      <div className={"space-y-8"}>
        {!t2OrAbove(props.user.tier) && (
          <div>
            <UpgradeLimitBox
              className={"bg-white bg-upgrade-bg-gw-stats bg-cover h-upgrade-banner-s w-full"}
              title={"Want more insights?"}
              description={"Become a Star member to unlock Matchups and more."}
            />
          </div>
        )}
        <div className={"bg-white rounded-lg"}>
          <div className={"flex flex-row justify-between px-4"}>
            <div className={"flex flex-row space-x-6"}>
              {dates.map((d) => {
                let final = format(d, "EEE d MMM");
                if (isToday(d)) {
                  final = "Today";
                } else if (isTomorrow(d)) {
                  final = "Tomorrow";
                } else if (isYesterday(d)) {
                  final = "Yesterday";
                }
                return (
                  <p
                    key={final}
                    onClick={() => changeDate(d)}
                    className={d.getDay() === activeDate.getDay() ? activeDateClass : inactiveDateClass}
                  >
                    {final}
                  </p>
                );
              })}
            </div>
            <div className={"self-center cursor-pointer"} onClick={() => setShowFilters(!showFilters)}>
              <div className={"w-10 h-10 self-center"}>
                <img className={"w-full h-full object-contain"} src={showFilters ? IconFiltersBrand : IconFiltersGrey} />
              </div>
            </div>
          </div>
          {showFilters && (
            <div className={"border-t border-grey-e5 flex flex-row rounded-b-lg"}>
              <div className={"w-4/12 border-r border-grey-e5 p-4 flex-col space-y-4 flex"}>
                <p className={filterTitleStyle}>Quick filters</p>
                <div className={"flex flex-col space-y-2"}>
                  <div className={toggleBoxStyle}>
                    <BoxWithToggle isActive={liveGames} setActive={() => setLiveGames(!liveGames)} label={"Live & upcoming games only"} />
                  </div>
                  <div className={toggleBoxStyle}>
                    <BoxWithToggle
                      isActive={hideCommonPlayers}
                      setActive={() => {
                        getPlayersForUser(!hideCommonPlayers);
                        setHideCommonPlayers(!hideCommonPlayers);
                      }}
                      label={"Hide SO5 eligible common players"}
                    />
                  </div>
                  <div className={toggleBoxStyle}>
                    <BoxWithToggle
                      isActive={linedupPlayers}
                      setActive={() => setLinedupPlayers(!linedupPlayers)}
                      label={"Games with lined up players"}
                    />
                  </div>
                  <div className={toggleBoxStyle}>
                    <BoxWithToggle
                      isActive={galleryPlayers}
                      setActive={() => setGalleryPlayers(!galleryPlayers)}
                      label={"Games with gallery players"}
                    />
                  </div>
                  {/*<div className={toggleBoxStyle}>
                                    <BoxWithToggle label={"Favorite games only"}/>
                                </div>*/}
                  <div className={toggleBoxStyle}>
                    <BoxWithToggle
                      isActive={showScores}
                      setActive={() => setShowScores(!showScores)}
                      label={"Display players performances"}
                    />
                  </div>
                </div>
              </div>
              {sport === sorareFootball && (
                <div className={"w-8/12 p-4 space-y-4"}>
                  <div className={"space-y-2 w-6/12"}>
                    <p className={filterTitleStyle}>Leagues & competitions</p>
                    <SelectSearch
                      value={divisionPref}
                      placeholder={"Filter by football competition"}
                      options={leagues_no_div_objects}
                      closeOnSelect={false}
                      multiple={true}
                      onChange={(v) => setDivisionPref(v)}
                      printOptions={"on-focus"}
                      filterOptions={fuzzySearch}
                    />
                  </div>
                  <div className={"space-y-2 w-6/12"}>
                    <p className={filterTitleStyle}>Display preference</p>
                    <SelectSearch
                      value={displayPref}
                      options={[
                        { value: "none", name: "Don't group" },
                        {
                          value: "country",
                          name: "Group by football competition",
                        },
                        { value: "so5", name: "Group by SO5 region" },
                      ]}
                      closeOnSelect={true}
                      onChange={(v) => setDisplayPref(v)}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        <div>
          {displayPref === "none" && (
            <div>
              {games !== null &&
                games.map((g, i) => {
                  if (displayGame(g)) {
                    return (
                      <div key={g.game.GameId}>
                        <SO5Game sport={sport} displayScores={showScores} game={g} map={playersMap} />
                      </div>
                    );
                  }
                })}
            </div>
          )}
          {displayPref === "country" && (
            <div>
              {gamesByCompetition !== null &&
                [...gamesByCompetition].map((entry, i) => {
                  let rounded = "";
                  if (i === 0) rounded = "rounded-t-lg";
                  let gm = entry[1];
                  let label = entry[0];
                  if (gm.length > 0) {
                    return (
                      <div key={label}>
                        <div className={rounded + " px-6 py-3 "} style={{ background: "rgba(255, 255, 255, .7) " }}>
                          <p className={"uppercase text-xs font-semibold opacity-100"}>{label}</p>
                        </div>
                        <div>
                          {gm.map((g, i) => {
                            return (
                              <div key={g.game.GameId}>
                                <SO5Game displayScores={showScores} last={i === gm.length - 1} game={g} sport={sport} map={playersMap} />
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  } else return null;
                })}
            </div>
          )}
          {displayPref === "so5" && (
            <div>
              {gamesByRegion !== null &&
                [...gamesByRegion].map((entry, i) => {
                  let rounded = "";
                  if (i === 0) rounded = "rounded-t-lg";
                  let gm = entry[1];
                  let label = entry[0];
                  if (gm.length > 0) {
                    return (
                      <div key={label}>
                        <div className={rounded + " px-6 py-3 "} style={{ background: "rgba(255, 255, 255, .7) " }}>
                          <p className={"uppercase text-xs font-semibold opacity-100"}>{label}</p>
                        </div>
                        <div>
                          {gm.map((g, i) => {
                            return (
                              <div key={g.game.GameId}>
                                <SO5Game displayScores={showScores} sport={sport} last={i === gm.length - 1} game={g} map={playersMap} />
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  } else return null;
                })}
            </div>
          )}
        </div>
      </div>
      <ReactTooltip />
    </div>
  );
}

export default withUser(SO5Games);
