import React from "react";
import { Box, Text } from "grommet";
import UnknownClub from "../../img/unknown_club.png";
import { sorareBaseball, sorareBasketball } from "../util/sports";
import NBAProjectedScore from "../util/nbaProjectionScore";
import OppScore from "../decision/oppScore";
import { isFree, t1OrAbove } from "../util/handleSubscriptionTier";
import GreyLock from "../util/greyLock";

function GameOnCard(props) {
  const gwGames = props.gwGames;
  const p = props.player;
  const sport = props.sport;
  const position = props.position;

  if (sport === sorareBaseball) {
    if (gwGames !== null && gwGames !== undefined) {
      const nbOfGames = gwGames[p.TeamId]?.nb_of_games;
      const logos = gwGames[p.TeamId]?.opponent_logos;
      let probable = false;
      gwGames[p.TeamId]?.probable_pitchers.map((pit) => {
        if (pit === p.PlayerId) probable = true;
      });
      if (nbOfGames > 0) {
        return (
          <div className={"flex flex-col items-center space-y-1"}>
            {p.Position !== "SP" && (
              <p data-tip={"Number of games next gameweek"} className={"text-sm font-semibold"}>
                <a href={"/player/" + p.PlayerId + "/sections/so5-scores"}>
                  {nbOfGames} <span className={"text-xs"}>GMS</span>
                </a>
              </p>
            )}
            {p.Position === "SP" && !probable && (
              <p data-tip={"No projected start next gameweek"} className={"text-sm font-semibold"}>
                <a href={"/player/" + p.PlayerId + "/sections/so5-scores"}>NS</a>
              </p>
            )}
            {p.Position === "SP" && probable && (
              <p data-tip={"Projected start next gameweek"} className={"text-sm font-semibold"}>
                <a href={"/player/" + p.PlayerId + "/sections/so5-scores"}>PP</a>
              </p>
            )}
            <div data-tip={"Opponents next gameweek"} className={"flex flex-row space-x-1 justify-center"}>
              {logos !== null &&
                logos.map((logo, index) => {
                  return <img className={"w-4"} src={logo} />;
                })}
            </div>
          </div>
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  } else if (sport === sorareBasketball) {
    if (gwGames !== null && gwGames !== undefined) {
      const nbOfGames = gwGames[p.TeamId]?.nb_of_games;
      const logos = gwGames[p.TeamId]?.opponent_logos;
      let projectedScore = 0;
      if (gwGames[p.TeamId]?.projected_scores !== null) {
        gwGames[p.TeamId]?.projected_scores.map((pit) => {
          if (pit.playerId === p.PlayerId) projectedScore = pit.score;
        });
      }
      if (nbOfGames > 0) {
        return (
          <div className={"flex flex-col items-center space-y-1"}>
            <div data-tip={"Opponents next gameweek"} className={"flex flex-row space-x-1 justify-center"}>
              {logos !== null &&
                logos.map((logo, index) => {
                  return <img className={"w-4"} src={logo} />;
                })}
            </div>
            <div data-tip={"Best projected score next gameweek"}>
              <NBAProjectedScore size={"w-6 h-6"} font={"text-xs"} score={projectedScore} />
            </div>
          </div>
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  } else {
    let fontSize = props.fontSize || "xsmall";
    let displayGame = false;
    let dataTip = "";
    let gwDisplay = "GW #" + props.gwNumber + ": ";
    if (props.gwNumber === undefined || props.gwNumber === 0) {
      gwDisplay = "Next GW: ";
    }
    let homeLogo = UnknownClub;
    let awayLogo = UnknownClub;
    let oppScore = 0;
    if (gwGames !== undefined && gwGames !== null) {
      let playerGame = gwGames[p.TeamId];
      if (playerGame === undefined && playerGame?.game?.GameId !== "") {
        playerGame = gwGames[p.NationalTeam];
      }
      if (playerGame !== undefined && playerGame?.game?.GameId !== "") {
        displayGame = true;
        dataTip = gwDisplay + playerGame.home_team + " - " + playerGame.away_team;
        if (playerGame?.pick_scores !== null) {
          oppScore = playerGame?.pick_scores[p.PlayerId]?.oppScore;
        }
      }

      if (oppScore === 0) {
        oppScore = playerGame?.opp_scores?.[p.MainPosition]?.Average;
      }

      if (playerGame !== undefined && playerGame?.game?.GameId !== "") {
        if (playerGame.home_team_logo !== "") homeLogo = playerGame.home_team_logo;
        if (playerGame.away_team_logo !== "") awayLogo = playerGame.away_team_logo;
      }
    }
    return displayGame ? (
      <div className={"flex flex-col items-center space-y-1"}>
        <div className={"flex flex-row space-x-1"} data-tip={dataTip}>
          <div className={"w-5 h-5 self-center"}>
            <img className={"w-full h-full object-contain"} src={homeLogo} />
          </div>
          <span className={" self-center text-sm font-semibold"}>-</span>
          <div className={"w-5 h-5 self-center"}>
            <img className={"w-full h-full object-contain"} src={awayLogo} />
          </div>
        </div>
        {t1OrAbove(props.tier) && (
          <div data-tip={"Game opponent score"}>
            <OppScore size={"w-6 h-6"} fontSize={"text-xs font-semibold"} score={oppScore} />
          </div>
        )}
        {isFree(props.tier) && <GreyLock />}
      </div>
    ) : (
      <Box align={"center"} direction={"row"} justify={"center"}>
        <Text data-tip={"No game scheduled for next gameweek"} textAlign={"center"} size={fontSize} weight={400}>
          No game
        </Text>
      </Box>
    );
  }
}

export default GameOnCard;
