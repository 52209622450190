import React, { useEffect, useState } from "react";
import { errorCatcher } from "../util/errors";
import CardTag from "../cards/cardTag";
import { withUser } from "../../userContext";
import Check from "../../img/check_small.svg";
import Bolt from "../../img/bolt.svg";
import PowerUp from "../../img/power-up.svg";
import { formatPrice } from "../util/formatMoney";
import { ChevronRightIcon } from "@heroicons/react/solid";

function CollectionScoreModalBox(props) {
  return (
    <div className={props.checked ? "space-y-1" : "space-y-1 opacity-60"}>
      <p className={"text-[#CCCCCC] text-sm font-semibold"}>{props.label}</p>
      <div className={"flex flex-rows space-x-1 text-white text-sm"}>
        <p className={props.checked ? "text-white font-semibold" : "font-semibold"}>{props.score}</p>
        {props.checked && <img src={Check} />}
        {props.heldDiff > 0 && <p className={"self-center pl-1 text-green-dark font-semibold text-xs"}>in {props.heldDiff} days</p>}
      </div>
    </div>
  );
}

function CollectionScoreModal(props) {
  let details = props.details;
  return (
    <div className={"rounded-lg text-[#333333] bg-[#333333] p-4 z-10"}>
      <div className={"grid grid-cols-3 gap-3 border-b border-[#B3B3B3] pb-4"}>
        <CollectionScoreModalBox score={10} label={"Owned"} checked={details.owner > 0} />
        <CollectionScoreModalBox score={10} label={"Held 90 days"} checked={details.holding > 0} heldDiff={props.heldDiff} />
        <CollectionScoreModalBox score={20} label={"Only owner"} checked={details.firstOwner > 0} />
        <CollectionScoreModalBox score={20} label={"Special edition"} checked={details.specialEdition > 0} />
        <CollectionScoreModalBox score={30} label={"Serial #1"} checked={details.firstSerialNumber > 0} />
        <CollectionScoreModalBox score={30} label={"Jersey mint"} checked={details.shirtMatchingSerialNumber > 0} />
      </div>
      <div className={"flex flex-row justify-between pt-4"}>
        <p className={"text-xs font-semibold text-[#CCCCCC]"}>Total</p>
        <div className={"flex flex-row text-sm font-semibold space-x-0.5"}>
          <span className={"text-white"}>{details.total}</span>
          <img src={Bolt} />
          <span className={"text-[#CCCCCC] opacity-50"}>/150</span>
        </div>
      </div>
    </div>
  );
}

function CollectionCardModal(props) {
  const c = props.card;

  let barPct = (c.cardCollectionMetadata.total * 100) / props.collection.totalScore;
  let collectionBonus = props.collection.totalScore;
  let showNewBonus = false;
  let newBonus = 0;
  let increasePct = 0;
  let impact = 0;
  if (!props.owned) {
    let highest = props.highest;
    impact = c.cardCollectionMetadata.total - highest - c.cardCollectionMetadata.firstOwner - c.cardCollectionMetadata.holding;
    if (impact < 0) {
      impact = 0;
    }
    let newTotal = collectionBonus + impact;
    if (newTotal >= 750) {
      newBonus = 5;
    } else if (newTotal >= 500) {
      newBonus = 4;
    } else if (newTotal >= 250) {
      newBonus = 3;
    } else if (newTotal >= 100) {
      newBonus = 2;
    } else if (newTotal >= 35) {
      newBonus = 1;
    }
    if (newBonus > props.collection.bonus) {
      showNewBonus = true;
    }
    barPct = (collectionBonus * 100) / 750;
    increasePct = (newTotal * 100) / 750;
  }
  let price = "";
  if (c.offer.OfferId !== "") {
    if (props.user.preferredUnit === "eth") {
      price = formatPrice(c.offer.ReceiveAmountInWei, "eth");
    } else {
      price = formatPrice(c.offer.ReceiveAmountInWei * c.ethPriceInfo[props.user.preferredUnit], props.user.preferredUnit);
    }
  }
  let heldDiff;
  if (c.cardCollectionMetadata.heldSince !== null) {
    heldDiff = 90 - Math.round((new Date() - new Date(c.cardCollectionMetadata.heldSince)) / (1000 * 60 * 60 * 24));
  }
  if (barPct > 100) {
    barPct = 100;
  }
  if (increasePct > 100) {
    increasePct = 100;
  }
  return (
    <div className={"bg-[#1A1A1A] p-4 space-x-4 rounded-lg flex flex-row"}>
      <div className={"flex flex-col space-y-4 w-4/12"}>
        <a href={"/card/" + c.card.TokenId} target={"_blank"} rel="noreferrer">
          <img src={c.card.PictureUrl} />
        </a>
        <div>
          <CardTag tag={c.cardTag} rt={c.rt} bmp={c.bmp} onSaleAndPrice={c.offer.OfferId !== ""} price={price} />
        </div>
      </div>
      <div className={"w-8/12 flex flex-col space-y-4"}>
        <div className={"w-full"}>
          <CollectionScoreModal
            details={c.cardCollectionMetadata}
            heldDiff={c.cardCollectionMetadata.heldSince !== null && c.cardCollectionMetadata.holding === 0 && heldDiff}
          />
        </div>
        <div>
          <div className={"flex flex-col bg-[#333333] p-6 rounded-lg space-y-2"}>
            <p className={"text-xs font-semibold text-[#CCCCCC]"}>Collection score</p>
            {props.owned && (
              <div className={"flex flex-row justify-between"}>
                <p className={"text-white text-base"}>
                  {c.cardCollectionMetadata.total} pts / {collectionBonus} pts
                </p>
                <p className={"text-white text-base text-[#90C9FE] font-semibold"}>
                  {((c.cardCollectionMetadata.total * 100) / collectionBonus).toFixed(0)}%
                </p>
              </div>
            )}
            {props.owned && (
              <div className={"bg-[#D9D9D9] rounded-full h-1.5 w-full self-center"}>
                <div className={"bg-super-rare h-1.5 rounded-full"} style={{ width: barPct + "%" }}></div>
              </div>
            )}
            {!props.owned && (
              <div className={"flex flex-col space-y-2"}>
                <div className={"flex flex-row justify-between"}>
                  <p className={"text-white text-base"}>
                    {collectionBonus} pts {`->`} <span className={"font-semibold"}>{collectionBonus + impact} pts</span>
                  </p>
                  <div className={"flex flex-row space-x-2"}>
                    {impact > 0 && <p className={"text-[#50BF84] text-base font-semibold"}>+{impact} pts</p>}
                    {showNewBonus && (
                      <div className={"flex flex-row space-x-2"}>
                        <div className={"border border-[#666666]"}></div>
                        <img src={PowerUp} />
                        <p className={"text-[#50BF84] text-base font-semibold"}>{newBonus}%</p>
                      </div>
                    )}
                  </div>
                </div>
                <div className={"bg-[#D9D9D9] rounded-full h-1.5 w-full self-center relative"}>
                  <div className={"bg-super-rare h-1.5 rounded-full absolute bottom-0 z-10"} style={{ width: barPct + "%" }}></div>
                  <div className={"bg-[#50BF84] h-1.5 rounded-full absolute bottom-0 z-0"} style={{ width: increasePct + "%" }}></div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div>
          <div className={"flex flex-row bg-[#333333] p-4 rounded-lg space-x-4"}>
            {!props.owned && (
              <div className={"flex flex-col space-y-1 w-6/12"}>
                <p className={"text-xxs font-semibold text-[#CCCCCC]"}>Owned by</p>
                <p className={"text-xs font-semibold text-white"}>{c.sorareUser.Nickname}</p>
              </div>
            )}
            {!props.owned && <div className={"border border-[#B3B3B3]"}></div>}
            <a
              href={"/card/" + c.card.TokenId}
              target={"_blank"}
              className={"flex flex-row justify-center space-x-2 self-center w-full"}
              rel="noreferrer"
            >
              <p className={"text-white text-base font-semibold"}>View card details</p>
              <ChevronRightIcon className={"w-6 h-6"} color={"white"} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

function ManagerCollectionCardModal(props) {
  const [active, setActive] = useState("");
  const [cards, setCollectionCards] = useState([]);

  const owned = cards?.owned;
  const notOwned = cards?.notOwned;
  const highest = cards?.highestOwnedScore;

  const p = props.placeholder;

  const getCollectionCards = () => {
    props
      .fetch(
        "/apiv2/manager/collectionCards?manager=" +
          props.manager +
          "&team=" +
          p.CardTeamId +
          "&scarcity=" +
          p.Scarcity +
          "&season=" +
          p.Season +
          "&playerId=" +
          p.PlayerId,
      )
      .then((response) => response.json())
      .then((res) => {
        setCollectionCards(res);
      })
      .catch(errorCatcher(() => {}));
  };

  useEffect(() => {
    getCollectionCards();
    if (props.owned) {
      setActive("owned");
    } else {
      setActive("notOwned");
    }
  }, []);

  const activeClass = "text-base font-semibold text-white pb-2 border-b-4 border-brand";
  const inactiveClass = "text-base font-semibold text-[#CCCCCC] pb-3";

  return (
    <div className={"bg-black-4D font-sans flex flex-col space-y-4"}>
      <p className={"text-white font-headers text-xl font-semibold"}>Collection cards</p>
      <div className={"flex flex-row space-x-8 border-[#666666] border-b"}>
        <button className={active === "owned" ? activeClass : inactiveClass} onClick={() => setActive("owned")}>
          Owned
        </button>
        <button className={active === "notOwned" ? activeClass : inactiveClass} onClick={() => setActive("notOwned")}>
          Not owned
        </button>
      </div>
      {active === "owned" && (
        <div className={"grid grid-cols-2 gap-8"}>
          {owned &&
            active === "owned" &&
            owned.map((c) => {
              return <CollectionCardModal user={props.user} highest={highest} collection={props.collection} card={c} owned={true} />;
            })}
        </div>
      )}
      {active === "notOwned" && (
        <div className={"grid grid-cols-2 gap-8"}>
          {notOwned &&
            active === "notOwned" &&
            notOwned.map((c) => {
              return <CollectionCardModal user={props.user} highest={highest} collection={props.collection} card={c} owned={false} />;
            })}
        </div>
      )}
    </div>
  );
}

export default withUser(ManagerCollectionCardModal);
