import React, { Fragment, useEffect, useState } from "react";
import { withUser } from "../../userContext";
import countries from "i18n-iso-countries";
import PlayerAvailability from "./playerAvailability";
import PlayerPriceGraph from "./chartJSPlayerPriceGraph";
import EmptyPlayer from "../../img/icon-empty-player.svg";
import EmptyClub from "../../img/icon-empty-club.svg";
import GLOBAL_MARGIN from "../util/margin";
import ordinal_suffix_of from "../util/ordinalNumber";
import { ReactTooltip } from "../util/tooltip.js";
import SimilarPlayers from "./similarPlayers";
import PlayerCombo from "./playerCombo";
import format from "date-fns/format";
import PlayerCards from "./playerCards";
import SDLoading from "../util/SDLoading";
import PlayerScoresTab from "./playerScoreTab";
import NotificationBubble from "../util/notificationBubble";
import FavoriteBubble from "../util/favoriteBubble";
import Jersey from "../../img/jersey-low-opacity.svg";
import HomeIcon from "../../img/homeIcon.svg";
import AwayIcon from "../../img/awayIcon.svg";
import AverageAndGames from "../util/averageAndGames";
import MainPosition from "../../img/main-position-dot.svg";
import SecondaryPosition from "../../img/secondary-position-dot.svg";
import PlayerSalesTab from "./playerSalesTab";
import PlayerTiers from "./playerTiers";
import MultiplePlayerIcon from "../../img/multiple_players_icon.svg";
import { convertToBaseballAverage } from "../util/baseballAverage";
import { isSportFree, sorareBaseball, sorareBasketball, sorareFootball } from "../util/sports";
import { Link } from "react-router-dom";
import PlayerBlockedVideo from "./playerBlockedVideo";
import { isFree, t1OrBelow, t2OrAbove } from "../util/handleSubscriptionTier";
import PlayerNews from "./playerNews";
import SorareScoreAverage from "../decision/sorareScoreAverage";
import { ReactComponent as IconInfoHelp } from "../../img/icons-information-help.svg";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { CostLegendBox, CostLegendExampleInner, LXLegendBox } from "../decision/footballLineupTableLegend";
import { endOnboarding, getFirstEnabledOnboardingForPage, nextOnboarding, Onboarding } from "../onboarding/onboardingContext";
import { PlayerPriceSplit } from "./playerPriceSplit";
import clsx from "clsx";
import { getPlayerCountryInfo } from "../util/formatPlayerCountry";
import { PlayerCommunityProjection, PlayerCommunityProjectionWithHover, predicitionsInfo } from "../util/communityPrediction";
import { CardsOwnedForPlayer } from "./cardsOwnedForPlayer";
import { errorCatcher } from "../util/errors";
import { isTeamEmpty } from "../util/teams";
import { PlayerPriceDetails } from "./playerPriceDetails";
import UpgradeLimitBox from "../util/upgradeLimitBox";
import { PriceAlertPlayerPageButton } from "../priceAlerts/priceAlertModal";

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

function BaseballStatBox(props) {
  const title = props.title;
  const stat = props.stat;

  return (
    <div className={"flex flex-col items-center space-y-1 self-center"}>
      <p className={"font-semibold text-sm text-textGrey2"}>{title}</p>
      <p className={"font-bold text-lg"}>{stat}</p>
    </div>
  );
}

function BasketballStatBox(props) {
  const title = props.title;
  const stat = props.stat;

  let precision = props.precision || 1;
  return (
    <div className={"flex flex-col items-center space-y-1 self-center"}>
      <p className={"font-semibold text-xs text-textGrey2"}>{title}</p>
      <p className={"font-bold text-base"}>{stat.toFixed(precision)}</p>
    </div>
  );
}

function BasketballStatFullBox(props) {
  const [which, setWhich] = useState("L10");

  const stats = props.stats;
  if (stats === undefined) {
    return null;
  } else {
    const activeClass = "bg-brand-pastel text-center text-brand text-xs font-bold py-2 self-center uppercase";
    const inactiveClass = "bg-grey-f2 text-center cursor-pointer text-xs font-bold py-2 self-center text-textGrey4 uppercase";

    let usedStats;
    if (which === "L10") {
      usedStats = stats[0];
    } else if (which === "L40") {
      usedStats = stats[1];
    } else {
      usedStats = stats[2];
    }
    return (
      <div className={"flex flex-col h-32 rounded-lg w-full md:mr-4"}>
        <div className={"self-start grid grid-cols-3 w-full"}>
          <div
            onClick={() => setWhich("L10")}
            className={which === "L10" ? "rounded-tl-lg " + activeClass : "rounded-tl-lg " + inactiveClass}
          >
            <p>L10</p>
          </div>
          <div
            onClick={() => setWhich("L40")}
            className={
              which === "L40" ? "border-l border-r border-grey-e5 " + activeClass : "border-l border-r border-grey-e5 " + inactiveClass
            }
          >
            <p>L40</p>
          </div>
          <div
            onClick={() => setWhich("season")}
            className={which === "season" ? "rounded-tr-lg " + activeClass : "rounded-tr-lg " + inactiveClass}
          >
            <p>Season</p>
          </div>
        </div>
        <div className={"flex flex-row justify-center space-x-3 h-full self-center"}>
          <BasketballStatBox stat={usedStats.points} title={"PPG"} />
          <BasketballStatBox stat={usedStats.rebounds} title={"RPG"} />
          <BasketballStatBox stat={usedStats.assists} title={"APG"} />
          <BasketballStatBox stat={usedStats.blocks} title={"BPG"} />
          <BasketballStatBox stat={usedStats.steals} title={"SPG"} />
          <BasketballStatBox stat={usedStats.turnovers} title={"TOPG"} />
          <BasketballStatBox stat={usedStats.minutes} title={"MPG"} />
          <BasketballStatBox stat={usedStats.fppm} title={"FPPM"} precision={2} />
        </div>
      </div>
    );
  }
}

function PlayerPage(props) {
  const activeTab = props.match?.params?.tab || "price-graph";
  const [playerInfo, setPlayerInfo] = useState({});
  const [favorited, setFavorited] = useState(false);
  const [hasNotifications, setHasNotifications] = useState(false);
  const [newMatrix, setNewMatrix] = useState(true);
  const [onboarding, setOnboarding] = useState(getFirstEnabledOnboardingForPage("player"));

  useEffect(() => {
    setOnboarding(getFirstEnabledOnboardingForPage("player"));
  }, [props]);

  const getPlayerInfo = () => {
    props
      .fetch("/apiv2/players/info/" + props.match.params.id)
      .then((response) => response.json())
      .then((res) => {
        document.title = res.player.DisplayName;
        setPlayerInfo(res);
        setHasNotifications(res.has_player_alerts);
        setFavorited(res.is_favorite);
        if (res.player.Sport === sorareBaseball) {
          setNewMatrix(false);
        }
        ReactTooltip.rebuild();
      })
      .catch(errorCatcher());
  };

  useEffect(() => {
    if (props.match.params.id) {
      getPlayerInfo();
    }
  }, [props.match.params.id]);

  const info = playerInfo;
  const player = playerInfo.player;
  const position = playerInfo.position;
  let colorStart = player?.ColorRight;
  let colorEnd = player?.ColorLeft;
  if (colorStart === "") {
    colorStart = "#132D8F";
    colorEnd = "#1D3EBD";
  }
  const availability = playerInfo.availabilityStatus;

  let status;
  if (info.player !== undefined) {
    if (info.player.PlayingStatus === "starter") {
      status = "Starter";
    } else if (info.player.PlayingStatus === "not_playing") {
      status = "Reserve";
    } else if (info.player.PlayingStatus === "substitute") {
      status = "Substitute";
    } else if (info.player.PlayingStatus === "regular") {
      status = "Regular";
    } else {
      status = info.player.PlayingStatus;
    }
  }

  let headerSize = " xl:h-player-header-1-row ";

  const hasGoalkeeperCards = position?.gk_cards > 0;
  const hasDefenderCards = position?.def_cards > 0;
  const hasMidfielderCards = position?.mid_cards > 0;
  const hasForwardCards = position?.fw_cards > 0;

  const nextStarts = info.next_projected_starts;

  const showIcons =
    (hasDefenderCards && hasMidfielderCards) || (hasDefenderCards && hasForwardCards) || (hasMidfielderCards && hasForwardCards);
  let tip = "Has ";
  if (hasDefenderCards) tip += position?.def_cards + " defender & ";
  if (hasMidfielderCards) {
    tip += position?.mid_cards + " midfielder ";
    if (hasForwardCards) {
      tip += "& ";
    }
  }
  if (hasForwardCards) tip += position?.fw_cards + " forward ";
  tip += "cards";
  let positions = [];
  if (hasGoalkeeperCards) {
    positions.push({ value: "Goalkeeper", name: "Goalkeeper" });
  }
  if (hasDefenderCards) {
    positions.push({ value: "Defender", name: "Defender" });
  }
  if (hasMidfielderCards) {
    positions.push({ value: "Midfielder", name: "Midfielder" });
  }
  if (hasForwardCards) {
    positions.push({ value: "Forward", name: "Forward" });
  }
  const isBaseballPlayer = player?.Sport === "sr_baseball";
  const isBasketballPlayer = player?.Sport === "sr_basketball";

  let nextGame = info.next_game;
  if (isBaseballPlayer) {
    nextGame = info.next_baseball_game;
  }

  let avg5 = info.averages?.avg_5;
  let avg15 = info.averages?.avg_15;
  let avg40 = info.averages?.avg_40;

  if (newMatrix) {
    avg5 = info.averages?.newAvg5;
    avg15 = info.averages?.newAvg15;
    avg40 = info.averages?.newAvg40;
  }

  if (!isBaseballPlayer) {
    if (props.user.sorareAverages === true) {
      avg5 = info.averages?.s5Avg;
      avg15 = info.averages?.s15Avg;
    }
  }

  const baseballStats = info.baseball_season_stats;
  const basketballStats = info.basketball_season_stats;
  let l10Stats;
  let l40Stats;
  let seasonStats;
  const baseballDetails = info.baseball_player_details;

  let basketballPosition = "";
  if (isBasketballPlayer) {
    basketballPosition = player.Position;
    if (basketballPosition === "SF") {
      basketballPosition = "Sm. Forward";
    } else if (basketballPosition === "G") {
      basketballPosition = "Guard";
    } else if (basketballPosition === "C") {
      basketballPosition = "Center";
    } else if (basketballPosition === "SG") {
      basketballPosition = "Sh. Guard";
    }
    if (basketballPosition.length > 0) {
      l10Stats = basketballStats[0];
      l40Stats = basketballStats[1];
      seasonStats = basketballStats[2];
    }
  }
  const [onboarding1, onboarding2, onboarding3] = onboarding.steps?.map((e) => e.target.replace(".", "")) || ["", "", ""];

  return (
    <div className={"mx-0 max-w-full w-full"}>
      {" "}
      {playerInfo.player ? (
        <div>
          <Onboarding
            page={"player"}
            index={onboarding.index}
            name={onboarding.name}
            steps={onboarding.steps}
            onNext={nextOnboarding}
            onEnd={endOnboarding}
            onSkip={endOnboarding}
          />
          <div>
            <div className={clsx(headerSize, "relative bg-surface-dim")}>
              {/*superposition*/}
              <div className="absolute inset-x-0 bg-transparent-inverse-surface-low bg-opacity-10 h-full" />
              {/*background-team-logo*/}
              <div className={clsx(headerSize, "w-full mx-auto absolute flex flex-row z-0 overflow-hidden")}>
                <div
                  className={clsx(headerSize, "hidden xl:block md:bottom-0 left-1/2 transform -translate-x-1/2 md:absolute xl:bottom-8")}
                >
                  <img className="h-[500px] opacity-10 grayscale" src={info.team.PictureUrl || EmptyClub} />
                </div>
              </div>
              <div
                className={"flex flex-col 3xl:w-10/12 4xl:w-9/12 items-center xl:items-start mx-6 xl:mx-12 3xl:mx-auto xl:flex-row z-10"}
              >
                <div className={"h-32 md:h-48 xl:hidden mt-2 flex flex-row justify-center"}>
                  <div>
                    <img
                      alt={playerInfo.player.DisplayName}
                      className={clsx(headerSize, "w-full h-full object-contain")}
                      src={playerInfo.player.SquaredPictureUrl || EmptyPlayer}
                    />
                  </div>
                  <div
                    className={
                      "absolute right-10 md:right-6 md:top-4 md:flex-row md:space-x-4 md:space-y-0 flex flex-col self-start justify-end space-y-3 xl:hidden"
                    }
                  >
                    {props.user.sorareSlug !== undefined && (
                      <div className={"flex self-center 2xl:w-8 2xl:h-8 w-7 h-7"}>
                        <PriceAlertPlayerPageButton playerId={info.player.PlayerId} playerName={info.player.DisplayName} />
                      </div>
                    )}
                    {props.user.sorareSlug !== undefined && (
                      <div className={"2xl:w-8 2xl:h-8 w-7 h-7 cursor-pointer flex self-center"}>
                        <FavoriteBubble className={"w-full"} player={info.player} favorite={favorited} />
                      </div>
                    )}
                  </div>
                </div>
                <div className={"w-full xl:w-5/12 xl:py-6 py-2 space-y-4 flex flex-col"}>
                  <div className={"text-center xl:text-left text-white space-y-3 z-20"}>
                    <PlayerInfoName player={info.player} availability={availability} />
                    <PlayerInfoDates age={info.player.Age} u23EligibilityEnd={info.u23_eligibility_end} contractEnd={info.contract_end} />
                    <div className="flex justify-center xl:justify-start">
                      <PlayerInfoTags
                        leagueSlug={info.team.LeagueSlug}
                        leagueLogo={info.leagueLogo || EmptyClub}
                        teamLogo={info.team.PictureUrl || EmptyClub}
                        leagueLabel={info.team.League || info.leagueName}
                        teamId={info.team.TeamId}
                        clubName={info.team.ClubName}
                        country={playerInfo.player.Country}
                        nationalTeam={playerInfo.player.NationalTeam}
                      />
                    </div>
                  </div>
                  <div className={"flex flex-col self-center space-y-4 align-middle z-10 w-full lg:w-10/12 lg:mx-auto xl:w-full xl:mx-0"}>
                    <div
                      className={
                        "flex flex-col items-center xl:items-start md:flex-row space-y-4 md:justify-center xl:justify-start " +
                        "md:space-y-0 md:space-x-6 2xl:space-x-8 self-center w-full 2xl:mr-0"
                      }
                    >
                      <div
                        className={
                          "bg-transparent-surface-container-high bg-opacity-70 rounded-2xl py-5 md:py-0.5 px-5 md:px-0 flex flex-col md:flex-row gap-5 justify-around items-center"
                        }
                      >
                        <div
                          className={clsx(
                            "flex flex-row self-center md:ml-4",
                            player?.Sport === sorareBasketball ? "space-x-3 justify-center" : "2xl:space-x-6 space-x-4 items-center",
                          )}
                        >
                          <AverageAndGames
                            font={"text-base font-semibold"}
                            size={"w-10 h-10"}
                            style={"player"}
                            avg={props.user.sorareAverages === true ? info.averages.s5Avg : info.averages.avg_5}
                            gms={props.user.sorareAverages === true ? info.averages.s5Apps : info.averages.gms_5}
                            sport={player?.Sport}
                            showPct={true}
                            totalGms={5}
                          />
                          <AverageAndGames
                            font={"text-base font-semibold"}
                            size={"w-10 h-10"}
                            avg={props.user.sorareAverages === true ? info.averages.s15Avg : info.averages.avg_15}
                            gms={props.user.sorareAverages === true ? info.averages.s15Apps : info.averages.gms_15}
                            style={"player"}
                            sport={player?.Sport}
                            showPct={true}
                            totalGms={15}
                          />
                          <AverageAndGames
                            font={"text-base font-semibold"}
                            size={"w-10 h-10"}
                            style={"player"}
                            avg={info.averages.avg_40}
                            sport={player?.Sport}
                            showPct={true}
                            gms={info.averages.gms_40}
                            totalGms={40}
                          />
                        </div>
                        <div className="border-b w-full md:w-fit md:border-l border-outline-variant md:h-6"></div>
                        {!isBaseballPlayer && !isBasketballPlayer && (
                          <div className={"w-full 2.5xl:w-6/12 flex flex-row space-x-4 h-32"}>
                            <FootballPitch fieldHeight={"h-24"} position={position} status={status} />
                            <div className={"w-full overflow-hidden flex flex-col justify-center py-3 pr-4"}>
                              <div className={"flex flex-col space-y-1"}>
                                <span className={"text-xs text-on-surface-variant font-semibold"} style={{ textWrap: "nowrap" }}>
                                  IN GAME POSITION
                                </span>
                                <div className={"flex flex-row text-sm"}>
                                  {playerInfo.positionShortNames?.map((shortName, index) => (
                                    <span key={index} className={index === 0 ? "font-semibold" : ""}>
                                      {index > 0 && <span>&nbsp;·&nbsp;</span>}
                                      {shortName}
                                    </span>
                                  ))}
                                </div>
                              </div>
                              <div className={"flex flex-col space-y-1"}>
                                <span
                                  className={"text-xs text-on-surface-variant font-semibold text-nowrap mt-3"}
                                  style={{ textWrap: "nowrap" }}
                                >
                                  SORARE POSITION
                                </span>
                                <span className={"font-semibold text-sm truncate"} title={player.Position}>
                                  {player.Position}
                                </span>
                              </div>
                            </div>
                          </div>
                        )}
                        {isBaseballPlayer && (
                          <div className={"flex flex-col h-32 rounded-lg w-full md:mr-4"}>
                            <div className={"py-2 bg-grey-f2 rounded-t-lg"}>
                              <p className={"uppercase font-bold text-xs text-center"}>{baseballStats.season} season</p>
                            </div>
                            {player.Position === "RP" || player.Position === "SP" || player.Position === "P" ? (
                              <div className={"flex flex-row justify-center space-x-4 h-full self-center"}>
                                <BaseballStatBox stat={baseballStats.inningsPitched.toFixed(1)} title={"IP"} />
                                {player.Position === "SP" && <BaseballStatBox stat={baseballStats.startPitching} title={"GS"} />}
                                <BaseballStatBox stat={baseballStats.win} title={"W"} />
                                {player.Position === "RP" && <BaseballStatBox stat={baseballStats.hold} title={"H"} />}
                                {player.Position === "RP" && <BaseballStatBox stat={baseballStats.save} title={"S"} />}
                                <BaseballStatBox stat={baseballStats.era.toFixed(2)} title={"ERA"} />
                                <BaseballStatBox stat={baseballStats.fip.toFixed(2)} title={"FIP"} />

                                {player.Position === "SP" && <BaseballStatBox stat={baseballStats.k9.toFixed(1)} title={"K9"} />}

                                <BaseballStatBox stat={baseballStats.whip.toFixed(3)} title={"WHIP"} />
                              </div>
                            ) : (
                              <div className={"flex flex-row justify-center space-x-4 h-full self-center"}>
                                <BaseballStatBox stat={baseballStats.apg.toFixed(1)} title={"AP/G"} />
                                <BaseballStatBox stat={convertToBaseballAverage(baseballStats.battingAverage)} title={"AVG"} />
                                <BaseballStatBox stat={convertToBaseballAverage(baseballStats.woba)} title={"wOBA"} />
                                <BaseballStatBox stat={baseballStats.rbi} title={"RBI"} />
                                <BaseballStatBox stat={baseballStats.homeRun} title={"HR"} />
                                <BaseballStatBox stat={baseballStats.run} title={"R"} />
                                <BaseballStatBox stat={baseballStats.stolenBase} title={"SB"} />
                              </div>
                            )}
                            {nextStarts !== null && nextStarts.length > 0 && (
                              <div>
                                <p className={"text-xs font-semibold px-2 pb-2"}>
                                  Projected to start in GW{" "}
                                  {nextStarts.map((gw, i) => {
                                    return "#" + gw + (i === nextStarts.length - 1 ? "" : ", ");
                                  })}
                                </p>
                              </div>
                            )}
                          </div>
                        )}
                        {isBasketballPlayer && <BasketballStatFullBox stats={basketballStats} />}
                      </div>
                    </div>
                  </div>
                </div>
                <div className={headerSize + "2xl:w-3/12 w-2/12 flex z-10"}></div>
                <div
                  className={
                    "flex justify-center xl:justify-end xl:items-start xl:ml-12 2xl:ml-32 w-10/12 lg:w-8/12 mx-auto md:mx-0 xl:w-5/12 xl:pt-6 md:pb-16 pb-32" +
                    headerSize
                  }
                >
                  <div className="flex wrap z-10 gap-3.5 mb-3 md:mb-0">
                    <div className="space-y-3 flex flex-col items-center w-[280px]">
                      <div className="w-full">
                        <PlayerPriceSplit split={info.prices.split} />
                      </div>
                      <div className="w-full">
                        <CardsOwnedForPlayer player={player} isLite hidePlayerName />
                      </div>
                    </div>

                    <div className={"hidden xl:block z-10"}>
                      <div className={"hidden xl:flex flex-col self-center gap-y-3.5"}>
                        {props.user.sorareSlug !== undefined && (
                          <div className={"flex self-center 2xl:w-8 2xl:h-8 w-7 h-7"}>
                            <PriceAlertPlayerPageButton playerId={info.player.PlayerId} playerName={info.player.DisplayName} />
                          </div>
                        )}
                        {props.user.sorareSlug !== undefined && (
                          <div className={"2xl:w-8 2xl:h-8 w-7 h-7 cursor-pointer flex self-center"}>
                            <FavoriteBubble className={"w-full"} player={info.player} favorite={favorited} />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={clsx(
                  "bg-surface-dim absolute bottom-0 w-full h-32 gap-5 md:space-y-0 md:h-12 py-2 grid grid-rows-2 md:grid-rows-1 grid-cols-12 xl:grid-cols-12 px-6 xl:px-12 3xl:px-48",
                )}
              >
                <div
                  className={
                    "w-full self-center col-span-12 xl:col-span-4 z-10 text-end flex flex-col items-center xl:space-y-0 space-x-3 xl:flex-row"
                  }
                >
                  <div>
                    <PlayerNextGame
                      nextGameId={nextGame.game.GameId}
                      nextGameTime={nextGame.game.GameTime}
                      sport={info.player.Sport}
                      playerTeamId={info.player.TeamId}
                      awayTeamId={nextGame.away_team_id}
                      awayTeamLogo={nextGame.away_team_logo || EmptyClub}
                      awayTeamShortName={nextGame.away_code}
                      homeTeamId={nextGame.home_team_id}
                      homeTeamLogo={nextGame.home_team_logo || EmptyClub}
                      homeTeamShortName={nextGame.home_code}
                      nationalTeamId={info.player.NationalTeam}
                      onboarding2={onboarding2}
                    />
                  </div>
                  <div className={clsx("col-span-2 self-center z-10", onboarding3)}>
                    <PlayerCommunityProjectionWithHover
                      sport={playerInfo.player.Sport}
                      projection={playerInfo.next_game_projection}
                      playerTeamId={playerInfo.player.TeamId}
                    />
                  </div>
                </div>
                <div className="w-full hidden xl:col-span-3 xl:flex">
                  <PlayerPicture
                    sport={playerInfo.player.Sport}
                    picture={playerInfo.player.SquaredPictureUrl || EmptyPlayer}
                    displayName={playerInfo.player.DisplayName}
                    headerSizeClass={headerSize}
                  />
                </div>
                <div
                  className={
                    "w-full self-center col-span-12 xl:col-span-5 z-10 text-end flex flex-col justify-end items-center space-y-3 xl:space-y-0 xl:flex-row"
                  }
                >
                  <PlayerRank
                    isBaseballPlayer={isBaseballPlayer}
                    isBasketballPlayer={isBasketballPlayer}
                    positionRanking={info.position_ranking}
                    playerPosition={player.Position}
                    baseballPosition={info.baseball_position}
                    jersey={Jersey}
                    overallRanking={info.overall_ranking}
                  />
                  <div className="hidden xl:block border-l border-outline mx-2 h-5"></div>
                  <PlayerCardPositions
                    playerPosition={player.Position}
                    isBaseballPlayer={isBaseballPlayer}
                    isBasketballPlayer={isBasketballPlayer}
                    basketballPosition={basketballPosition}
                    baseballPosition={info.baseball_position}
                    showIcons={showIcons}
                    status={status}
                    tip={tip}
                  />
                </div>
              </div>
            </div>
            <div className="bg-transparent-inverse-surface-low bg-opacity-10">
              <PlayerPageTabs paramId={props.match.params.id} activeTab={activeTab} sport={info.player.Sport} />
            </div>
            <div>
              {activeTab === "price-graph" && (
                <div className={GLOBAL_MARGIN + " max-w-screen"}>
                  <div className="space-y-10">
                    <PlayerPriceGraph player={info.player} sport={info.player.Sport} positions={positions} split={info.prices.split} />
                    {!props.noLimitBox && isFree(props.user?.tier) && !isSportFree(props.sport) ? (
                      <UpgradeLimitBox
                        className={"bg-upgrade-bg-price-details h-upgrade-banner-xl bg-cover w-full mt-4 py-2"}
                        title={"Want to get all the market indicators?"}
                        description={"Supply and price details for all scarcities."}
                        button={"Upgrade membership"}
                        hideLocker
                        dark
                      />
                    ) : (
                      <PlayerPriceDetails prices={info.prices} />
                    )}
                  </div>
                </div>
              )}
              {activeTab === "auctions-and-offers" && (
                <div className={GLOBAL_MARGIN + " max-w-screen"}>
                  <PlayerSalesTab player={info.player} />
                </div>
              )}
              {activeTab === "so5-scores" && (
                <div className={GLOBAL_MARGIN + " max-w-screen"}>
                  <PlayerScoresTab info={info} />
                </div>
              )}
              {activeTab === "so5-tiers" && <PlayerTiers player={info.player} />}
              {activeTab === "similar-players" && (
                <div className={GLOBAL_MARGIN + " max-w-screen"}>
                  <div>
                    <SimilarPlayers player={info.player} averages={info.averages} team={info.team} />
                  </div>
                </div>
              )}
              {activeTab === "stacks" && t2OrAbove(props.user.tier) && (
                <div className={GLOBAL_MARGIN + " max-w-screen"}>
                  <div>
                    <PlayerCombo player={info.player} />
                  </div>
                </div>
              )}
              {activeTab === "stacks" && t1OrBelow(props.user.tier) && (
                <div className={GLOBAL_MARGIN + " max-w-screen"}>
                  <div>
                    <PlayerBlockedVideo
                      title="Want more insights?"
                      description="Become a Star member to unlock club stacks"
                      videoLink="https://www.youtube.com/embed/HRK8xB5piA0"
                    />
                  </div>
                </div>
              )}
              {activeTab === "cards" && (
                <div className={GLOBAL_MARGIN + " max-w-screen"}>
                  <PlayerCards player={info.player} />
                </div>
              )}
              {activeTab === "news" && (
                <div className={GLOBAL_MARGIN + " max-w-screen"}>
                  <PlayerNews player={info.player} />
                </div>
              )}
            </div>
          </div>
          <ReactTooltip />
        </div>
      ) : (
        <div>
          <SDLoading />
        </div>
      )}
    </div>
  );
}

const PlayerCost = (props) => {
  const { cost } = props;
  const [open, setOpen] = useState(false);
  return (
    <div>
      <div className={"flex relative flex-col justify-center items-center gap-1 cursor-pointer"} onClick={() => setOpen(true)}>
        <div className={"flex flex-row gap-1 justify-center items-center"}>
          <span className={"text-brand-black text-xs font-semibold"}>Cost</span>
          <IconInfoHelp className={"fill-outline-variant my-auto"} />
        </div>
        <div className={"w-10"}>
          <SorareScoreAverage avg={cost} />
        </div>
      </div>
      {open && <PlayerCostModale open={open} close={() => setOpen(false)} />}
    </div>
  );
};

const PlayerCostModale = (props) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(props.open);
    ReactTooltip.rebuild();
  }, [props.open]);

  return (
    <div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-100 inset-0 overflow-y-auto"
          onClose={() => {
            props.close();
            setOpen(false);
          }}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-full-black bg-opacity-80 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className={"inline-block transform transition-all sm:my-8 sm:align-middle"}>
                <div className={"flex flex-row gap-4 justify-center"}>
                  <div className={"max-w-3xl relative align-bottom bg-grey-f2 rounded-lg pt-5 pb-4 text-left shadow-xl"}>
                    <div className="absolute top-0 right-0 pt-4 pr-4">
                      <button
                        type="button"
                        className="bg-grey-f2 rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        onClick={() => {
                          props.close();
                          setOpen(false);
                        }}
                      >
                        <span className="sr-only">Close</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                    <div className={"font-sans flex flex-col gap-2"}>
                      <div className="font-headers px-5 border-b border-grey-e9 pb-4 flex flex-col justify-center items-center gap-2">
                        <p className="text-xl font-headers font-semibold m-0 text-center">Cost and averages</p>
                      </div>
                      <div className={"flex flex-col gap-3 px-4 py-1 transition-height transition-slowest ease"}>
                        <div className={"flex flex-col justify-center items-center w-full pt-1 font-sans gap-3"}>
                          <div className={"w-full"}>
                            <CostLegendBox />
                          </div>
                          <div className={"w-full"}>
                            <LXLegendBox lx={[5, 15, 40]} />
                          </div>
                          <div className={`px-3 py-3 rounded-lg bg-white border-white`}>
                            <h3 className={"text-center font-headers mt-0 mb-2 text-xl"}>Example</h3>
                            <CostLegendExampleInner />
                          </div>
                          <div className="flex flex-col sm:flex-row justify-center gap-3 w-full sm:w-[40em]">
                            <button
                              type="button"
                              onClick={() => {
                                props.close();
                                setOpen(false);
                              }}
                              className={`m-0 w-full sm:w-[17em] text-center inline-flex items-center justify-center px-6 py-2 border border-brand text-med font-semibold rounded-lg
                shadow-sm text-white bg-brand focus:outline-none focus:ring-0 hover:bg-brand-light hover:border-brand-light cursor-pointer`}
                            >
                              Got it
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};

export default withUser(PlayerPage);

const PlayerInfoTags = ({ leagueSlug, leagueLogo, teamLogo, leagueLabel, teamId, clubName, country, nationalTeam }) => {
  const countryInfo = getPlayerCountryInfo(country);
  return (
    <div className="w-fit text-base space-y-3">
      <div className="flex flex-row xl:space-x-2 flex-wrap xl:flex-nowrap xl:space-y-0 justify-center md:justify-start space-x-1 gap-y-3">
        {leagueSlug && (
          <a href={`/comp/${leagueSlug}`}>
            <div className="w-fit bg-surface-container self-center px-3 py-1 rounded-full text-on-surface font-semibold flex justify-center items-center">
              <div className="w-8 h-8 self-center pr-2">
                <img className="w-full h-full object-contain" src={leagueLogo} />
              </div>
              <span className="text-on-surface">{leagueLabel}</span>
            </div>
          </a>
        )}
        <a href={isTeamEmpty(teamId) ? "#" : `/team/${teamId}`}>
          <div className="w-fit bg-surface-container self-center px-3 py-1 rounded-full text-on-surface font-semibold flex justify-center items-center">
            <div className="w-8 h-8 self-center pr-2">
              {isTeamEmpty(teamId)}
              <img className="w-full h-full object-contain" src={isTeamEmpty(teamId) ? EmptyClub : teamLogo} />
            </div>
            <span className="text-on-surface">{clubName || "No club"}</span>
          </div>
        </a>
      </div>
      {countryInfo && countryInfo.countryLabel && (
        <div className="flex flex-row justify-center xl:justify-start">
          <a href={isTeamEmpty(nationalTeam) ? "#" : `/team/${nationalTeam}`}>
            <div className="w-fit bg-surface-container self-center px-3 py-1 rounded-full text-on-surface font-semibold flex justify-center items-center">
              <div className="w-8 h-8 self-center pr-2">
                <img className="w-full h-full object-contain" src={countryInfo.countryFlag} />
              </div>
              <span className="text-on-surface">{countryInfo.countryLabel}</span>
            </div>
          </a>
        </div>
      )}
    </div>
  );
};

const PlayerPicture = ({ sport, picture, displayName, headerSizeClass }) => {
  const isFootball = sport === sorareFootball;
  const isPictureEmpty = picture === "";

  return (
    <div>
      {/*superposition*/}
      <div className="absolute inset-x-0 bottom-0 h-[250px] z-[1] bg-gradient-to-t from-linear-overlay-end opacity-60" />
      {(isFootball || isPictureEmpty) && (
        <div className={clsx(headerSizeClass, "hidden xl:block md:bottom-0 left-1/2 transform -translate-x-1/2 md:absolute")}>
          <img
            alt={displayName}
            className={clsx(headerSizeClass, "pt-15 xl:pt-12 self-end z-1")}
            src={picture !== "" ? picture : EmptyPlayer}
          />
        </div>
      )}

      {!isFootball && !isPictureEmpty && (
        <div className={clsx(headerSizeClass, "hidden xl:block md:bottom-0 left-1/2 transform -translate-x-1/2 md:absolute")}>
          <img
            alt={displayName}
            className={clsx(headerSizeClass, "w-full h-full overflow-hidden py-4 z-1")}
            src={picture !== "" ? picture : EmptyPlayer}
          />
        </div>
      )}
    </div>
  );
};

const PlayerInfoDates = ({ age, u23EligibilityEnd, contractEnd }) => {
  return (
    <div className={"text-base font-medium z-10"}>
      <p className="text-on-surface-variant">
        <span>{age} years old</span>
        {u23EligibilityEnd !== null && u23EligibilityEnd !== undefined && (
          <span> · {"U23 until " + format(new Date(u23EligibilityEnd), "MMMM do yyyy")}</span>
        )}
        {contractEnd !== null && <span> · Contract until {format(new Date(contractEnd), "MMMM do yyy")}</span>}
      </p>
    </div>
  );
};

const PlayerInfoName = ({ player, availability }) => {
  const isLongName = player?.DisplayName?.length > 25;

  return (
    <div className="flex flex-row justify-center xl:justify-start space-x-4">
      <p
        className={clsx(
          "text-2xl text-center xl:text-left font-semibold font-headers text-on-surface-variant z-10",
          isLongName ? "md:text-3xl" : "md:text-4xl",
        )}
      >
        {player.DisplayName}
      </p>
      <div className="self-center flex z-20">
        <PlayerAvailability
          availability={availability}
          player={player}
          intl={player.InternationalTrip}
          size="w-6"
          popperOffset={{ x: 9, y: 9 }}
          nextGwPlayers={undefined}
        />
      </div>
    </div>
  );
};

const PlayerPageTabs = ({ paramId, activeTab, sport }) => {
  const tabLinks = [
    { label: "Price graph", path: "price-graph" },
    { label: "Auctions & offers", path: "auctions-and-offers" },
    { label: "SO5 Scores", path: "so5-scores" },
    { label: "SO5 Tiers", path: "so5-tiers", condition: sport === sorareFootball },
    { label: "Similar players", path: "similar-players", condition: sport === sorareFootball },
    { label: "Stacks", path: "stacks", condition: sport === sorareFootball },
    { label: "Cards", path: "cards" },
    { label: "News", path: "news", condition: sport !== sorareFootball },
  ];

  return (
    <div className="xl:ml-16 mx-auto mb-6">
      <nav className="lg:flex lg:flex-row grid grid-cols-2 grid-rows-4 md:gap-3">
        {tabLinks.map((tab, index) =>
          tab.condition === undefined || (tab.condition && tab.condition === true) ? (
            <Link key={index} to={`/player/${paramId}/sections/${tab.path}`}>
              <button
                className={clsx(
                  "flex flex-row items-center text-base text-on-surface-variant py-2 px-6 font-medium focus:outline-none",
                  activeTab === tab.path ? "font-semibold border-b-4 border-brand text-on-surface" : "",
                )}
              >
                {tab.label}
              </button>
            </Link>
          ) : null,
        )}
      </nav>
    </div>
  );
};

const PlayerRank = ({
  isBaseballPlayer,
  isBasketballPlayer,
  positionRanking,
  overallRanking,
  playerPosition,
  baseballPosition,
  basketballPosition,
}) => {
  const position = isBaseballPlayer ? baseballPosition : isBasketballPlayer ? basketballPosition : playerPosition;

  return (
    <div className="text-base font-medium text-on-surface space-x-1">
      <span className="text-on-surface-variant">Rank:</span>
      <span>{positionRanking > 0 ? ordinal_suffix_of(positionRanking) : "No rank"}</span>
      <span>
        {position || ""}, {overallRanking > 0 ? ordinal_suffix_of(overallRanking) : "No rank"} Overall
      </span>
    </div>
  );
};

const PlayerCardPositions = ({
  isBaseballPlayer,
  isBasketballPlayer,
  playerPosition,
  basketballPosition,
  baseballPosition,
  showIcons,
  tip,
}) => {
  return (
    <>
      <div className="text-base font-medium flex flex-row space-x-1 text-on-surface">
        <span className="text-on-surface-variant">Cards:</span>
        {isBaseballPlayer && baseballPosition && (
          <span>
            {baseballPosition}
            {baseballPosition !== playerPosition ? ` (${playerPosition})` : ""}
          </span>
        )}
        {isBasketballPlayer && basketballPosition && <span>{basketballPosition}</span>}
        {!isBaseballPlayer && !isBasketballPlayer && playerPosition && <span>{playerPosition}</span>}
        {showIcons && (
          <div className="w-4 h-4 self-center">
            <img src={MultiplePlayerIcon} className="h-full w-full object-contain" data-tip={tip} />
          </div>
        )}
      </div>
    </>
  );
};

const PlayerNextGame = (props) => {
  const {
    nextGameId,
    nextGameTime,
    sport,
    playerTeamId,
    nationalTeamId,
    awayTeamId,
    awayTeamLogo,
    awayTeamShortName,
    homeTeamId,
    homeTeamLogo,
    homeTeamShortName,
    onboarding2,
  } = props;
  const nextGameDate = format(new Date(nextGameTime), "dd/MM");
  let opponentTeam = {};
  let isHome = false;
  if (awayTeamId === playerTeamId || awayTeamId === nationalTeamId) {
    opponentTeam = {
      shortName: homeTeamShortName,
      logo: homeTeamLogo,
    };
  }
  if (homeTeamId === playerTeamId || homeTeamId === nationalTeamId) {
    isHome = true;
    opponentTeam = {
      shortName: awayTeamShortName,
      logo: awayTeamLogo,
    };
  }

  return (
    <div className="w-full text-base font-medium">
      {isTeamEmpty(playerTeamId) && <span className="text-on-surface-variant">This player has no club currently</span>}
      {nextGameId !== "" && sport === sorareFootball && (
        <>
          <a href={"/game/" + nextGameId}>
            <div className="flex flex-row justify-start items-center space-x-2 text-on-surface">
              <span className="text-on-surface-variant">Next game:</span>
              <span>{nextGameDate}</span>
              <div className={"w-6 h-6 cursor-pointer"}>
                <img className={"w-full h-full object-contain"} src={isHome ? HomeIcon : AwayIcon} />
              </div>
              <span>vs</span>{" "}
              <div className={"w-6 h-6 cursor-pointer"}>
                <img className={"w-full h-full object-contain"} src={opponentTeam.logo} />
              </div>
              <span className="font-bold">{opponentTeam.shortName}</span>
            </div>
          </a>
        </>
      )}
      {nextGameId !== "" && sport !== sorareFootball && (
        <div className="bg-white bg-opacity-10 px-3 py-1.5 gap-2 flex flex-row justify-evenly items-center rounded">
          <div className={clsx("col-span-7 flex flex-row justify-evenly self-center gap-2 items-center", onboarding2)}>
            <a href={"/team/" + homeTeamId}>
              <div className="w-8 h-8 cursor-pointer">
                <img className="w-full h-full object-contain" src={homeTeamLogo} />
              </div>
            </a>
            <p className={"self-center font-semibold text-white text-base"}>{format(new Date(nextGameTime), "dd/MM")}</p>
            <a href={"/team/" + awayTeamId}>
              <div className="w-8 h-8 cursor-pointer">
                <img className="w-full h-full object-contain" src={awayTeamLogo} />
              </div>
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

const FootballPitch = (props) => {
  const { fieldHeight, position, status } = props;
  return (
    <div className={"relative w-fit origin-bottom self-center"}>
      <div className={clsx(fieldHeight, "absolute flex flex-col z-10")}></div>
      <div className={clsx(fieldHeight, "z-0 flex flex-col rounded-xl")}>
        <div className={clsx(fieldHeight, "bg-game-field-borders rounded-t-xl pt-1 w-full flex flex-row")}>
          {position?.main_position === "Centre Striker" && (
            <div className={"absolute flex flex-row left-1/2 transform -translate-x-1/2 w-4 h-4"}>
              <img className={"w-full h-full object-contain"} src={MainPosition} />
            </div>
          )}
          {(position?.secondary_position === "Centre Striker" || position?.third_position === "Centre Striker") && (
            <div className={"absolute flex flex-row left-1/2 transform -translate-x-1/2 mt-1 mx-auto w-2 h-2"}>
              <img className={"w-full h-full object-contain"} src={SecondaryPosition} />
            </div>
          )}
          {position?.main_position === "Left/Centre Striker" && (
            <div className={"absolute flex flex-row left-7 w-4 h-4"}>
              <img className={"w-full h-full object-contain"} src={MainPosition} />
            </div>
          )}
          {(position?.secondary_position === "Left/Centre Striker" || position?.third_position === "Left/Centre Striker") && (
            <div className={"absolute flex flex-row left-8 mt-1 mx-auto w-2 h-2"}>
              <img className={"w-full h-full object-contain"} src={SecondaryPosition} />
            </div>
          )}
          {position?.main_position === "Centre/Right Striker" && (
            <div className={"absolute flex flex-row right-7 w-4 h-4"}>
              <img className={"w-full h-full object-contain"} src={MainPosition} />
            </div>
          )}
          {(position?.secondary_position === "Centre/Right Striker" || position?.third_position === "Centre/Right Striker") && (
            <div className={"absolute flex flex-row right-8 mt-1 mx-auto w-2 h-2"}>
              <img className={"w-full h-full object-contain"} src={SecondaryPosition} />
            </div>
          )}
          <div className={"absolute flex flex-row justify-center w-full h-2/12 self-center"}>
            <div className={"border-2 flex border-white/10 align-middle mx-auto z-0 h-12 rounded-full self-center justify-center w-12 "} />
          </div>
          <div className={"absolute border-t-2 flex border-white/10 flex-row justify-center pr-4 w-full h-1 self-center"}></div>
        </div>
        <div className={"bg-game-field-opacity pt-1 " + fieldHeight + " w-full"}>
          {position?.main_position === "Left Midfielder" && (
            <div className={"absolute flex flex-row justify-end left-3 w-4 h-4"}>
              <img className={"w-full h-full object-contain"} src={MainPosition} />
            </div>
          )}
          {(position?.secondary_position === "Left Midfielder" || position?.third_position === "Left Midfielder") && (
            <div className={"absolute flex flex-row justify-end left-4 w-2 h-2 mt-1"}>
              <img className={"w-full h-full object-contain"} src={SecondaryPosition} />
            </div>
          )}
          {position?.main_position === "Right Midfielder" && (
            <div className={"absolute flex flex-row justify-end right-3 w-4 h-4"}>
              <img className={"w-full h-full object-contain"} src={MainPosition} />
            </div>
          )}
          {(position?.secondary_position === "Right Midfielder" || position?.third_position === "Right Midfielder") && (
            <div className={"absolute flex flex-row justify-end right-4 w-2 h-2 mt-1"}>
              <img className={"w-full h-full object-contain"} src={SecondaryPosition} />
            </div>
          )}
          {position?.main_position === "Left Attacking Midfielder" && (
            <div className={"absolute flex flex-row justify-end left-3 w-4 h-4 -mt-2"}>
              <img className={"w-full h-full object-contain"} src={MainPosition} />
            </div>
          )}
          {(position?.secondary_position === "Left Attacking Midfielder" || position?.third_position === "Left Attacking Midfielder") && (
            <div className={"absolute flex flex-row justify-end left-4 w-2 h-2 -mt-1"}>
              <img className={"w-full h-full object-contain"} src={SecondaryPosition} />
            </div>
          )}
          {position?.main_position === "Left/Centre Attacking Midfielder" && (
            <div className={"absolute flex flex-row justify-end left-7 w-4 h-4 -mt-2"}>
              <img className={"w-full h-full object-contain"} src={MainPosition} />
            </div>
          )}
          {(position?.secondary_position === "Left/Centre Attacking Midfielder" ||
            position?.third_position === "Left/Centre Attacking Midfielder") && (
            <div className={"absolute flex flex-row justify-end left-8 w-2 h-2 -mt-1"}>
              <img className={"w-full h-full object-contain"} src={SecondaryPosition} />
            </div>
          )}
          {position?.main_position === "Centre/Right Attacking Midfielder" && (
            <div className={"absolute flex flex-row justify-end right-7 w-4 h-4 -mt-2"}>
              <img className={"w-full h-full object-contain"} src={MainPosition} />
            </div>
          )}
          {(position?.secondary_position === "Centre/Right Attacking Midfielder" ||
            position?.third_position === "Centre/Right Attacking Midfielder") && (
            <div className={"absolute flex flex-row justify-end right-8 w-2 h-2 -mt-1"}>
              <img className={"w-full h-full object-contain"} src={SecondaryPosition} />
            </div>
          )}
          {position?.main_position === "Right Attacking Midfielder" && (
            <div className={"absolute flex flex-row justify-end right-3 w-4 h-4 -mt-2"}>
              <img className={"w-full h-full object-contain"} src={MainPosition} />
            </div>
          )}
          {(position?.secondary_position === "Right Attacking Midfielder" || position?.third_position === "Right Attacking Midfielder") && (
            <div className={"absolute flex flex-row justify-end right-4 w-2 h-2 -mt-1"}>
              <img className={"w-full h-full object-contain"} src={SecondaryPosition} />
            </div>
          )}
          {position?.main_position === "Left/Centre Midfielder" && (
            <div className={"absolute flex flex-row left-7 w-4 h-4"}>
              <img className={"w-full h-full object-contain"} src={MainPosition} />
            </div>
          )}
          {(position?.secondary_position === "Left/Centre Midfielder" || position?.third_position === "Left/Centre Midfielder") && (
            <div className={"absolute flex flex-row justify-end left-8 w-2 h-2 mt-1"}>
              <img className={"w-full h-full object-contain"} src={SecondaryPosition} />
            </div>
          )}
          {position?.main_position === "Centre Defensive Midfielder" && (
            <div className={"absolute flex flex-row left-1/2 transform -translate-x-1/2 mt-3 mx-auto w-4 h-4"}>
              <img className={"w-full h-full object-contain"} src={MainPosition} />
            </div>
          )}
          {(position?.secondary_position === "Centre Defensive Midfielder" ||
            position?.third_position === "Centre Defensive Midfielder") && (
            <div className={"absolute flex flex-row justify-center mx-auto mt-4 w-2 h-2"}>
              <img className={"w-full h-full object-contain"} src={SecondaryPosition} />
            </div>
          )}
          {position?.main_position === "Left/Centre Defensive Midfielder" && (
            <div className={"absolute flex flex-row justify-end left-7 mt-3 w-4 h-4"}>
              <img className={"w-full h-full object-contain"} src={MainPosition} />
            </div>
          )}
          {(position?.secondary_position === "Left/Centre Defensive Midfielder" ||
            position?.third_position === "Left/Centre Defensive Midfielder") && (
            <div className={"absolute flex flex-row justify-end left-8 mt-4 w-2 h-2"}>
              <img className={"w-full h-full object-contain"} src={SecondaryPosition} />
            </div>
          )}
          {position?.main_position === "Centre/Right Defensive Midfielder" && (
            <div className={"absolute flex flex-row justify-end right-7 mt-3 w-4 h-4"}>
              <img className={"w-full h-full object-contain"} src={MainPosition} />
            </div>
          )}
          {(position?.secondary_position === "Centre/Right Defensive Midfielder" ||
            position?.third_position === "Centre/Right Defensive Midfielder") && (
            <div className={"absolute flex flex-row justify-end right-8 mt-4 w-2 h-2"}>
              <img className={"w-full h-full object-contain"} src={SecondaryPosition} />
            </div>
          )}
          {position?.main_position === "Centre/Right Midfielder" && (
            <div className={"absolute flex flex-row right-7 w-4 h-4"}>
              <img className={"w-full h-full object-contain"} src={MainPosition} />
            </div>
          )}
          {(position?.secondary_position === "Centre/Right Midfielder" || position?.third_position === "Centre/Right Midfielder") && (
            <div className={"absolute flex flex-row right-8 mt-1 mx-auto w-2 h-2"}>
              <img className={"w-full h-full object-contain"} src={SecondaryPosition} />
            </div>
          )}
          {position?.main_position === "Centre Midfielder" && (
            <div className={"absolute flex flex-row left-1/2 transform -translate-x-1/2 w-4 h-4"}>
              <img className={"w-full h-full object-contain"} src={MainPosition} />
            </div>
          )}
          {(position?.secondary_position === "Centre Midfielder" || position?.third_position === "Centre Midfielder") && (
            <div className={"absolute flex flex-row left-1/2 transform -translate-x-1/2 mt-1 mx-auto w-2 h-2"}>
              <img className={"w-full h-full object-contain"} src={SecondaryPosition} />
            </div>
          )}
          {position?.main_position === "Centre Attacking Midfielder" && (
            <div className={"absolute flex flex-row left-1/2 transform -mt-2 -translate-x-1/2 w-4 h-4"}>
              <img className={"w-full h-full object-contain"} src={MainPosition} />
            </div>
          )}
          {(position?.secondary_position === "Centre Attacking Midfielder" ||
            position?.third_position === "Centre Attacking Midfielder") && (
            <div className={"absolute flex flex-row left-1/2 transform -translate-x-1/2 -mt-1 mx-auto w-2 h-2"}>
              <img className={"w-full h-full object-contain"} src={SecondaryPosition} />
            </div>
          )}
        </div>
        <div className={"bg-game-field-borders pt-1 " + fieldHeight + " w-full"}>
          {position?.main_position === "Centre Defender" && (
            <div className={"absolute flex flex-row left-1/2 transform -translate-x-1/2 mx-auto w-4 h-4"}>
              <img className={"w-full h-full object-contain"} src={MainPosition} />
            </div>
          )}
          {(position?.secondary_position === "Centre Defender" || position?.third_position === "Centre Defender") && (
            <div className={"absolute flex flex-row left-1/2 transform -translate-x-1/2 w-2 h-2 mt-1"}>
              <img className={"w-full h-full object-contain"} src={SecondaryPosition} />
            </div>
          )}
          {position?.main_position === "Centre/Right Defender" && (
            <div className={"absolute flex flex-row right-7 mx-auto w-4 h-4"}>
              <img className={"w-full h-full object-contain"} src={MainPosition} />
            </div>
          )}
          {(position?.secondary_position === "Centre/Right Defender" || position?.third_position === "Centre/Right Defender") && (
            <div className={"absolute flex flex-row right-8 w-2 h-2 mt-1"}>
              <img className={"w-full h-full object-contain"} src={SecondaryPosition} />
            </div>
          )}
          {position?.main_position === "Left/Centre Defender" && (
            <div className={"absolute flex flex-row left-7 transform mx-auto w-4 h-4"}>
              <img className={"w-full h-full object-contain"} src={MainPosition} />
            </div>
          )}
          {(position?.secondary_position === "Left/Centre Defender" || position?.third_position === "Left/Centre Defender") && (
            <div className={"absolute flex flex-row left-8 transform w-2 h-2 mt-1"}>
              <img className={"w-full h-full object-contain"} src={SecondaryPosition} />
            </div>
          )}
          {position?.main_position === "Left Defender" && (
            <div className={"absolute flex flex-row justify-end left-3 w-4 h-4"}>
              <img className={"w-full h-full object-contain"} src={MainPosition} />
            </div>
          )}
          {(position?.secondary_position === "Left Defender" || position?.third_position === "Left Defender") && (
            <div className={"absolute flex flex-row justify-end left-4 w-2 h-2 mt-1"}>
              <img className={"w-full h-full object-contain"} src={SecondaryPosition} />
            </div>
          )}
          {position?.main_position === "Right Defender" && (
            <div className={"absolute flex flex-row right-3 w-4 h-4"}>
              <img className={"w-full h-full object-contain"} src={MainPosition} />
            </div>
          )}
          {(position?.secondary_position === "Right Defender" || position?.third_position === "Right Defender") && (
            <div className={"absolute flex flex-row right-4 w-2 h-2 mt-1"}>
              <img className={"w-full h-full object-contain"} src={SecondaryPosition} />
            </div>
          )}
          {position?.main_position === "Left Wing Back" && (
            <div className={"absolute flex flex-row justify-end left-2 -mt-3 w-4 h-4"}>
              <img className={"w-full h-full object-contain"} src={MainPosition} />
            </div>
          )}
          {(position?.secondary_position === "Left Wing Back" || position?.third_position === "Left Wing Back") && (
            <div className={"absolute flex flex-row justify-end left-3 -mt-4 w-2 h-2"}>
              <img className={"w-full h-full object-contain"} src={SecondaryPosition} />
            </div>
          )}
          {position?.main_position === "Right Wing Back" && (
            <div className={"absolute flex flex-row justify-end right-2 -mt-3 w-4 h-4"}>
              <img className={"w-full h-full object-contain"} src={MainPosition} />
            </div>
          )}
          {(position?.secondary_position === "Right Wing Back" || position?.third_position === "Right Wing Back") && (
            <div className={"absolute flex flex-row justify-end right-3 -mt-4 w-2 h-2"}>
              <img className={"w-full h-full object-contain"} src={SecondaryPosition} />
            </div>
          )}
        </div>
        <div className={"absolute bottom-5 border-2 border-b-0 border-white/10 z-5 h-8 self-center justify-center w-6/12 rounded-t-lg"} />
        <div
          className={"absolute bottom-5 border-2 border-b-0 border-white/10 z-5 h-4 self-center justify-center w-3/12 rounded-t-lg"}
        ></div>
        <div className={"bg-game-field-opacity rounded-b-xl " + fieldHeight + " w-full"}>
          {position?.main_position?.includes("Goalkeeper") && (
            <div className={"flex flex-row justify-center mx-auto mt-1 w-4 h-4"}>
              <img className={"w-full h-full object-contain"} src={MainPosition} />
            </div>
          )}
          {(position?.secondary_position?.includes("Goalkeeper") || position?.third_position?.includes("Goalkeeper")) && (
            <div className={"flex flex-row justify-center mx-auto mt-1 w-2 h-2"}>
              <img className={"w-full h-full object-contain"} src={SecondaryPosition} />
            </div>
          )}
        </div>
      </div>
      <div
        className="bg-outline-variant rounded-full w-full px-3 py-0.5 mt-1 text-on-surface-variant overflow-hidden font-semibold text-xs text-center"
        style={{ minWidth: "6rem" }}
      >
        {status}
      </div>
    </div>
  );
};
