import React from "react";
import { Box } from "grommet";
import CardLayout from "../cards/cardLayout";
import { getValuationOrigin } from "../util/valuationOrigin";
import { withUser } from "../../userContext";
import { formatPrice } from "../util/formatMoney";
import { sorareBaseball, sorareBasketball } from "../util/sports";

function AuctionCard(props) {
  let a = props.auction;
  const past = props.past;
  let dateToDisplay = new Date(a.auction.EndingAt);

  let unit = props.forceUnit ? props.forceUnit : props.user.preferredUnit;

  if (a.auction.Status === "ENDED") dateToDisplay = new Date(a.auction.EndingAt);
  let price = formatPrice(a.auction.CurrentPrice, "eth");
  let bmp = a.best_market_price_split?.Price || a.bmp;
  let r30 = a.real_time_player_average_split?.Price || a.r30;
  let split = a.best_market_price_split?.Split;
  let marketPrice = formatPrice(bmp, "eth");
  if (unit !== "eth") {
    price = formatPrice(a.auction.CurrentPrice * props.priceInfo[unit], unit);
    marketPrice = formatPrice(bmp * props.priceInfo[unit], unit);
  }
  let averagePrice = formatPrice(r30, "eth");
  if (unit !== "eth") {
    averagePrice = formatPrice(parseFloat(a[unit]), unit);
  }
  if (a.valuation_origin === "starting_price") averagePrice = "None";
  if (a.valuation_origin === "best_market_price") averagePrice = marketPrice;
  let gamesPlayedL5 = a.l5;
  let so5AvgL5 = a.avg5;
  let gamesPlayedL20 = a.l20;
  let so5AvgL20 = a.avg20;
  if (a.player.Sport !== sorareBaseball && props.newScoring) {
    so5AvgL5 = a.newAvg5;
    so5AvgL20 = a.newAvg20;
  }
  if (r30 === -1) {
    averagePrice = "None";
  }
  if (bmp === -1) {
    marketPrice = "None";
  }
  return (
    <Box gap={"small"}>
      {past ? (
        <Box>
          <CardLayout
            imageLink={"/auction/" + a.auction.AuctionId}
            picture={a.card.PictureUrl}
            monthPrice={averagePrice}
            marketPrice={marketPrice}
            ethCurrentPrice={formatPrice(a.auction.CurrentPrice, "eth")}
            valuationOrigin={getValuationOrigin(a.valuation_origin)}
            currentPrice={price}
            date={dateToDisplay}
            countdown={false}
            card={a.card}
            player={a.player}
            l5={so5AvgL5}
            l20={so5AvgL20}
            gms5={gamesPlayedL5}
            gms20={gamesPlayedL20}
            s5={a.s5Avg}
            s15={a.s15Avg}
            s5Apps={a.s5Apps}
            s15Apps={a.s15Apps}
            availability={a.player_status}
            gwGames={props.nextGwPlayers}
            split={split}
          />
        </Box>
      ) : (
        <Box>
          <CardLayout
            imageLink={"/auction/" + a.auction.AuctionId}
            picture={a.card.PictureUrl}
            monthPrice={averagePrice}
            marketPrice={marketPrice}
            countdown={true}
            ethCurrentPrice={formatPrice(a.auction.CurrentPrice, "eth")}
            valuationOrigin={getValuationOrigin(a.valuation_origin)}
            notNextGw={props.notNextGw}
            currentPrice={price}
            date={dateToDisplay}
            card={a.card}
            player={a.player}
            priceInfo={props.priceInfo}
            centerLink={
              a.player.Sport === sorareBasketball
                ? "https://sorare.com/nba/cards/" + a.card.Slug
                : a.player.Sport === sorareBaseball
                ? "https://sorare.com/mlb/cards/" + a.card.Slug
                : "https://sorare.com/football/cards/" + a.card.Slug + "?irclickid=XkzxFx08axyLWzVwUx0Mo3EAUkE05zVZhSq91o0&irgwc=1"
            }
            l5={so5AvgL5}
            l20={so5AvgL20}
            gms5={gamesPlayedL5}
            gms20={gamesPlayedL20}
            s5={a.s5Avg}
            s15={a.s15Avg}
            s5Apps={a.s5Apps}
            s15Apps={a.s15Apps}
            availability={a.player_status}
            gwGames={props.nextGwPlayers}
            gwNumber={props.gwNumber}
            split={split}
          />
        </Box>
      )}
    </Box>
  );
}

export default withUser(AuctionCard);
